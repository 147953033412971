import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import "./style.css";
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();

  const { page, rowsPerPage, count, setPage } = props;

  function handleFirstPageButtonClick(event) {
    setPage(0);
  }

  function handleBackButtonClick(event) {
    setPage(page - 1);
  }

  function handleNextButtonClick(event) {
    setPage(page + 1);
  }

  function handleLastPageButtonClick(event) {
    setPage(Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

export default function DataTable(props) {
  const classes = useStyles2();
  const {
    count,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    rows,
    footerAction,
  } = props;
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value));
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {rows &&
                rows.length > 0 &&
                Object.keys(rows[0]).map((key, i) => {
                  return (
                    <TableCell
                      id={key}
                      align="center"
                      className="table-custom-header"
                      key={i}
                    >
                      {key.toUpperCase()}
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, i) => {
                return (
                  <TableRow key={i}>
                    {Object.entries(row).map((key, i) => {
                      return (
                        <TableCell
                          align="center"
                          className={"table-custom-column "+ key[0]}
                          key={i}
                        >
                          {key[1]}
                        </TableCell>
                      );
                    })}
                    {/* <TableCell>
                                            <Link to={{
                                                pathname: `/activity-add-edit`,
                                                state: {
                                                    params: [{
                                                        'id': row.id, //1
                                                        'pEntry': row.pEntry,
                                                        'docEntry': row.docEntry,
                                                        'stgEntry': row.stgEntry
                                                    }],
                                                },
                                            }}  
                                                className='btn btn-warning'>
                                                <FaPlus />
                                            </Link>
                                        </TableCell> */}
                  </TableRow>
                );
              })}
            {footerAction !== undefined && (
              <TableRow align="right">
                <TableCell
                  align="right"
                  colSpan={24}
                  className="table-custom-column"
                  key="actions"
                >
                  {footerAction}
                </TableCell>
              </TableRow>
            )}
            {emptyRows > 0 && (
              <TableRow style={{ height: 48 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "Rows per page" },
                  native: true,
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={(subProps) => (
                  <TablePaginationActions
                    {...subProps}
                    count={count}
                    setPage={setPage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                  />
                )}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </Paper>
  );
}
