import axios from "axios";
import {Auth} from "../../models/auth/auth";

let authUrl;
class AuthService {


    setUrl() {
        this.authUrl = process.env.REACT_APP_API_SIGN_IN;
    }

    getToken(username, password,isRemember) {
        // const params = new URLSearchParams();
        // params.append('username', username);
        // params.append('password',password );
        // params.append('grant_type', 'password');
        // params.append('client_id', 'WebMvcClientForUser');
        // params.append('client_secret', 'secret');

        this.setUrl();
        let params = new Auth(username, password, isRemember);
          var  headers={
            'Content-Type': 'application/json',
            }
        console.log("authUrl:",this.authUrl);
        return axios
            .post(this.authUrl,params,headers)
            .then(response => {
                return response;
            }) .catch((error) => {
                console.log(error);
            });
    }
}

export default new AuthService();