import React, { useState, useEffect, useContext } from "react";
import { handleAlertMessage, tableFilter } from "../../../helpers/Helpers";
import { Table, Tooltip } from "antd";
import ApiClientService from "../../../services/auth/api-client-service";
import { Link } from "react-router-dom";
import { FaPen, FaSave, FaTrashAlt } from "react-icons/fa";
import { MainContext } from "../../../context/context";
import UserDialog from "../../pages/Dialog";

function Survey(props) {
  const defaultFilters = [
    {
      Title: [],
      Actions: [],
    },
  ];
  const { snackbar, setSnackbar } = useContext(MainContext);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [surveyId, setSurveyId] = useState(0);
  const [surveys, setSurveys] = useState(0);
  const [filters, setFilters] = useState(defaultFilters);
  const [deleteDialogOption, setDeleteDialogOption] = useState({
    open: false,
    title: "Survey Deleting",
    body: "Are you sure you want to delete this record?",
    case1: "No",
    case2: "Yes",
  });
  const [duplicateDialogOption, setDuplicateDialogOption] = useState({
    open: false,
    title: "Survey Duplicate",
    body: "Are you sure you want to duplicate this record?",
    case1: "No",
    case2: "Yes",
  });
  function getData() {
    setRows([]);
    let data = [];
    setLoading(true);
    ApiClientService.get(process.env.REACT_APP_API_GET_ALL_SURVEY_LIST, null)
      .then((value) => {
        if (!value) return;
        var surveys = value.data.data;  
        if (surveys !== null) {
          surveys.forEach(function(survey) {
            data.push({
              Actions: createActionButtons(survey),
              Title: survey.title,
              Id: survey.id,
            });
          });
          setSurveys(surveys);
          setRows(data);
          if (data.length > 0) createFilterData(data);
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const createFilterData = (rows) => {
    if (rows.length <= 0) return;
    let array = defaultFilters[0];
    const object = defaultFilters[0];

    Object.keys(object || {}).forEach((key) => {
      rows.forEach((element) => {
        if (
          element[key] &&
          !object[key].some((x) => x.value === element[key])
        ) {
          array[key].push({
            text: element[key],
            label: element[key],
            value: element[key],
          });
        }
      });
    });
    setFilters(array);
  };

  const createHeaders = () => {
    let headers = [];
    const object = defaultFilters[0];
    const filterKeys = ["Title"];
    Object.keys(object || {}).forEach((key) => {
      if (filterKeys.includes(key)) {
        headers.push({
          title: key,
          dataIndex: key,
          filters: object[key],
          filterMode: "tree",
          filterSearch: false,
          onFilter: (value, record) => tableFilter(record[key], value),
          sorter: (a, b) =>
            a[key]?.toString().localeCompare(b[key]?.toString()),
        });
      } else {
        headers.push({
          title: key,
          dataIndex: key,
        });
      }
    });
    setColumns(headers);
  };

  function createActionButtons(survey) {
    return (
      <div
        key={survey.id}
        className="d-flex justify-content-end align-items-end"
      >
        <Tooltip placement="top" title="Duplicate">
          <button
            className="btn btn-success btn-sm mr-2"
            onClick={(e) => handleDuplicate(e, survey.id)}
          >
            <FaSave />
          </button>
        </Tooltip>
        <Tooltip placement="top" title="Edit">
          <Link
            to={{
              pathname: `/survey-add-edit`,
              state: {
                params: {
                  action: "update",
                  surveyId: survey.id,
                },
              },
            }}
            className="btn btn-warning btn-sm mr-2"
          >
            <FaPen />
          </Link>
        </Tooltip>
        <Tooltip placement="top" title="Delete">
          <button
            className="btn btn-danger btn-sm mr-2"
            onClick={(e) => handleDeleted(e, survey.id)}
          >
            <FaTrashAlt />
          </button>
        </Tooltip>
      </div>
    );
  }
  const rejectAction = (e) => {
    e.preventDefault();
    setDeleteDialogOption({ ...deleteDialogOption, open: false });
    setDuplicateDialogOption({ ...duplicateDialogOption, open: false });
    setSurveyId(0);
  };
  const handleDeleted = (event, id) => {
    event.preventDefault();
    setDeleteDialogOption({ ...deleteDialogOption, open: true });
    setSurveyId(id);
  };

  const deleteSurvey = () => {
    var data = {
      id: surveyId,
    };
    setDeleteDialogOption({ ...deleteDialogOption, open: false });
    setLoading(true);
    ApiClientService.get(
      process.env.REACT_APP_API_SURVEY_SOFT_DELETE_BY_ID,
      data
    ).then((value) => {
      if (!value)
        handleAlertMessage(snackbar, setSnackbar, "An error occurred", "error");
      else if (
        value.status === 200 ||
        value.status === 201 ||
        value.status === 204
      ) {
        handleAlertMessage(
          snackbar,
          setSnackbar,
          "Delete successful.",
          "success"
        );
        setRows((prevState) => {
          prevState = prevState.filter((x) => x.Id !== surveyId);
          return prevState;
        });
        setFilters(defaultFilters);
        getData();
      } else {
        handleAlertMessage(snackbar, setSnackbar, "An error occurred", "error");
      }
      setLoading(false);
    });
  };

  const handleDuplicate = (event, id) => {
    event.preventDefault();
    setDuplicateDialogOption({ ...duplicateDialogOption, open: true });
    setSurveyId(id);
  };
  const duplicateSurvey = () => {
    var survey = surveys.find((x) => x.id === surveyId);
    var data = {
      Title: "DUPLICATE - " + survey.title,
      Description: survey.description,
      surveyQuestionSections: survey.surveyQuestionSections.map((x) => ({
        Id: 0,
        Title: x.title,
        DisplayOrder: x.displayOrder,
        SurveyQuestions: x.surveyQuestions?.map((sq) => ({
          QuestionText: sq.questionText,
          SurveyQuestionTypeId: sq.surveyQuestionTypeId,
          IsMandatoryQuestion: sq.isMandatoryQuestion,
          DisplayOrder: sq.displayOrder,
          FreeText: sq.freeText,
          KeyboardTypeId: sq.keyboardTypeId,
          SurveyAnswers: sq.surveyAnswers?.map((sa) => ({
            AnswerText: sa.answerText,
          })),
        })),
      })),
    };

    setDuplicateDialogOption({ ...duplicateDialogOption, open: false });
    setLoading(true);
    ApiClientService.post(process.env.REACT_APP_API_SAVE_SURVEY, data).then(
      (value) => {
        if (!value)
          handleAlertMessage(
            snackbar,
            setSnackbar,
            "An error occurred",
            "error"
          );
        else if (
          value.status === 200 ||
          value.status === 201 ||
          value.status === 204
        ) {
          handleAlertMessage(
            snackbar,
            setSnackbar,
            "Duplicate successful.",
            "success"
          );
          getData();
        } else {
          handleAlertMessage(
            snackbar,
            setSnackbar,
            "An error occurred",
            "error"
          );
        }
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    createHeaders();
    getData();
  }, []);

  return (
    <>
      <UserDialog
        open={deleteDialogOption.open}
        title={deleteDialogOption.title}
        body={deleteDialogOption.body}
        approveAction={deleteSurvey}
        rejectAction={rejectAction}
        case1={deleteDialogOption.case1}
        case2={deleteDialogOption.case2}
      ></UserDialog>

      <UserDialog
        open={duplicateDialogOption.open}
        title={duplicateDialogOption.title}
        body={duplicateDialogOption.body}
        approveAction={duplicateSurvey}
        rejectAction={rejectAction}
        case1={duplicateDialogOption.case1}
        case2={duplicateDialogOption.case2}
      ></UserDialog>
      <div className="assign-activities row mt-5">
        <div className="col-12 mt-4 d-flex justify-content-end align-items-center">
          <Link
            to={{
              pathname: `/survey-add-edit`,
              state: {
                params: {
                  action: "add",
                },
              },
            }}
            className="btn btn-primary"
          >
            Create Survey
          </Link>
        </div>
        <div className="col-12">
          <Table columns={columns} dataSource={rows} loading={loading} />
        </div>
      </div>
    </>
  );
}

export default Survey;
