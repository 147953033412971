import React, { useContext, useEffect, useState } from "react";
import { ActivityContext } from "../../../../context/context";
import { GetResourceShortNameSelectList } from "../../../../helpers/SelectHelpers";
import { Row, Col, Button } from "reactstrap";
import { FaPlus, FaTimes } from "react-icons/fa";
import Select from "react-select";

function ResourceComponent() {
  const { resources, setResources, docStgId } = useContext(ActivityContext);
  const [shortNameOptions, setShortNameOptions] = useState([]);

  const handleChange = (value, index, param) => {
    var newState = [...resources];
    newState[index][param] = value;
    setResources(newState);
  };

  const addRow = (event) => {
    var defaultResource = {
      selectedShortName: {},
      startTime: "",
      endTime: "",
    };
    var newState = [...resources];
    newState.push(defaultResource);
    setResources(newState);
  };

  const removeRow = (index) => {
    var newState = [];
    var tempArray = [...resources];
    tempArray.forEach((element, i) => {
      if (index != i) newState.push(element);
    });
    setResources(newState);
    console.log(newState)
  };
  const shortNameOptionsFilter = () => {
    if(resources == null || resources?.length == 0) return shortNameOptions;
    var oldShortNameOptions = [...shortNameOptions];
    var values = resources.map(x=> x.selectedShortName.value);
    var newShortNameOptions = oldShortNameOptions.filter(x=> !values.includes(x.value));
    return newShortNameOptions;
  }

  useEffect(() => {
    if (docStgId > 0)
      GetResourceShortNameSelectList(setShortNameOptions, docStgId);
  }, []);

  return (
    <div>
      {resources?.map((resource, index) => (
        <Row key={1+index}>
          <Col xs={6} sm={4}>
            <label>Used Equipments</label>
            <Select
              isSearchable={true}
              value={resource.selectedShortName}
              onChange={(e) => handleChange(e, index, "selectedShortName")}
              options={shortNameOptionsFilter()}
              key={"selectedSortName"+index}
            />
          </Col> 
          <Col xs={6} sm={6} xl={3}>
            <label>Start Time</label>
            <input
              type="time"
              className="form-control"
              id="equipmentTimeStart"
              value={resource.startTime}
              onChange={(e) => handleChange(e.target.value, index, "startTime")}
            />
          </Col>
          <Col xs={6} sm={6} xl={3}>
            <label>End Time</label>
            <input
              type="time"
              className="form-control"
              id="equipmentTimeEnd"
              value={resource.endTime}
              onChange={(e) => handleChange(e.target.value, index, "endTime")}
            />
          </Col>
          <Col xl={2} className="d-flex justify-content-end align-items-end">
            {index == 0 ? (
              <Button color="primary" onClick={(e) => addRow(e)}>
                <FaPlus />
              </Button>
            ) : (
              <Button color="danger" onClick={(e) => removeRow(index)}>
                <FaTimes />
              </Button>
            )}
          </Col>
        </Row>
      ))}
    </div>
  );
}

export default ResourceComponent;
