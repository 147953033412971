import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import "./style.css";
import ActivityListForm from "./components/ActivityListForm";
import { projectData } from "../../../helpers/project/projectHelpers";
var _ = require("lodash");

const _FormData = {
  selectedProject: null,
  selectedStageName: null,
  selectedWorkingLocation: null,
  selectedSubWorkingLocation: null,
  selectedProblemLocation: null,
  selectedStgName: null,
  treatmentPoin: 0,
};
function AssignActivities(props) {
  const [formData, setFormData] = useState(_FormData);
  const [loading, setLoading] = useState(true);

  const [projectOption, setProjectOption] = useState();


  useEffect(() => {
    projectData(setLoading, setProjectOption);
  }, []);

  return (
    <div className="assign-activities">
      <div className="row">
        <div className="col-12">
          <div className="card p-5 mt-4 mb-4">
            <ActivityListForm
              formData={formData}
              setFormData={setFormData}
              projectOption={projectOption}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssignActivities;