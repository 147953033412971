import React, { useState, useEffect } from "react";
import ApiClientService from "../../../services/auth/api-client-service";
import { FaDownload,FaClipboardList } from "react-icons/fa";
import { Table } from "antd";
import "antd/dist/antd.css";
import CustomizedDialogs from "../../pages/CustomizedDialogs";
import { downloadFile, tableFilter } from "../../../helpers/Helpers";

function DamageList() {
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState("Damage Add");
  const [popupBody, setPopupBody] = useState(
    "You are adding an damage for the operation you selected, are you sure you want to add it?"
  );
  
  const [filters, setFilters] = useState([
    {
      id: [],
      name: [],
      quantity: [],
      projectName: [],
      mainWork: [],
      routeStage: [],
      docNum: [],
      description: [],
      download: [],
    },
  ]);

  function createActionButtons(fileName) {
    return (
      <button
        className="btn btn-warning"
        onClick={(e) => downloadFile(fileName)}
      >
        <FaDownload />
      </button>
    );
  }
  function materialNameTableColumn(materials, col) {
    return materials.map((cusRow, index) => (
      <>
        <p>{cusRow[col]}</p>
        <hr />
      </>
    ));
  }
  function descriptionDetail(desc) {
    return (
      <button
        className="btn btn-success"
        onClick={(e) => descriptionDialog(desc)}
      >
        <FaClipboardList />
      </button>
    );
  }
  function descriptionDialog(desc) {
    setOpenPopup(true);
    setPopupTitle("Description");
    setPopupBody(desc);
  }


  const createHeaders = () => {
    var headers = [];
    let notFilters = new Array("id", "description", "download");
    Object.keys(filters[0]).forEach((key) => {
      if (!notFilters.find((s) => key === s)) {
        headers.push({
          title: key,
          key: key,
          dataIndex: key,
          filters: filters[0][key],
          filterMode: "tree",
          filterSearch: false,
          onFilter: (value, record) => tableFilter(record[key], value),
          sorter: (a, b) => a[key].toString().localeCompare(b[key].toString()),
        });
      } else {
        headers.push({
          title: key,
          dataIndex: key,
        });
      }
    });
    setColumns(headers);
  };

  const createFilterData = (rows) => {
    var array = filters[0];
    Object.keys(filters[0]).forEach((key) => {
      rows.forEach((element) => {
        if (
          element[key] !== null &&
          element[key] !== "" &&
          element[key] !== "Invalid date" &&
          !array[key].some((x) => x.value === element[key])
        )
          array[key].push({
            text: element[key],
            label: element[key],
            value: element[key],
          });
      });
    });
    setLoading(false);
    setFilters(array);
  };

  useEffect(() => {
    createHeaders();
    ApiClientService.get(
      process.env.REACT_APP_API_DAMAGE_GET_ALL_LIST,
      null
    ).then((value) => {
      if (!value) return;
      var damageList = value.data.data;
      var arrayRows = [];
      var promises = [];
      if (damageList != null) {
        promises.push(
          damageList.forEach((damage) => {
            arrayRows.push({
              id: damage.id,
              name: materialNameTableColumn(damage.damageItems, "name"),
              quantity: materialNameTableColumn(damage.damageItems, "quantity"),
              projectName: damage.projectName,
              mainWork: damage.stageName,
              routeStage: damage.stgName,
              docNum: damage.docNum,
              description:
                damage.description.trim() === ""
                  ? "Açıklama mevcut değil"
                  : descriptionDetail(damage.description),
              download:
                damage.fileName.trim() === ""
                  ? ""
                  : createActionButtons(damage.fileName),
            });
          })
        );
      }

      Promise.all(promises).then(() => {
        setCount(arrayRows.length);
        arrayRows.forEach((row) => {
          setRows((rows) =>
            [...rows, row].sort((a, b) =>
              a.employeStartTime < b.employeStartTime ? -1 : 1
            )
          );
        });
        createFilterData(arrayRows);
        setLoading(false);
      });
    });
  }, []);
  return (
    <div className="assign-activities">
      <div className="row">
        <div className="col-12">
          <Table columns={columns} dataSource={rows} loading={loading} />
          <CustomizedDialogs
            open={openPopup}
            title={popupTitle}
            body={popupBody}
            rejectAction={() => setOpenPopup(false)}
          ></CustomizedDialogs>
        </div>
      </div>
    </div>
  );
}

export default DamageList;
