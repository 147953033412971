import { createContext, useContext } from "react";

const SurveyContext = createContext();
export { SurveyContext, useContext };

const MainContext = createContext();
export { MainContext };

const SurveyFlowContext = createContext();
export { SurveyFlowContext };

const ActivityContext = createContext();
export { ActivityContext };

const FlowmeterContext = createContext();
export { FlowmeterContext };

const StgCencusContext = createContext();
export { StgCencusContext };
