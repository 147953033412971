const KeyboardTypeEnum = {
  Alfanumeric: {
    value: 1,
    label: "Alfanumeric",
  },
  Numeric: {
    value: 2,
    label: "Numeric",
  },
  Date: {
    value: 3,
    label: "Date",
  },
};

export { KeyboardTypeEnum };
