import ProjectService from "../../services/project/projectService";
import { dynamicSort } from "../Helpers";

export const projectData = (setLoading, setProjectOption) => {
  setLoading(true);
  ProjectService.projectGetAll()
    .then((response) => {
      if (response) {
        var data = response.data?.data;
        if (data && data.length > 0) {
          let projectData = data.map((x) => ({
            label: x.name,
            value: x.absEntry,
            id: x.id,
          }));
          projectData.sort(dynamicSort("label"));

          setProjectOption(projectData);
        }
        return response;
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      setLoading(false);
    });
};
