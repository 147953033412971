import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Row, Col, Input } from "reactstrap";
import { GetItemSetList, GetItemList } from "../../../helpers/SelectHelpers";
import InputMask from "react-input-mask";
import { handleAlertMessage } from "../../../helpers/Helpers";
import { useContext } from "react";
import { MainContext } from "../../../context/context";
import ApiClientService from "../../../services/auth/api-client-service";
import { useHistory } from "react-router-dom";
import { FlowmeterContext } from "../../../context/context";
import ItemComponent from "./components/ItemComponent";
import itemSetService from "../../../services/item/itemSetService";

const FlowMeterAddEdit = (props) => {
  const history = useHistory();
  const [navigateData, setNavigateData] = useState(
    props.history.location.state
  );
  const { snackbar, setSnackbar } = useContext(MainContext);
  const [itemSet, setItemSet] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [flowSelect, setFlowSelect] = useState([]);
  const [isSetLoading, setIsSetLoading] = useState(true);
  const [flowMeterState, setFlowMeterState] = useState({
    id: 0,
    flowMeterSetName: "",
    ip: "",
    itemSetName: { label: "", value: "" },
  });
  const [flowItems, setFlowItems] = useState([
    {
      item: { label: "", value: "" },
      cId: "",
      flowMeterName: "",
      port: "",
    },
  ]);

  const handleChangeSelect = (key, param, e) => {
    var newState = { ...flowMeterState };
    newState[key] = e;
    newState[param] = e.value;
    setFlowMeterState(newState);
    setFlowItems([
      {
        item: { label: "", value: "" },
        cId: "",
        flowMeterName: "",
        port: "",
      },
    ]);
    if (key == "itemSetName") {
      var options = newState.itemSetName.childItems.map((x) => ({
        label: x.name,
        value: x.id,
      }));
      setFlowSelect(options);
    }
  };
  useEffect(() => {
    getAllItemSet();
    //GetItemSetList(setItemSet);
    // GetItemList(setItemList);
    if (navigateData && navigateData.params.action == "update") {
      getByIdFlowMeter(navigateData.params.flowMeterId);
    }
  }, []);

  const getAllItemSet = () => {
    itemSetService
      .getAll()
      .then((response) => {
        if (response) {
          var data = response.data?.data;
          if (data && data.length > 0) {
            setItemSet(
              data.map((x) => ({
                itemSetId: x.fatherItem.id,
                label: x.fatherItem.name,
                value: x.fatherItem.id,
                childItems: x.childItems,
              }))
            );
          }
          return response;
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsSetLoading(false);
      });
  };

  let cIdControl;
  let fNameControl;
  let portControl;
  let itemControl;

  const fCId = flowItems.map((item) => item.cId);
  fCId.forEach((data) => {
    if (data === "") {
      cIdControl = true;
    } else {
      cIdControl = false;
    }
  });
  const fName = flowItems.map((item) => item.flowMeterName);
  fName.forEach((data) => {
    if (data === "") {
      fNameControl = true;
    } else {
      fNameControl = false;
    }
  });
  const fPort = flowItems.map((item) => item.port);
  fPort.forEach((data) => {
    if (data === "") {
      portControl = true;
    } else {
      portControl = false;
    }
  });
  const fItem = flowItems.map((item) => item.item.value);
  fItem.forEach((data) => {
    if (data === "") {
      itemControl = true;
    } else {
      itemControl = false;
    }
  });

  function postAction() {
    const flowMeterDataSave = {
      Id: flowMeterState.id ? flowMeterState.id : 0,
      Name: flowMeterState.flowMeterSetName,
      IpAddress: flowMeterState.ip,
      FlowMeters: flowItems.map((flowMeter) => ({
        ItemSetId: parseInt(flowMeterState.itemSetName.value),
        Name: flowMeter.flowMeterName,
        FlowMeterItems: [
          {
            ItemId: parseInt(flowMeter.item.value),
            Cid: flowMeter.cId,
            Port: flowMeter.port,
          },
        ],
      })),
    };
    if (
      flowMeterState.flowMeterSetName === "" ||
      flowMeterState.ip === "" ||
      flowMeterState.itemSetName.label === "" ||
      cIdControl === true ||
      fNameControl === true ||
      portControl === true ||
      itemControl === true
    )
      return handleAlertMessage(
        snackbar,
        setSnackbar,
        "Empty field",
        "warning"
      );
    ApiClientService.post(
      process.env.REACT_APP_API_SAVE_FLOW_METER,
      flowMeterDataSave
    ).then((value) => {
      if (!value)
        handleAlertMessage(snackbar, setSnackbar, "An error occurred", "error");
      else if (
        value.status == 200 ||
        value.status == 201 ||
        value.status == 204
      ) {
        var message = "Update process successful.";
        handleAlertMessage(snackbar, setSnackbar, message, "success");
        setTimeout(() => history.push("/flow-meter-list"), 2000);
      } else {
        var message = "An error occurred";
        handleAlertMessage(snackbar, setSnackbar, message, "error");
      }
    });
  }

  function checkIpValue(value) {
    const subips = value.split(".");
    if (subips.length > 4) {
      return false;
    }
    const invalidSubips = subips.filter((ip) => {
      ip = parseInt(ip);
      return ip < 0 || ip > 255;
    });
    if (invalidSubips.length !== 0) {
      return false;
    }
    let emptyIpCount = 0;
    subips.forEach((ip) => {
      if (ip === "") {
        emptyIpCount++;
      }
    });
    if (emptyIpCount > 1) {
      return false;
    }
    return true;
  }

  function getByIdFlowMeter(id) {
    var headers = {
      id: id,
    };
    ApiClientService.get(
      process.env.REACT_APP_API_GET_BY_ID_FLOW_METER,
      headers
    ).then((value) => {
      if (!value) return;

      if (!value)
        handleAlertMessage(snackbar, setSnackbar, "An error occurred", "error");
      else if (
        value.status === 200 ||
        value.status === 201 ||
        value.status === 204
      ) {
        var data = value.data.data;
        var mapData = {
          id: data.id,
          flowMeterSetName: data.name,
          ip: data.ipAddress,
          itemSetName: {
            label: data.flowMeters[0].itemSet.name,
            value: data.flowMeters[0].itemSet.id,
          },
        };
        setFlowMeterState(mapData);
        var itemList = [];
        data.flowMeters.map((items) =>
          items.flowMeterItems.map((flowMeterItem) =>
            itemList.push({
              item: {
                label: flowMeterItem.item.name,
                value: flowMeterItem.item.id,
              },
              cId: flowMeterItem?.cid,
              flowMeterName: items.name,
              port: flowMeterItem?.port,
            })
          )
        );
        setFlowItems(itemList);
      } else {
        var message = "An error occurred";
        handleAlertMessage(snackbar, setSnackbar, message, "error");
      }
    });
  }

  var flowmeterContextData = {
    flowItems,
    setFlowItems,
  };
  return (
    <>
      <FlowmeterContext.Provider value={flowmeterContextData}>
        <div className="card p-5 mt-4 mb-4">
          <Row>
            <Col xs={12} sm={6} xl={4}>
              <label>Flowmeter Set Name</label>
              <Input
                type="text"
                name="name"
                value={flowMeterState.flowMeterSetName}
                onChange={(e) =>
                  setFlowMeterState({
                    ...flowMeterState,
                    flowMeterSetName: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} sm={6} xl={4}>
              <label>IP</label>
              <InputMask
                value={flowMeterState.ip}
                onChange={(e) =>
                  setFlowMeterState({
                    ...flowMeterState,
                    ip: e.target.value,
                  })
                }
                className="form-control"
                formatChars={{
                  "9": "[0-9.]",
                }}
                mask="999999999999999"
                maskChar={null}
                alwaysShowMask={false}
                beforeMaskedValueChange={(newState, oldState, userInput) => {
                  let value = newState.value;
                  const oldValue = oldState.value;
                  let selection = newState.selection;
                  let cursorPosition = selection ? selection.start : null;
                  const result = checkIpValue(value);
                  if (!result) {
                    value = value.trim();
                    // try to add . before the last char to see if it is valid ip address
                    const newValue =
                      value.substring(0, value.length - 1) +
                      "." +
                      value.substring(value.length - 1);
                    if (checkIpValue(newValue)) {
                      cursorPosition++;
                      selection = {
                        start: cursorPosition,
                        end: cursorPosition,
                      };
                      value = newValue;
                    } else {
                      value = oldValue;
                    }
                  }

                  return {
                    value,
                    selection,
                  };
                }}
              />
            </Col>
            <Col xs={12} sm={6} xl={4}>
              <label>Item Set Name</label>
              <Select
                value={flowMeterState.itemSetName}
                options={itemSet}
                isLoading={isSetLoading}
                isSearchable={true}
                onChange={(e) => handleChangeSelect("itemSetName", "itemId", e)}
              />
            </Col>
          </Row>
          <hr />
          <div>
            <ItemComponent itemFlowSelect={flowSelect} />
          </div>
          <hr />
          <Row>
            <Col className="mt-4 d-flex justify-content-end align-items-end">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={(e) => postAction(e)}
              >
                Save
              </button>
            </Col>
          </Row>
        </div>
      </FlowmeterContext.Provider>
    </>
  );
};

export default FlowMeterAddEdit;
