import React, { Fragment } from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles, colors } from "../style/style";
import { formatAMPM } from "../../../../helpers/Helpers";
const TimeSheet = (props) => {
  const { data } = props;
  const sharpFlex = 0.3;
  const nameFlex = 2.4;
  const startFlex = 1;
  const endFlex = 1;

  var items = [];
  var size = data.length > 2 ? data.length : 2;
  var dataHalfLength = 2;
  if (size % 2 !== 0) dataHalfLength = (size + 1) / 2;
  else dataHalfLength = size / 2;

  if (data) {
    for (let i = 0; i < dataHalfLength; i++) {
      items.push(
        <View
          style={[
            styles.containerRow,
            { marginHorizontal: "10%", minHeight: "15px", maxHeight: "15px" },
          ]}
        >
          <View
            style={[
              styles.materialTableContainer,
              i % 2 !== 0
                ? { backgroundColor: colors.grey }
                : { backgroundColor: colors.white },
            ]}
          >
            <View style={[styles.materialTableRow, { flex: sharpFlex }]}>
              <Text style={styles.tableText}>{i + 1}</Text>
            </View>
            <View style={styles.materialBorderRight}></View>
            <View style={[styles.materialTableRow, { flex: nameFlex }]}>
              <Text style={styles.tableText}>
                {data[i] ? data[i].userName : ""}
              </Text>
            </View>
            <View style={styles.materialBorderRight}></View>

            <View style={[styles.materialTableRow, { flex: startFlex }]}>
              <Text style={styles.tableText}>
                {data[i] ? formatAMPM(data[i].startDate) : ""}
              </Text>
            </View>
            <View style={styles.materialBorderRight}></View>

            <View style={[styles.materialTableRow, { flex: endFlex }]}>
              <Text style={styles.tableText}>
                {data[i] ? formatAMPM(data[i].endDate) : ""}
              </Text>
            </View>
            <View style={styles.materialBorderRight}></View>
            <View style={[styles.materialTableRow, { flex: sharpFlex }]}>
              <Text style={styles.tableText}>{dataHalfLength + i + 1}</Text>
            </View>
            <View style={styles.materialBorderRight}></View>
            <View style={[styles.materialTableRow, { flex: nameFlex }]}>
              <Text style={styles.tableText}>
                {data[dataHalfLength + i]
                  ? data[dataHalfLength + i].userName
                  : ""}
              </Text>
            </View>
            <View style={styles.materialBorderRight}></View>

            <View style={[styles.materialTableRow, { flex: startFlex }]}>
              <Text style={styles.tableText}>
                {data[dataHalfLength + i]
                  ? formatAMPM(data[dataHalfLength + i].startDate)
                  : ""}
              </Text>
            </View>
            <View style={styles.materialBorderRight}></View>

            <View style={[styles.materialTableRow, { flex: endFlex }]}>
              <Text style={styles.tableText}>
                {data[dataHalfLength + i]
                  ? formatAMPM(data[dataHalfLength + i].endDate)
                  : ""}
              </Text>
            </View>
          </View>
        </View>
      );
    }
  }
  return (
    <>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          marginHorizontal: "10%",
          marginTop: 2,
          minHeight: 15,
        }}
      >
        <View style={styles.rowCenter}>
          <Text
            style={{
              fontSize: 10,
              fontFamily: "Open Sans",
              fontWeight: 700,
              fontStyle: "italic",
            }}
          >
            TIMESHEET / WORKMANSHIP
          </Text>
        </View>
      </View>
      <Fragment>
        <View
          style={[
            styles.containerRow,
            { marginHorizontal: "10%", minHeight: "24px", maxHeight: "24px" },
          ]}
        >
          <View
            style={[
              styles.materialTableContainer,
              {
                backgroundColor: "#f2f2f2",
                fontSize: 8,
                fontFamily: "Open Sans",
                fontWeight: 700,
              },
            ]}
          >
            <View style={[styles.materialTableRow, { flex: sharpFlex }]}>
              <Text>#</Text>
            </View>
            <View style={styles.materialBorderRight}></View>
            <View style={[styles.materialTableRow, { flex: nameFlex }]}>
              <Text>Name Last name</Text>
            </View>
            <View style={styles.materialBorderRight}></View>
            <View style={[styles.materialTableRow, { flex: startFlex }]}>
              <Fragment>
                <Text>START</Text>
                <Text>am/pm</Text>
              </Fragment>
            </View>
            <View style={styles.materialBorderRight}></View>
            <View style={[styles.materialTableRow, { flex: endFlex }]}>
              <Fragment>
                <Text>END</Text>
                <Text>am/pm</Text>
              </Fragment>
            </View>
            <View style={styles.materialBorderRight}></View>
            <View style={[styles.materialTableRow, { flex: sharpFlex }]}>
              <Text>#</Text>
            </View>
            <View style={styles.materialBorderRight}></View>
            <View style={[styles.materialTableRow, { flex: nameFlex }]}>
              <Text>Name Last name</Text>
            </View>
            <View style={styles.materialBorderRight}></View>
            <View style={[styles.materialTableRow, { flex: startFlex }]}>
              <Fragment>
                <Text>START</Text>
                <Text>am/pm</Text>
              </Fragment>
            </View>
            <View style={styles.materialBorderRight}></View>
            <View style={[styles.materialTableRow, { flex: endFlex }]}>
              <Fragment>
                <Text>END</Text>
                <Text>am/pm</Text>
              </Fragment>
            </View>
          </View>
        </View>
        {items}
      </Fragment>
    </>
  );
};

export default TimeSheet;
