import { Table } from "antd";
import React, { useState } from "react";

const GeoTable = (props) => {
  const { selectedRows, setSelectedRows } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { tableProp } = props;

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const hasSelected = selectedRowKeys.length > 0;
  return (
    <div>
      <div
        style={{
          marginBottom: 16,
        }}
      >
        <span
          style={{
            marginLeft: 8,
          }}
        >
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
        </span>
      </div>
      <Table
        rowSelection={{
          ...rowSelection,
        }}
        columns={tableProp.header}
        dataSource={tableProp.rows}
      />
    </div>
  );
};

export default GeoTable;