const RoleTypeOptions = [
    {
      value: 1,
      label: "Team Lead",
    },
    {
      value: 2,
      label: "Team Member",
    },
    {
      value: 3,
      label: "All Roles",
    },
  ];
  export { RoleTypeOptions };