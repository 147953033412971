import axios from "axios";
import TokenStorage from "../token-storage/token-storage-service"
import FormData from 'form-data';

let authUrl;

class ApiClientService {
    setUrl() {
        this.authUrl = process.env.REACT_APP_API_ENDPOINT;
    }

    get(path, params) {
        this.setUrl();
        var headers = {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        return axios
            .get(this.authUrl + path, { params }, headers,{withCredentials: true})
            .then(response => {
                if (response) {
                    return response;
                }
            }).catch((error) => {
                console.log(error);
            });
    }
    post(path, params) {
        this.setUrl();
        var headers = {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        return axios
            .post(this.authUrl + path, params, headers,{withCredentials: true})
            .then(response => {
                if (response)
                    return response;
            }).catch((error) => {
                console.log("post error=> ", error);
            });
    }

    postSingle(path, params) {
        console.log(params);
        this.setUrl();
        var data = JSON.stringify(params);

        var config = {
            method: 'post',
            url: this.authUrl + path,
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
            data: data,
            withCredentials: true
        };

        return axios(config)
            .then(response => {
                if (response)
                    return response;
            });

    }
}

export default new ApiClientService();
