import React, { useEffect, useState } from "react";
import { Card, message } from "antd";
import StgCencusForm from "./components/StgCencusForm";
import StgCencusService from "../../../services/stg-cencus/stgCencusService";
import { StgCencusContext } from "../../../context/context";
import { StgCencusTable } from "./components/StgCencusTable";

function StgCencusPage() {
  const [loading, setLoading] = useState(true);
  const [stgCencus, setStgCencus] = useState([]);
  const [rows, setRows] = useState([]);
  var contextData = {
    stgCencus,
    setStgCencus,
    rows,
    setRows,
    loading,
    setLoading,
  };

  const getStgCencusData = () => {
    setLoading(true);
    StgCencusService.stgCencusGetAll()
      .then((response) => {
        if (response) {
          var data = response.data?.data;
          if (data && data.length > 0) {
            setStgCencus(
              data.map((x) => ({
                Id: x.id,
                ProjectName: x.project?.name,
                StageName: x.stage?.name,
                StgName: x.stg?.code,
                StgId: x.stg?.id,
                ProjectId: x.project?.id,
                StageId: x.stage?.id,
              }))
            );
          }
          return response;
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getStgCencusData();
  }, []);

  return (
    <StgCencusContext.Provider value={contextData}>
      <div className="assign-activities row mt-5">
        <div className="col-12 ">
          <StgCencusForm />
        </div>
        <div className="col-12 mt-5">
          <StgCencusTable />
        </div>
      </div>
    </StgCencusContext.Provider>
  );
}

export default StgCencusPage;
