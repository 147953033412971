import axios from "axios";

const config = {
  method: "",
  url: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  data: null,
  params: null,
  withCredentials: true,
};
class ItemSetService {
  async itemSetGetByDocStgId(docStgId) {
    var newConfig = { ...config };
    newConfig.url =
      newConfig.url + process.env.REACT_APP_API_ITEM_SET_BY_DOCSTGID;
    newConfig.method = "get";
    newConfig.params = { docStgId: docStgId };
    var result = await axios(newConfig);
    return result;
  }
  async getAll() {
    var newConfig = { ...config };
    newConfig.url = newConfig.url + process.env.REACT_APP_API_GET_ITEMSET;
    newConfig.method = "get";
    var result = await axios(newConfig);
    return result;
  }
}
export default new ItemSetService();
