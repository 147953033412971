import DocService from "../../services/doc/docService";
import { dynamicSort } from "../Helpers";

export const getStgByStageId = (stageId, selected, setStgNameOption) => {
  DocService.docGetAllStageId(stageId)
    .then((response) => {
      if (response) {
        var data = response.data?.data;
        if (data && data.length > 0) {
          data.forEach((doc) => {
            var stgData = doc.docStgs
              .filter((x) => selected.docStgIds.includes(x.id))
              .map((docStg) => ({
                label: docStg.stg.desc,
                value: docStg.stg.id,
                entry: docStg.stg.entry,
                docEntry: doc.entry,
                docNum: doc.num,
                docStgId: docStg.id,
              }));
            stgData.sort(dynamicSort("label"));
            setStgNameOption(stgData);
          });
        }
        return response;
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {});
};
