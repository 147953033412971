import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ApiClientService from "../../../services/auth/api-client-service";
import { FaPlus } from "react-icons/fa";
import moment from "moment";
import { Table } from "antd";
import "antd/dist/antd.css";
import { tableFilter } from "../../../helpers/Helpers";


function DamagedMaterialReport() {
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  const [filters, setFilters] = useState([
    {
      code:[],
      projectName: [],
      mainWork: [], //stage
      docNum: [],
      routeStage: [], //stg
      employeStartTime: [],
      employeEndTime: [],
      actions: [],
    },
  ]);

  function createActionButtons(
    id,
    pEntry,
    docEntry,
    stgEntry,
    pName,
    docNum,
    stgDesc,
    stageName,
    startDate,
    endDate
  ) {
    return (
      <Link
        to={{
          pathname: `/damage-add-edit`,
          state: {
            params: {
              docStgId: id, //
              pEntry: pEntry,
              docEntry: docEntry,
              stgEntry: stgEntry,
              projectName: pName,
              docNum: docNum,
              stgDesc: stgDesc,
              stageName: stageName,
              workStartDate: startDate,
              workedEndDate: endDate,
            },
          },
        }}
        className="btn btn-warning"
      >
        <FaPlus />
      </Link>
    );
  }
  
  const createHeaders = () => {
    var headers = [];
    Object.keys(filters[0]).forEach((key) => {
      if (key !== "actions") {
        headers.push({
          title: key,
          key: key,
          dataIndex: key,
          filters: filters[0][key],
          filterMode: "tree",
          filterSearch: false,
          onFilter: (value, record) => tableFilter(record[key], value),
          sorter: (a, b) =>a[key].toString().localeCompare(b[key].toString()),
        });
      } else {
        headers.push({
          title: key,
          dataIndex: key,
        });
      }
    });
    setColumns(headers);
  };

  const createFilterData = (rows) => {
    var array = filters[0];
    Object.keys(filters[0]).forEach((key) => {
      rows.forEach((element) => {
        if (
          element[key] !== null &&
          element[key] !== "" &&
          element[key] !== "Invalid date" &&
          !array[key].some((x) => x.value === element[key])
        )
          array[key].push({
            text: element[key],
            label: element[key],
            value: element[key],
          });
      });
    });
    setLoading(false);
    setFilters(array);
  };

  useEffect(() => {
    createHeaders();
    ApiClientService.get(
      process.env.REACT_APP_API_GET_ASSIGN_ACTIVITIES,
      null
    ).then((value) => {
      if (!value) return;
      var projectList = value.data.data;
      var arrayRows = [];
      var promises = [];
      if (projectList != null) {
        promises.push(
          projectList.forEach(function(project) {
            project.stages.forEach(function(stage) {
              stage.stageEmployees.forEach(function(employe) {
                stage.docs.forEach(function(doc) {
                  doc.docStgs.forEach(function(docStg) {
                    arrayRows.push({
                      code: project.code,
                      projectName: project.name,
                      mainWork: stage.name,
                      docNum: doc.num,
                      routeStage: docStg.stg.desc,
                      employeStartTime: moment(employe.workStartTime).format(
                        "DD/MM/YYYY hh:mm"
                      ),
                      employeEndTime: moment(employe.workEndTime).format(
                        "DD/MM/YYYY hh:mm"
                      ),
                      actions: createActionButtons(
                        docStg.id,
                        project.absEntry,
                        doc.entry,
                        docStg.stg.entry,
                        project.name,
                        doc.num,
                        docStg.stg.desc,
                        stage.name,
                        employe.workStartTime,
                        employe.workEndTime
                      ),
                    });
                  });
                });
              });
            });
          })
        );
      }

      Promise.all(promises).then(() => {
        setCount(arrayRows.length);
        arrayRows.forEach((row) => {
          setRows((rows) =>
            [...rows, row].sort((a, b) =>
              a.employeStartTime < b.employeStartTime ? -1 : 1
            )
          );
        });
        createFilterData(arrayRows);
        setLoading(false);
      });
    });
  }, []);
  return (
    <div className="assign-activities">
      <div className="row">
        <div className="col-12">
          <Table
            columns={columns}
            dataSource={rows}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}

export default DamagedMaterialReport;
