const QuestionSectionParameterOptions = [
  {
    value: 1,
    label: "Tasks",
  },
  {
    value: 2,
    label: "For all activities",
  },
];
export { QuestionSectionParameterOptions };

const DefaultSubQuestionSet = {
  name: "",
  surveyQuestions: [
    {
      questionText: "",
      isRequired: false,
      questionType: {},
      surveyAnswers: [],
    },
  ],
};
export { DefaultSubQuestionSet };
