import React, { useState } from "react";
import BaseSelect from "react-select";
import { Row, Col } from "reactstrap";
import RangeSlider from "../../../pages/RangeSlider";
import FixRequiredSelect from "../../../pages/FixRequiredSelect";
import { useHistory } from "react-router-dom";
import {
  getProblemLocation,
  getSubWorkingLocation,
  getWorkingLocation,
} from "../../../../helpers/segment/segmentHelpers";
import { getStageByProjectId } from "../../../../helpers/stage/stageHelpers";
import { getStgByStageId } from "../../../../helpers/doc/docHelpers";

const ActivityListForm = (props) => {
  let history = useHistory();

  const { formData, setFormData, projectOption } = props;

  const [treatmentPoint, setTreatmentPoint] = useState(0);
  const [stageNameOption, setStageNameOption] = useState();
  const [workingLocationOption, setWorkingLocationOption] = useState();
  const [subWorkingLocationOption, setSubWorkingLocationOption] = useState();
  const [problemLocationOption, setProblemLocationOption] = useState();
  const [stgNameOption, setStgNameOption] = useState();

  const Select = (props) => (
    <FixRequiredSelect
      {...props}
      SelectComponent={BaseSelect}
      options={props.options}
    />
  );

  const handleChangeProject = (selected) => {
    var newState = { ...formData };
    newState.selectedProject = selected;
    newState.selectedStageName = null;
    newState.selectedWorkingLocation = null;
    newState.selectedSubWorkingLocation = null;
    newState.selectedProblemLocation = null;
    newState.selectedStgName = null;
    setFormData(newState);
    if (selected) {
      getStageByProjectId(selected, setStageNameOption);
    } else {
      setStageNameOption(null);
    }
  };

  const handleChangeStageName = (selected) => {
    var newState = { ...formData };
    newState.selectedStageName = selected;
    newState.selectedWorkingLocation = null;
    newState.selectedSubWorkingLocation = null;
    newState.selectedProblemLocation = null;
    newState.selectedStgName = null;
    setFormData(newState);
    if (selected && selected.stageEmployees?.length > 0) {
      getWorkingLocation(selected.value, setWorkingLocationOption);
    } else {
      setWorkingLocationOption([]);
    }
    setSubWorkingLocationOption([]);
    setStgNameOption([]);
    setProblemLocationOption([]);
  };

  const handleChangeSubWorkingLocation = (e) => {
    var newState = { ...formData };
    newState.selectedSubWorkingLocation = e;
    newState.selectedProblemLocation = null;
    setFormData(newState);
    getProblemLocation(e.value, setProblemLocationOption);
  };
  const handleChangeProblemLocation = (e) => {
    var newState = { ...formData };
    newState.selectedProblemLocation = e;
    setFormData(newState);
  };
  const handleChangeWorkingLocation = (selected) => {
    var newState = { ...formData };
    newState.selectedWorkingLocation = selected;
    newState.selectedSubWorkingLocation = null;
    newState.selectedProblemLocation = null;
    newState.selectedStgName = null;
    setFormData(newState);

    getStgByStageId(
      newState.selectedStageName.value,
      selected,
      setStgNameOption
    );
    getSubWorkingLocation(selected.value, setSubWorkingLocationOption);
    setProblemLocationOption([]);
  };

  const hangleChangeStgName = (e) => {
    var newState = { ...formData };
    newState.selectedStgName = e;
    setFormData(newState);
  };
  const postAction = (event) => {
    event.preventDefault();
    history.push(`/activity-add-edit`, {
      params: {
        action: "add",
        docStgId: formData.selectedStgName?.docStgId,
        pEntry: formData.selectedProject?.value,
        docEntry: formData.selectedStgName?.docEntry,
        stgEntry: formData.selectedStgName?.entry,
        projectName: formData.selectedProject?.label,
        docNum: formData.selectedStgName?.docNum,
        stgDesc: formData.selectedStgName?.label,
        stageName: formData.selectedStageName?.label,
        workingLocation: formData.selectedWorkingLocation?.label,
        subWorkingLocation: formData.selectedSubWorkingLocation?.label,
        problemLocation: formData.selectedProblemLocation?.label,
        treatmentPoint: treatmentPoint,
        stageId: formData.selectedStageName.value,
        stageEmployees: formData.selectedStageName.stageEmployees,
      },
    });
  };
  return (
    <form onSubmit={postAction.bind(this)} autoComplete="off">
      <Row className="mt-3">
        <Col xs={12} sm={6} xl={4} className="mt-3">
          <label>Project</label>
          <Select
            required
            value={formData.selectedProject}
            options={projectOption}
            isClearable={true}
            isSearchable={false}
            onChange={(e) => handleChangeProject(e)}
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className="mt-3">
          <label> Main Work</label>
          <Select
            required
            value={formData.selectedStageName}
            options={stageNameOption}
            isSearchable={false}
            onChange={(e) => handleChangeStageName(e)}
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className="mt-3">
          <label>Working Location</label>
          <Select
            required
            value={formData.selectedWorkingLocation}
            options={workingLocationOption}
            isSearchable={false}
            onChange={(e) => handleChangeWorkingLocation(e)}
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className="mt-3">
          <label>Sub Working Location</label>
          <Select
            required
            value={formData.selectedSubWorkingLocation}
            options={subWorkingLocationOption}
            isSearchable={false}
            onChange={(e) => handleChangeSubWorkingLocation(e)}
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className="mt-3">
          <label>Problem Location</label>
          <Select
            required
            value={formData.selectedProblemLocation}
            options={problemLocationOption}
            isSearchable={false}
            onChange={(e) => handleChangeProblemLocation(e)}
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className="mt-3">
          <label>Route Stage</label>
          <Select
            required
            value={formData.selectedStgName}
            options={stgNameOption}
            isSearchable={false}
            onChange={(e) => hangleChangeStgName(e)}
          />
        </Col>
        <Col
          md={6}
          sm={12}
          className="mt-3"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <label>Treatment Point</label>
          <RangeSlider
            inputValue={treatmentPoint}
            setInputValue={setTreatmentPoint}
          />
        </Col>
        <Col
          md={6}
          sm={12}
          className="mt-4 d-flex justify-content-end align-items-end"
        >
          <button type="submit" className="btn btn-primary">
            Activity Add
          </button>
        </Col>
      </Row>
    </form>
  );
};

export default ActivityListForm;
