import React, { useState } from "react";
import { Table } from "antd";
import ExportExcel from "../../pages/ExportExcel";
import ApiClientService from "../../../services/auth/api-client-service";
import { createTableHeaders, downloadFile } from "../../../helpers/Helpers";
import FilterForm from "../../pages/AttachmentReportFilterForm/FilterForm";
import { Col, Row } from "reactstrap";
import UserSnackBars from "../../pages/Snackbar";
import { FaDownload } from "react-icons/fa";

function ActivityAttachmentReport() {
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [alertMessage, setAlertMessage] = useState({
    open: false,
    variant: "",
    message: "",
    timeout: 5000,
  });

  const [filter, setFilter] = useState({
    project: [],
    projectEntry: 0,
    startDate: null,
    endDate: null,
    user: [],
    userId: "",
    dateRange: "",
  });
  const validateFilter = () => {
    var isValid = false;
    var message = "";
    if (
      (filter.startDate === null || filter.endDate === null) &&
      filter.projectEntry === 0 &&
      filter.userId === ""
    ) {
      isValid = true;
      message = "Please select a field you want to filter";
    }
    return [isValid, message];
  };
  const handleAlert = (message, variant) => {
    var alert = { ...alertMessage };
    alert.message = message;
    alert.variant = variant;
    alert.open = true;
    setAlertMessage(alert);
  };

  const getFilterData = () => {
    const [isValid, message] = validateFilter();
    if (isValid) return handleAlert(message, "warning");
    setLoading(true);
    ApiClientService.post(
      process.env.REACT_APP_API_ATTACHMENT_REPORT,
      filter
    ).then((value) => {
      if (!value) return;
      var data = value.data;
      setLoading(false);
      if (data.length > 0) {
        data[0].download = "";
        createTableHeaders(setHeaders, Object.keys(data[0]));
      } else {
        return handleAlert(
          "There is no data according to the searched filter.",
          "warning"
        );
      }
      var array = [];
      data.forEach((element) => {
        element.url =
          process.env.REACT_APP_API_DOWNLOAD_IMAGE_URL + element.url;
        array.push({
          createdUser: element.createdUser,
          doc: element.doc,
          endDate: element.endDate,
          issueId: element.issueId,
          project: element.project,
          stage: element.stage,
          startDate: element.startDate,
          stg: element.stg,
          tag: element.tag,
          task: element.task,
          url: element.url,
          treatmentReferencePoint: element.treatmentReferencePoint,
          verticalDistance: element.verticalDistance,
          horizontalDistance: element.horizontalDistance,
          workingLocation: element.workingLocation,
          download: createActionButtons(element.url),
        });
      });
      setRows(array);
      setExcelData(data);
    });
  };
  function createActionButtons(fileName) {
    return (
      <button
        className="btn btn-warning"
        onClick={(e) => downloadFile(fileName)}
      >
        <FaDownload />
      </button>
    );
  }
  const closeSnackbar = () => {
    var alert = { ...alertMessage };
    alert.open = false;
    setAlertMessage(alert);
  };
  return (
    <>
      <div className="card p-5 mt-4 mb-4">
        <Row css={{ zIndex: 99 }}>
          <Col sm={12}>
            <FilterForm filter={filter} setFilter={setFilter} />
          </Col>
          <Col sm={6} className="mt-4">
            <ExportExcel data={excelData} fileName="attachmentReport" />
          </Col>
          <Col sm={6} className="mt-4 d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={getFilterData}
            >
              Filter
            </button>
          </Col>
        </Row>
      </div>
      <div className="mt-3">
        <Table
          columns={headers}
          dataSource={rows}
          scroll={{ x: 3000 }}
          loading={loading}
        />
      </div>
      <UserSnackBars
        open={alertMessage.open}
        variant={alertMessage.variant}
        message={alertMessage.message}
        timeout={alertMessage.timeout}
        handleClose={closeSnackbar}
      />
    </>
  );
}

export default ActivityAttachmentReport;
