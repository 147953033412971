import React, { useEffect, useCallback, useState } from "react";
import Button from "@material-ui/core/Button";
import { Row, Col, Input } from "reactstrap";
import Select from "react-select";
import "./style.css";
import { Card, Input as AntInput, Select as AntSelect, Tooltip } from "antd";

const { Option } = AntSelect;

const ImageUpload = (props) => {
  const { setFile, file, imageTagOptions, isShow } = props;

  const [mediaTypes, setMediaTypes] = useState([]);

  function uploadSingleFile(e) {
    let ImagesArray = Object.entries(e.target.files).map((e) =>
      URL.createObjectURL(e[1])
    );
    var object = [];
    var size = Object.keys(e.target.files).length;
    for (var i = 0; i < size; i++) {
      object.push({
        tag: { label: "", value: "" },
        label: "",
        description: "",
        value: "",
        url: ImagesArray[i],
        contentType: e.target.files[i].type,
        image: e.target.files[i],
        isNew: true,
        horizontalDistance: 0,
        verticalDistance: 0,
        verticalUnit: "Inch",
        horizontalUnit: "Inch",
      });
    }
    setFile([...file, ...object]);
  }

  async function downloadFile(imageSrc) {
    const image = await fetch(imageSrc.url);
    const imageBlog = await image.blob();

    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement("a");
    link.href = imageURL;
    link.download = "image.png";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function deleteFile(e) {
    const s = file.filter((item, index) => index !== e);
    props.setFile(s);
  }
  const updateState = (param, index, e) => {
    const newState = file.map((obj, i) => {
      if (index === i) {
        return { ...obj, [param]: e };
      }
      return obj;
    });

    setFile(newState);
  };

  const checkMediaTypes = useCallback(async () => {
    const fetchMediaType = async (url) => {
      try {
        const response = await fetch(url);
        const contentType = response.headers.get("content-type");

        if (contentType && contentType.includes("image")) {
          return "image";
        } else if (contentType && contentType.includes("video")) {
          return "video";
        } else {
          return "unknown";
        }
      } catch (error) {
        console.error("Error fetching media type:", error);
        return "unknown";
      }
    };

    const newMediaTypes = await Promise.all(
      file.map((item) => fetchMediaType(item.url))
    );
    setMediaTypes(newMediaTypes);
  }, [file]);

  useEffect(() => {
    checkMediaTypes();
  }, [checkMediaTypes]);

  const renderMedia = (url) => {
    return file.map((item, index) => {
      if (item.url === url) {
        const mediaType = mediaTypes[index];
        switch (mediaType) {
          case "image":
            return (
              <img key={index} src={item.url} alt={`Resim ${index + 1}`} />
            );
          case "video":
            return (
              <video key={index} width="320" height="240" controls>
                <source src={item.url} type={item.contentType} />
                Your browser does not support the video tag.
              </video>
            );
          default:
            return <p key={index}>Bilinmeyen medya türü.</p>;
        }
      }
    });
  };
  const imageRow = (image) => {
    if (image.length > 0) {
      return image.map((item, index) => (
        <>
          <Card
            size="small"
            hoverable
            style={{
              width: 250,
              position: "relative",
            }}
            cover={renderMedia(item.url)}
          >
            <Card.Grid style={{ width: "100%" }}>
              <label>#Tag</label>
              <Select
                value={file[index].tag}
                options={imageTagOptions}
                isSearchable={false}
                onChange={(e) => updateState("tag", index, e)}
              />
            </Card.Grid>
            <Card.Grid style={{ width: "100%" }}>
              <label>Treatment Reference Point</label>
              <Input
                type="input"
                name="text"
                value={file[index].description}
                onChange={(e) =>
                  updateState("description", index, e.target.value)
                }
              />
            </Card.Grid>

            <Card.Grid style={{ width: "100%" }}>
              <Tooltip title="HORIZONTAL distance to the reference point">
                <label>Horizontal Distance </label>
              </Tooltip>

              <AntInput.Group compact style={{ display: "flex" }}>
                <Input
                  type="number"
                  name="text"
                  placeholder="0"
                  value={file[index].horizontalDistance}
                  onChange={(e) =>
                    updateState("horizontalDistance", index, e.target.value)
                  }
                />
                <AntSelect
                  defaultValue={item.horizontalUnit}
                  onChange={(e) => updateState("horizontalUnit", index, e)}
                >
                  <Option value="Inch">Inch</Option>
                  <Option value="Feet">Feet</Option>
                </AntSelect>
              </AntInput.Group>
            </Card.Grid>

            <Card.Grid style={{ width: "100%" }}>
              <Tooltip title="VERTICAL distance to the reference point">
                <label>Vertical Distance </label>
              </Tooltip>
              <AntInput.Group compact style={{ display: "flex" }}>
                <Input
                  type="number"
                  name="text"
                  placeholder="0"
                  value={file[index].verticalDistance}
                  onChange={(e) =>
                    updateState("verticalDistance", index, e.target.value)
                  }
                />
                <AntSelect
                  defaultValue={item.verticalUnit}
                  onChange={(e) => updateState("verticalUnit", index, e)}
                >
                  <Option value="Inch">Inch</Option>
                  <Option value="Feet">Feet</Option>
                </AntSelect>
              </AntInput.Group>
            </Card.Grid>
            <Card.Grid
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Button
                className="btn btn-danger m-2"
                type="button"
                onClick={() => deleteFile(index)}
              >
                <span> Delete </span>
              </Button>
              <Button
                className="btn btn-warning m-2"
                type="button"
                onClick={() => downloadFile(item)}
              >
                download
              </Button>
              <Button className="btn btn-info m-2" type="button">
                <a href={item.url} target="_blank">
                  Go to image
                </a>
              </Button>
            </Card.Grid>
          </Card>
        </>
      ));
    }
  };
  return (
    <>
      <Row>
        <Col xs={12} sm={6} md={4}>
          <Input
            type="file"
            disabled={props.file.length === 5}
            style={{ display: "none" }}
            onChange={uploadSingleFile}
            id="select-image"
            multiple
          />
          <label htmlFor="select-image">
            <Button variant="contained" color="primary" component="span">
              Upload Media
            </Button>
          </label>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Row>{imageRow(props.file)}</Row>
        </Col>
      </Row>
    </>
  );
};

export default ImageUpload;
