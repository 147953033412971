import React from "react";
import ReactExport from "react-export-excel";
import { camelCase } from "../../helpers/Helpers";
import { Button } from "reactstrap";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const filterColumns = (data) => {
  const columns = Object.keys(data.length > 0 ? data[0] : {});
  return columns;
};

export default function ExportExcel(props) {
  const { data,fileName } = props;

  return (
    <ExcelFile
      filename={fileName}
      element={
        <button type="submit" className="btn btn-primary">
          Export to Excel
        </button>
      }
    >
      <ExcelSheet data={data} name="Test">
        {filterColumns(data).map((col) => {
          return <ExcelColumn label={camelCase(col)} value={col} />;
        })}
      </ExcelSheet>
    </ExcelFile>
  );
}
