import React, { useContext, useEffect, useState } from "react";
import { Table, Tooltip } from "antd";
import ApiClientService from "../../../services/auth/api-client-service";
import {
  createTableHeaders,
  handleAlertMessage,
  uuidv4,
} from "../../../helpers/Helpers";
import { MainContext } from "../../../context/context";
import { Link } from "react-router-dom";
import { FaPen, FaTrashAlt } from "react-icons/fa";
import UserDialog from "../../pages/Dialog";

var _DefaultHeader = {
  FlowmeterSetName: "",
  IP: "",
  FlowmetersName: "",
  ItemSetName: "",
  Item: "",
  CID: "",
  Port: "",
  Actions: "",
};

function FlowMeterList() {
  const { snackbar, setSnackbar } = useContext(MainContext);
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [flowMeterId, setFlowMeterId] = useState(0);
  const [deleteDialogOption, setDeleteDialogOption] = useState({
    open: false,
    title: "Survey Deleting",
    body: "Are you sure you want to delete this record?",
    case1: "No",
    case2: "Yes",
  });

  const createActionButtons = (id) => {
    return (
      <div className="d-flex justify-content-end align-items-end">
        <Tooltip placement="top" title="Edit">
          <Link
            to={{
              pathname: `/flow-meter-add-edit`,
              key: uuidv4(),
              state: {
                params: {
                  action: "update",
                  flowMeterId: id,
                },
              },
            }}
            className="btn btn-warning btn-sm mr-2"
          >
            <FaPen />
          </Link>
        </Tooltip>
        <Tooltip placement="top" title="Delete">
          <button
            className="btn btn-danger btn-sm mr-2"
            onClick={(e) => handleDeleted(e, id)}
          >
            <FaTrashAlt />
          </button>
        </Tooltip>
      </div>
    );
  };

  const rejectAction = (e) => {
    e.preventDefault();
    setDeleteDialogOption({ ...deleteDialogOption, open: false });
    setFlowMeterId(0);
  };
  const handleDeleted = (event, id) => {
    event.preventDefault();
    setDeleteDialogOption({ ...deleteDialogOption, open: true });
    setFlowMeterId(id);
  };

  const deleteSurvey = () => {
    var data = {
      id: flowMeterId,
    };
    setDeleteDialogOption({ ...deleteDialogOption, open: false });
    setLoading(true);
    ApiClientService.get(
      process.env.REACT_APP_API_DELETE_FLOW_METER,
      data
    ).then((value) => {
      if (!value)
        handleAlertMessage(snackbar, setSnackbar, "An error occurred", "error");
      else if (
        value.status == 200 ||
        value.status == 201 ||
        value.status == 204
      ) {
        handleAlertMessage(
          snackbar,
          setSnackbar,
          "Delete successful.",
          "success"
        );
        setRows((prevState) => {
          prevState = prevState.filter((x) => x.Id != flowMeterId);
          return prevState;
        });
        getFlowMeterList();
      } else {
        handleAlertMessage(snackbar, setSnackbar, "An error occurred", "error");
      }
      setLoading(false);
    });
  };

  const getFlowMeterList = () => {
    setLoading(true);
    ApiClientService.get(
      process.env.REACT_APP_API_GET_ALL_FLOW_METER_LIST,
      null
    ).then((value) => {
      if (!value) return;
      var data = value.data.data;
      setLoading(false);
      if (data.length > 0) {
        var mapperFlowMeter = data.map(function(flowmeter) {
          return {
            FlowmeterSetName: flowmeter.name,
            IP: flowmeter.ipAddress,
            FlowmetersName: flowmeter.flowMeters?.map(
              (flowMeterName) => `( ${flowMeterName.name} ) `
            ),
            ItemSetName: flowmeter.flowMeters[0].itemSet.name,
            Item: flowmeter.flowMeters?.map((value) =>
              value.flowMeterItems?.map((item) => `( ${item.item.name} ) `)
            ),
            CID: flowmeter.flowMeters?.map((value) =>
              value.flowMeterItems?.map((item) => `( ${item.cid} ) `)
            ),
            Port: flowmeter.flowMeters?.map((value) =>
              value.flowMeterItems?.map((item) => `( ${item.port} ) `)
            ),
            Actions: createActionButtons(flowmeter.id),
          };
        });
        createTableHeaders(setHeaders, Object.keys(mapperFlowMeter[0]));
        setRows(mapperFlowMeter);
      } else {
        createTableHeaders(setHeaders, Object.keys(_DefaultHeader));
        setRows([]);
        return handleAlertMessage(
          snackbar,
          setSnackbar,
          "There is no data according to the searched filter.",
          "warning"
        );
      }
    });
  };
  useEffect(() => {
    getFlowMeterList();
  }, []);

  return (
    <>
      <UserDialog
        open={deleteDialogOption.open}
        title={deleteDialogOption.title}
        body={deleteDialogOption.body}
        approveAction={deleteSurvey}
        rejectAction={rejectAction}
        case1={deleteDialogOption.case1}
        case2={deleteDialogOption.case2}
      ></UserDialog>
      <div className="assign-activities row mt-5">
        <div className="col-12 mt-4 d-flex justify-content-end align-items-center">
          <Link
            to={{
              pathname: `/flow-meter-add-edit`,
              state: {
                params: {
                  action: "add",
                },
              },
            }}
            className="btn btn-primary"
          >
            Flow Meter Add
          </Link>
        </div>
        <div className="col-12">
          <Table
            columns={headers}
            dataSource={rows}
            loading={loading}
            scroll={{ x: 1300 }}
          />
        </div>
      </div>
    </>
  );
}

export default FlowMeterList;
