import React from "react";
import { useState } from "react";
import { Col, Input, Row } from "reactstrap";
import QuestionSetAdd from "./components/QuestionSetAdd";
import UserSnackbars from "../../pages/Snackbar";
import "./style/style.css";
import { SurveyContext } from "../../../context/context";
import { useEffect } from "react";
import ApiClientService from "../../../services/auth/api-client-service";
import { QuestionSectionParameterEnum } from "../../../enums/surveyEnum";
import { RoleTypeOptions } from "../../../mockupData/roleType";

const _DefaultQuestionSet = {
  name: "",
  message: "",
  displayStep: null,
  questionSectionParameter: null,
  stg: null,
  roleType: null,
  isStgDisable: true,
  //subQuestionSet: [],
};

function SurveyAddEdit(props) {
  //const [questionSet, setQuestionSet] = useState({ name: "", message: "" });
  const [surveySection, setSurveySection] = useState({
    ..._DefaultQuestionSet,
  });
  const [navigateData, setNavigateData] = useState(
    props.history.location.state.params
  );
  const [subQuestionSet, setSubQuestionSet] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    variant: "",
    timeout: 5000,
  });

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  }
  const getSurveyData = () => {
    var data = {
      id: navigateData.surveyId,
    };
    ApiClientService.get(process.env.REACT_APP_API_GET_BY_ID_SURVEY, data).then(
      (value) => {
        if (!value || !value.data.data) return;
        var survey = value.data.data;
        var object = {
          id: survey.id,
          name: survey.title,
          surveyType: {
            label: survey.type,
            value: survey.type,
          },
          message: survey.description,
          displayStepSelect: survey.displayStepSelect,
          questionSectionParameter: selectQuestionParameter(survey.stgIdList),
          stg: survey.stgSelects,
          roleType: RoleTypeOptions.find((x) => x.value == survey.showRoleType),
          isStgDisable: survey.stgSelects?.length > 0 ? false : true,
        };
        var subQuestionSetArray = [];
        survey.surveyQuestionSections.forEach((element, sIndex) => {
          subQuestionSetArray.push({
            name: element.title,
            surveyQuestions: [],
            displayOrder: element.displayOrder,
          });
          element.surveyQuestions.forEach((question, qIndex) => {
            subQuestionSetArray[sIndex].surveyQuestions.push({
              questionType: question.surveyQuestionTypeSelect,
              questionText: question.questionText,
              isRequired: question.isMandatoryQuestion,
              displayOrder: question.displayOrder,
              freeText: question.freeText,
              defaultAnswer: question.defaultAnswer,
              keyboardType:
                question.keyboardType != null
                  ? {
                      value: question.keyboardType.id,
                      label: question.keyboardType.name,
                    }
                  : {},
              surveyAnswers: question.surveyAnswers,
            });
          });
        });
        setSurveySection(object);
        setSubQuestionSet(subQuestionSetArray);
      }
    );
  };
  const selectQuestionParameter = (strValue) => {
    if (!strValue) {
      return QuestionSectionParameterEnum.All;
    } else {
      return QuestionSectionParameterEnum.Stg;
    }
  };
  useEffect(() => {
    if (navigateData.action == "update") getSurveyData();
  }, []);
  const data = {
    surveySection,
    setSurveySection,
    subQuestionSet,
    setSubQuestionSet,
    snackbar,
    setSnackbar,
  };
  return (
    <SurveyContext.Provider value={data}>
      <UserSnackbars
        open={snackbar.open}
        variant={snackbar.variant}
        message={snackbar.message}
        timeout={snackbar.timeout}
        handleClose={handleClose}
      ></UserSnackbars>
      <div className="site-page-header-ghost-wrapper">
        <div className="card p-5 mt-4 mb-4">
          <Row css={{ zIndex: 99 }}>
            <Col sm={12}>
              <QuestionSetAdd />
            </Col>
          </Row>
        </div>
      </div>
    </SurveyContext.Provider>
  );
}

export default SurveyAddEdit;
