import React, { useEffect, useState } from "react";
import {
  GetSurveyDisplayStepList,
  GetProjectSelectList,
  GetStgSelectList,
} from "../../../helpers/SelectHelpers";
import { Col, Row, Input } from "reactstrap";
import BaseSelect from "react-select";
import FixRequiredSelect from "../../pages/FixRequiredSelect";
import { RoleTypeOptions } from "../../../mockupData/roleType";
import { QuestionSectionParameterOptions } from "../../../mockupData/surveyJson";
import { QuestionSectionParameterEnum } from "../../../enums/surveyEnum";
import { MainContext, SurveyFlowContext } from "../../../context/context";
import { useContext } from "react";
import ApiClientService from "../../../services/auth/api-client-service";
import { handleAlertMessage } from "../../../helpers/Helpers";
import { useHistory } from "react-router-dom";

function FlowChartRule() {
  const history = useHistory();
  const { snackbar, setSnackbar } = useContext(MainContext);
  const [projectOptions, setProjectOptions] = useState([]);
  const [displayStepOptions, setDisplayStepOptions] = useState([]);
  const [stgOptions, setStgOptions] = useState([]);
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(false);

  const { flowSection, setFlowSection } = useContext(SurveyFlowContext);

  const Select = (props) => (
    <FixRequiredSelect
      {...props}
      SelectComponent={BaseSelect}
      options={props.options}
    />
  );

  useEffect(() => {
    GetProjectSelectList(setProjectOptions);
    GetSurveyDisplayStepList(setDisplayStepOptions);
    GetStgSelectList(setStgOptions);
  }, []);

  const handleChangeQuestionSectionParameter = (e) => {
    if (e.value == QuestionSectionParameterEnum.Stg.value) {
      var tempState = { ...flowSection };
      tempState.isStgDisable = false;
      tempState.selectedQuestionSectionParameter = e;
      setFlowSection(tempState);
    } else {
      var tempState = { ...flowSection };
      tempState.selectedStg = [];
      tempState.isStgDisable = true;
      tempState.selectedQuestionSectionParameter = e;
      setFlowSection(tempState);
    }
  };

  const postAction = (event) => {
    event.preventDefault();
    var isDuplicate = false;
    var section = { ...flowSection };
    if (section.surveys.length <= 0) {
      return handleAlertMessage(
        snackbar,
        setSnackbar,
        "Survey is empty",
        "warning"
      );
    }
    let isEmptySort = section.surveys.some(
      (x) => x.displayOrder == "" || x.displayOrder <= 0
    );
    if (isEmptySort)
      return handleAlertMessage(
        snackbar,
        setSnackbar,
        "Display order error ",
        "warning"
      );

    section.surveys.forEach((arr1, i) => {
      section.surveys.forEach((arr2, j) => {
        if (i != j) {
          if (arr1.displayOrder == arr2.displayOrder) {
            return handleAlertMessage(
              snackbar,
              setSnackbar,
              "Display duplicate ",
              "warning"
            );
          }
        }
      });
    });
    if (isDuplicate) return;

    var stgIds = section.selectedStg?.map((x) => x.value);
    var projectIds = section.selectedProject?.map((x) => x.value);
    var surveyFlowCharts = section.surveys?.map((x) => ({
      SurveyId: x.surveyId,
      DisplayOrder: x.displayOrder,
    }));
    var body = {
      Id: section.id ?? 0,
      Name: section.flowName,
      StgIdList: stgIds?.join(";") ?? "",
      ProjectIds: projectIds?.join(";") ?? "",
      RoleType: section.selectedRole.value,
      SurveyDisplayStepId: section.selectedDisplayStep.value,
      displayOrder: section.displayOrder,
      SurveySurveyFlowCharts: surveyFlowCharts ?? [],
    };
    setIsSaveBtnDisabled(true);
    ApiClientService.post(
      process.env.REACT_APP_API_SAVE_SURVEY_FLOW,
      body
    ).then((value) => {
      setIsSaveBtnDisabled(false);
      if (value.status == 200 || value.status == 201 || value.status == 204) {
        var message = "Update process successful.";
        handleAlertMessage(snackbar, setSnackbar, message, "success");
        setTimeout(() => history.push("/survey-flow-chart"), 1000);
      } else {
        var message = "An error occurred";
        handleAlertMessage(snackbar, setSnackbar, message, "error");
      }
    });
  };

  return (
    <div className="card p-5 mt-4 mb-4">
      <form onSubmit={postAction.bind(this)} autoComplete="off">
        <Row className="mt-3">
          <Col xs={12} sm={6} xl={4}>
            <label>Flow Chart Name</label>
            <Input
              required
              type="text"
              name="flowChartName"
              value={flowSection.flowName}
              onChange={(e) =>
                setFlowSection({ ...flowSection, flowName: e.target.value })
              }
            />
          </Col>
          <Col xs={12} sm={6} xl={4}>
            <label>Projects</label>
            <Select
              value={flowSection.selectedProject}
              options={projectOptions}
              isMulti
              isClearable={true}
              isSearchable={false}
              onChange={(e) =>
                setFlowSection({ ...flowSection, selectedProject: e })
              }
            />
          </Col>
          <Col xs={12} sm={6} xl={4}>
            <label>Display Step</label>
            <Select
              required
              value={flowSection.selectedDisplayStep}
              options={displayStepOptions}
              isSearchable={false}
              onChange={(e) =>
                setFlowSection({ ...flowSection, selectedDisplayStep: e })
              }
            />
          </Col>
          <Col xs={12} sm={6} xl={4}>
            <label>Question Selection Parameter</label>
            <Select
              required
              value={flowSection.selectedQuestionSectionParameter}
              options={QuestionSectionParameterOptions}
              isSearchable={false}
              onChange={(e) => handleChangeQuestionSectionParameter(e)}
            />
          </Col>
          <Col xs={12} sm={6} xl={4}>
            <label>Role Type</label>
            <Select
              required
              value={flowSection.selectedRole}
              options={RoleTypeOptions}
              isSearchable={false}
              onChange={(e) =>
                setFlowSection({ ...flowSection, selectedRole: e })
              }
            />
          </Col>
          <Col xs={12} sm={6} xl={4}>
            <label>Stg List</label>
            <Select
              required={!flowSection.isStgDisable}
              value={flowSection.selectedStg}
              isMulti
              options={stgOptions}
              isSearchable={false}
              onChange={(e) =>
                setFlowSection({ ...flowSection, selectedStg: e })
              }
              isDisabled={flowSection.isStgDisable}
            />
          </Col>
          <Col xs={12} sm={6} lg={4}>
            <label>Order</label>
            <Input
              required
              type="number"
              name="displayOrder"
              value={flowSection.displayOrder}
              onChange={(e) =>
                setFlowSection({ ...flowSection, displayOrder: e.target.value })
              }
            />
          </Col>
          {/* <Col xs={12} sm={6} xl={4}>
            <label>Message</label>
            <Input
              required
              type="text"
              name="Message"
              value={flowSection.message}
              onChange={(e) =>
                setFlowSection({ ...flowSection, message: e.target.value })
              }
            />
          </Col> */}
          <Col
            xs={12}
            sm={6}
            lg={8}
            className="mt-4 d-flex justify-content-end align-items-end"
          >
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSaveBtnDisabled}
            >
              Save
            </button>
          </Col>
        </Row>
      </form>
    </div>
  );
}

export default FlowChartRule;
