import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { GetProjectStageStgSelectList } from "../../../../helpers/SelectHelpers";
import StgCencusService from "../../../../services/stg-cencus/stgCencusService";
import FixRequiredSelect from "../../../pages/FixRequiredSelect";
import BaseSelect from "react-select";
import { Card, message } from "antd";
import { StgCencusContext } from "../../../../context/context";

export default function StgCencusForm() {
  const [selected, setSelected] = useState({
    project: null,
    stage: null,
    stg: null,
  });
  const [projectOptions, setProjectOptions] = useState([]);
  const [stageOptions, setStageOptions] = useState([]);
  const [stgOptions, setStgOptions] = useState([]);
  const [stageList, setStageList] = useState([]);
  const [stgList, setStgList] = useState([]);
  const { stgCencus, setStgCencus, setLoading, rows } = useContext(
    StgCencusContext
  );

  const Select = (props) => (
    <FixRequiredSelect
      {...props}
      SelectComponent={BaseSelect}
      options={props.options}
    />
  );
  const handleChange = (e, key) => {
    var newState = { ...selected };
    switch (key) {
      case "project":
        newState.project = e;
        newState.stage = null;
        newState.stg = null;
        setStageOptions(stageList.filter((x) => x.projectId == e.value) ?? []);
        break;
      case "stage":
        newState.stage = e;
        newState.stg = null;
        setStgOptions(stgList.filter((x) => x.stageId == e.value) ?? []);
        break;
      case "stg":
        newState.stg = e;
        break;
      default:
        break;
    }
    setSelected(newState);
  };

  useEffect(() => {
    GetProjectStageStgSelectList(
      setProjectOptions,
      setStgOptions,
      setStageList,
      setStgList
    );
  }, []);

  const duplicateData = () => {
    var tempRows = [...rows];
    var tempSelected = { ...selected };
    var isDuplicate = false;
    var data = tempRows.find(
      (x) =>
        x.ProjectId == tempSelected.project?.value &&
        x.StageId == tempSelected.stage?.value &&
        x.StgId == tempSelected.stg.value
    );
    return (isDuplicate = data != null ? true : false);
  };

  const postAction = (e) => {
    setLoading(true);
    e.preventDefault();
    var body = {
      projectId: selected.project?.value,
      stageId: selected.stage?.value,
      stgId: selected.stg.value,
    };
    const isDuplicate = duplicateData();
    if (isDuplicate) {
      setLoading(false);
      return message.info("Duplicate Stg Cencus");
    }

    StgCencusService.stgCencusCreate(body)
      .then((response) => {
        if (response.status === 200) {
          message.success("Cencus Saved");
          var newSelected = { ...selected };
          var newStgCencus = [...stgCencus];
          newStgCencus.push({
            Id: response.data.data.id ?? 0,
            ProjectName: newSelected.project?.label,
            StageName: newSelected.stage?.label,
            StgName: newSelected.stg.label,
            StgId: newSelected.stg.value,
            ProjectId: newSelected.project?.value,
            StageId: newSelected.stage?.value,
          });
          setStgCencus(newStgCencus);

          newSelected.project = null;
          newSelected.stage = null;
          newSelected.stg = null;
          setSelected(newSelected);

          setStageOptions([]);
          setStgOptions(stgList);
        } else {
          message.warning("Cencus not Saved");
        }
      })
      .catch((error) => {
        console.log(error);
        message.error("System Error!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form onSubmit={postAction.bind(this)} autoComplete="off">
      <Card
        title="Stg Cencus Settings"
        bordered={true}
        extra={<button className="btn btn-primary">Add</button>}
        style={{
          width: "100%",
        }}
      >
        <div className="row">
          <div className="col-sm-4 mt-1 mb-3 ">
            <label>Project</label>
            <Select
              value={selected.project}
              options={projectOptions}
              isClearable={true}
              isSearchable={false}
              onChange={(e) => handleChange(e, "project")}
            />
          </div>
          <div className="col-sm-4 mt-1 mb-3 ">
            <label>Main Work</label>
            <Select
              value={selected.stage}
              options={stageOptions}
              isClearable={true}
              isSearchable={false}
              onChange={(e) => handleChange(e, "stage")}
            />
          </div>
          <div className="col-sm-4 mt-1 mb-3 ">
            <label>Route Stage</label>
            <Select
              required
              value={selected.stg}
              options={stgOptions}
              isClearable={true}
              isSearchable={false}
              onChange={(e) => handleChange(e, "stg")}
            />
          </div>
        </div>
      </Card>
    </form>
  );
}
