const QuestionType = {
  Multiple: 1,
  Free_Text: 2,
  Yes_No: 3,
  Form: 4,
  MoreThanOne: 7,
};
export { QuestionType };

const QuestionTypeListEnum = {
  Multiple: {
    value: 1,
    label: "Multiple Choice",
  },
  Free_Text: {
    value: 2,
    label: "Free Text",
  },
  Yes_No: {
    value: 3,
    label: "Yes/No",
  },
  Form: {
    value: 4,
    label: "Form",
  },
  MoreThanOne: {
    value: 7,
    label: "More Than One",
  },
};
export { QuestionTypeListEnum };