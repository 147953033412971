import React, { Fragment } from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import { styles, colors } from "../style/style";

const Activities = (props) => {
  if (!props) return;
  var data = props.item ? props.item : [];

  const column1 = 0.5;
  const column2 = 2;
  const column3 = 2;

  var items = [];
  var locationSize = data?.locations?.length;
  var jobSize = data?.jobs?.length;
  var taskSize = data?.tasks?.length;
  if (data) {
    for (let i = 0; i < locationSize; i += 2) {
      items.push(
        <View
          style={[
            styles.containerRow,
            { marginHorizontal: "10%", minHeight: "25px", maxHeight: "25px" },
          ]}
        >
          <View
            style={[
              styles.materialTableContainer,
              i % 2 != 0
                ? { backgroundColor: colors.grey }
                : { backgroundColor: colors.white },
            ]}
          >
            <View style={[styles.materialTableRow, { flex: column1 }]}>
              <Fragment>
                <Text style={styles.tableText}>LOCATIONS</Text>
                <Text style={{ fontSize: 7 }}>WORKED</Text>
              </Fragment>
            </View>
            <View style={styles.materialBorderRight}></View>
            <View style={[styles.materialTableRow, { flex: column2 }]}>
              <Text style={styles.tableText}>
                {data.locations[i] ? data.locations[i].itemName : ""}
              </Text>
            </View>
            <View style={styles.materialBorderRight}></View>

            <View style={[styles.materialTableRow, { flex: column3 }]}>
              <Text style={styles.tableText}>
                {data.locations[i + 1] ? data.locations[i + 1].itemName : ""}
              </Text>
            </View>
          </View>
        </View>
      );
    }
    for (let i = 0; i < jobSize; i += 2) {
      items.push(
        <View
          style={[
            styles.containerRow,
            { marginHorizontal: "10%", minHeight: "25px", maxHeight: "25px" },
          ]}
        >
          <View
            style={[
              styles.materialTableContainer,
              i % 2 != 0
                ? { backgroundColor: colors.grey }
                : { backgroundColor: colors.white },
            ]}
          >
            <View style={[styles.materialTableRow, { flex: column1 }]}>
              <Text style={styles.tableText}>JOBS DONE</Text>
            </View>
            <View style={styles.materialBorderRight}></View>
            <View style={[styles.materialTableRow, { flex: column2 }]}>
              <Text style={styles.tableText}>
                {data.jobs[i] ? data.jobs[i].itemName : ""}
              </Text>
            </View>
            <View style={styles.materialBorderRight}></View>

            <View style={[styles.materialTableRow, { flex: column3 }]}>
              <Text style={styles.tableText}>
                {data.jobs[i + 1] ? data.jobs[i + 1].itemName : ""}
              </Text>
            </View>
          </View>
        </View>
      );
    }

    for (let i = 0; i < taskSize; i += 2) {
      items.push(
        <View
          style={[
            styles.containerRow,
            { marginHorizontal: "10%", minHeight: "25px", maxHeight: "25px" },
          ]}
        >
          <View
            style={[
              styles.materialTableContainer,
              i % 2 != 0
                ? { backgroundColor: colors.grey }
                : { backgroundColor: colors.white },
            ]}
          >
            <View style={[styles.materialTableRow, { flex: column1 }]}>
              <Text style={styles.tableText}>Tasks Done</Text>
            </View>
            <View style={styles.materialBorderRight}></View>
            <View style={[styles.materialTableRow, { flex: column2 }]}>
              <Text style={styles.tableText}>
                {data.tasks[i] ? data.tasks[i].itemName : ""}
              </Text>
            </View>
            <View style={styles.materialBorderRight}></View>
            <View style={[styles.materialTableRow, { flex: column3 }]}>
              <Text style={styles.tableText}>
                {data.tasks[i + 1] ? data.tasks[i + 1].itemName : ""}
              </Text>
            </View>
          </View>
        </View>
      );
    }
  }

  return (
    <Fragment>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          marginHorizontal: "10%",
          marginTop: 2,
          minHeight: 20,
        }}
      >
        <View style={styles.rowCenter}>
          <Text
            style={{
              fontSize: 10,
              fontFamily: "Open Sans",
              fontWeight: 700,
              fontStyle: "italic",
            }}
          >
            ACTIVITIES / WORKMANSHIP
          </Text>
        </View>
      </View>
      {items}
    </Fragment>
  );
};
export default Activities;
