import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import ApiClientService from "../../services/auth/api-client-service";
import {
    Dialog,
    DialogTitle,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    TextField,
    Snackbar,
    SnackbarContent,
    IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import { amber, green } from "@material-ui/core/colors";
import DataTable from "../pages/DataTable";

import axios from "axios";

const UserManagementPage = () => {
    const [rows, setRows] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setrowsPerPage] = useState(5);

    const [users, setUsers] = useState([]);
    const [currentUserIndex, setCurrentUserIndex] = useState(0);
    const [invitationDialog, setInvitationDialog] = useState(false);
    const [invitationEmail, setInvitationEmail] = useState("");
    const [Notification, setNotification] = useState(false);
    const [notificationVariant, setNotificationVariant] = useState("");
    const [notificationMessage, setNotificationMessage] = useState("");

    const variantIcon = {
        success: CheckCircleIcon,
        warning: WarningIcon,
        error: ErrorIcon,
        info: InfoIcon,
    };

    const changePage = (page) => {
        setPage(page);
    };
    const setrowsPerPageNumber = (page) => {
        setrowsPerPage(page);
    };

    const createActionButtons = (isActive, index) => {
        return isActive == true ? (
            <Button variant="contained" color="primary">
                Active
            </Button>
        ) : (
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => openInvitationDialog(index)}
                >
                    {" "}
        Send Invitation{" "}
                </Button>
            );
    };

    useEffect(() => {
        var arrayRows = [];
        ApiClientService.get(process.env.REACT_APP_API_GET_ALL_USER).then(
            (value) => {
                console.log("userss ", value.data.data);
                setUsers(value.data.data);
                var index = 0;
                value.data.data.forEach((user) => {
                    arrayRows.push({
                        email: user.email,
                        role: user.description,
                        isActive: createActionButtons(user.emailConfirmed, index),
                    });
                    index++;
                });
                setCount(arrayRows.length);
                arrayRows.forEach((row) => {
                    setRows((rows) => [...rows, row]);
                });
            }
        );
    }, []);

    function openInvitationDialog(index) {
        setInvitationDialog(true);
        setCurrentUserIndex(index);
    }
    function closeInvitationDialog() {
        setInvitationDialog(false);
    }

    async function sendInvitation() {
        await ApiClientService.post(
            process.env.REACT_APP_API_SEND_ACTIVATION +
            "?email=" +
            invitationEmail +
            "&username=" +
            users[currentUserIndex].userName
        )
            .then((response) => {
                if (
                    response.status == 200 ||
                    response.status == 201 ||
                    response.status == 204
                ) {
                    setNotificationVariant("success");
                    setNotificationMessage("Invitation mail send!");
                    closeInvitationDialog();
                    OpenNotification();
                }
            })
            .catch((error) => {
                console.log("errr ", error.response.data.errors)
                if (error.response.data.errors !== undefined) {
                    setNotificationMessage(error.response.data.errors[0]);
                } else {
                    setNotificationMessage("Fail");
                }
                setNotificationVariant("error");
                closeInvitationDialog();
                OpenNotification();
            });
    }

    function NotificationSnack(props) {
        const classes = snackStyle();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];

        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon className={clsx(classes.icon, classes.iconVariant)} />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }

    NotificationSnack.propTypes = {
        className: PropTypes.string,
        message: PropTypes.node,
        onClose: PropTypes.func,
        variant: PropTypes.oneOf(["success", "warning", "error", "info"])
            .isRequired,
    };

    function OpenNotification() {
        setNotification(true);
    }

    function CloseNotification(event, reason) {
        if (reason === "clickaway") {
            return;
        }

        setNotification(false);
    }

    return (
        <div>
            <DataTable
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                setPage={changePage}
                setRowsPerPage={setrowsPerPageNumber}
                rows={rows}
            ></DataTable>
            <div>
                <Dialog
                    open={invitationDialog}
                    onClose={closeInvitationDialog}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Email Invitation</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter the user's email address here. We will send an
                            activation email as soon as possible.
            </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Email Address"
                            type="email"
                            fullWidth
                            value={invitationEmail}
                            onChange={(e) => {
                                setInvitationEmail(e.target.value);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeInvitationDialog} color="primary">
                            Cancel
            </Button>
                        <Button onClick={sendInvitation} color="primary">
                            Send
            </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    open={Notification}
                    autoHideDuration={6000}
                    onClose={CloseNotification}
                >
                    <NotificationSnack
                        onClose={CloseNotification}
                        variant={notificationVariant} // "success"
                        message={notificationMessage} //"Invitation mail send!"
                    />
                </Snackbar>
            </div>
        </div>
    );
};

const snackStyle = makeStyles((theme) => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: "flex",
        alignItems: "center",
    },
}));

export default UserManagementPage;
