import React, { Fragment } from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import { styles } from "../style/style";
const Footer = (props) => {
  const { documentNo } = props;

  return (
    <View fixed style={{ position: 'absolute', bottom: 10, left: 0, right: 0, textAlign: "center" }}>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          marginHorizontal: "10%",
          marginTop: 2,
          minHeight: 15,
        }}
      >
        <View style={styles.rowCenter}>
          <Text
            style={{
              fontSize: 9,
              fontFamily: "Open Sans",
              fontWeight: 600,
            }}
          >
            DAILY SITE REPORT DOCUMENT
          </Text>
        </View>
      </View>
      <Fragment>
        <View style={[styles.containerRow, styles.footerContainer]}>
          <View
            style={[
              styles.footerTableContainer,
              {
                paddingLeft: 10,
              },
            ]}
          >
            <View
              style={[
                styles.materialTableRow,
                {
                  flex: 1,
                },
              ]}
            >
              <Text>
                If you have any query about this Daily Recordor any errors in
                delivery, please contact us within 2 working days.
              </Text>
            </View>
          </View>
        </View>

        <View style={[styles.containerRow, styles.footerContainer]}>
          <View
            style={[
              styles.footerTableContainer,
              {
                paddingLeft: 10,
              },
            ]}
          >
            <View
              style={[
                styles.materialTableRow,
                {
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "flex-start",
                  alignSelf: "center",
                },
              ]}
            >
              <Text>GFC-DRH-845</Text>
            </View>
            <View
              style={[
                styles.materialTableRow,
                {
                  flex: 1,
                },
              ]}
            >
              <Text>Rev03 Nov 2022</Text>
            </View>
            <View
              style={[
                styles.materialTableRow,
                {
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "flex-end",
                  alignSelf: "center",
                  paddingRight: 10,
                },
              ]}
            >
              <Text
                render={({ pageNumber, totalPages }) =>
                  `Page ${pageNumber} of ${totalPages}`
                }
              />
            </View>
          </View>
        </View>
        <View style={[styles.containerRow, styles.footerContainer]}>
          <View
            style={[
              styles.footerTableContainer,
              {
                backgroundColor: "#c00000",
                color: "#fff",
                fontWeight: 700,
              },
            ]}
          >
            <View
              style={[
                styles.materialTableRow,
                {
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "flex-end",
                  alignSelf: "center",
                  borderRight: 2,
                  borderRightColor: "white",
                  paddingRight: 10,
                },
              ]}
            >
              <Text>1.883.GEOFORM</Text>
            </View>
            <View
              style={[
                styles.materialTableRow,
                {
                  flex: 1,
                  borderRight: 2,
                  borderRightColor: "white",
                },
              ]}
            >
              <Text>www.geoforming.com</Text>
            </View>
            <View
              style={[
                styles.materialTableRow,
                {
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "flex-start",
                  alignSelf: "center",
                  paddingLeft: 10,
                },
              ]}
            >
              <Text>records@geoforming.com</Text>
            </View>
          </View>
        </View>
      </Fragment>
    </View>
  );
};
export default Footer;
