export function generateUUID() {
    let data = new Uint8Array(16);
    crypto.getRandomValues(data);
  
    data[6] = (data[6] & 0x0f) | 0x40;
    data[8] = (data[8] & 0x3f) | 0x80;
  
    const hex = Array.from(data)
      .map(byte => byte.toString(16).padStart(2, '0'))
      .join('');
  
    return `${hex.substr(0, 8)}-${hex.substr(8, 4)}-${hex.substr(12, 4)}-${hex.substr(16, 4)}-${hex.substr(20)}`;
  }
  