import SegmentService from "../../services/segment/segmentService";
import { dynamicSort } from "../Helpers";

export const getWorkingLocation = (stageId, setWorkingLocationOption) => {
  SegmentService.workingLocationGetAll(stageId)
    .then((response) => {
      if (response) {
        var data = response.data?.data;
        if (data && data.length > 0) {
          let workingLocationData = data.map((x) => ({
            label: x.workingLocation,
            value: x.id,
            docStgIds: x.docStgIds,
          }));
          workingLocationData.sort(dynamicSort("label"));
          setWorkingLocationOption(workingLocationData);
        }
        return response;
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {});
};

export const getSubWorkingLocation = (
  workingLocationId,
  setSubWorkingLocationOption
) => {
  SegmentService.subWorkingLocationGetAll(workingLocationId)
    .then((response) => {
      if (response) {
        var data = response.data?.data;
        if (data && data.length > 0) {
          let subWorkingLocationData = data.map((x) => ({
            label: x.workingLocation,
            value: x.id,
            docStgId: x.docStgId,
          }));
          subWorkingLocationData.sort(dynamicSort("label"));
          setSubWorkingLocationOption(subWorkingLocationData);
        }
        return response;
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {});
};

export const getProblemLocation = (
  subWorkingLocationId,
  setProblemLocationOption
) => {
  SegmentService.problemLocationGetAll(subWorkingLocationId)
    .then((response) => {
      if (response) {
        var data = response.data?.data;
        if (data && data.length > 0) {
          let problemLocationData = data.map((x) => ({
            label: x.workingLocation,
            value: x.id,
            docStgId: x.docStgId,
          }));
          problemLocationData.sort(dynamicSort("label"));
          setProblemLocationOption(problemLocationData);
        }
        return response;
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {});
};
