import moment from "moment";

export function minDateArray(dates, format) {
  return moment(
    dates.reduce(function(a, b) {
      return a > b ? b : a;
    })
  ).format(format);
}
export function maxDateArray(dates, format) {
  return moment(
    dates.reduce(function(a, b) {
      return a > b ? a : b;
    })
  ).format(format);
}
export function ConvertDecimalHour(decimalTime) {
  var decimalTimeString = decimalTime;
  var decimalTime = parseFloat(decimalTimeString);
  decimalTime = decimalTime * 60 * 60;
  var hours = Math.floor(decimalTime / (60 * 60));
  decimalTime = decimalTime - hours * 60 * 60;
  var minutes = Math.floor(decimalTime / 60);
  decimalTime = decimalTime - minutes * 60;
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  return {
    hour: parseInt(hours) ,
    minute: parseInt(minutes),
  };
}
export function TurkishOnlyDateFormat(date) {
  const [month, day, year] = date.split("/");
  return [day,month, year].join("-");
}