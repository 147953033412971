import axios from "axios";

const config = {
  method: "",
  url: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  data: null,
  params: null,
  withCredentials: true,
};

class ProjectService {
  async projectGetAll() {
    var newConfig = { ...config };
    newConfig.url = newConfig.url + process.env.REACT_APP_API_PROJECT_LIST;
    newConfig.method = "get";
    var result = await axios(newConfig);
    return result;
  }
}
export default new ProjectService();
