import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, FormControlLabel } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
  itemHeight: {
    minHeight: 40,
  },
}));

export default function CustomCheckBox(props) {
  const {data, single, setValue, name, value, color, checked} = props;
  const classes = useStyles();

  function handleChange(event) {
    let isChecked = event.target.checked;
    if (single) setValue(isChecked);
    else {
      setValue({ ...data, [name]: isChecked });
    }
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
        checked ={checked}
          onChange={(e) => handleChange(e)}
          value={value}
          color={color}
        />
      }
      label={props.label}
    />
  );
}
