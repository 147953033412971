import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import { FaInfoCircle } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
    height: 40,
    width: 40,
    fontSize: 15,
  },
  tooltip: {
    fontSize: 13,
    background:'rgb(27, 197, 189)',
  },
}));
export default function SimpleTooltips(props) {
  const classes = useStyles();
  const { color, title, label } = props;
  return (
    <div>
      <Tooltip title={title} aria-label={title} classes={{ tooltip: classes.tooltip }}>
        <Fab color="inherit" className={classes.fab}>
          <FaInfoCircle />
        </Fab>
      </Tooltip>
    </div>
  );
}
