import React from "react";
import { Image, View, Text } from "@react-pdf/renderer";
import { styles } from "../style/style";
const Signature = (props) => {
  const { item } = props;
  var isIncident = item.find((s) => s.isIncident) ? true : false;

  return (
    <>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          marginHorizontal: "10%",
          height: "1.8cm",
          borderTop: 1,
          paddingBottom: 2,
          marginTop: "1px",
          borderTopColor: "#000",
          borderBottom: 1,
          borderBottomColor: "#000",
        }}
      >
        <View
          style={{ flex: 2, flexDirection: "column", height: 50, padding: 5 }}
        >
          <View style={{ flexDirection: "row", flex: 1 }}>
            <View style={{ flex: 1 }}>
              <Text style={styles.signatureFirstText}>Date Executed:</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text style={styles.signatureFirstText}>
                ____________________
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: "row", flex: 1 }}>
            <View style={{ flex: 1 }}>
              <Text style={styles.signatureFirstText}>Prepared By:</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text style={styles.signatureFirstText}>
                ____________________
              </Text>
            </View>
          </View>
          <Text style={styles.signatureSecondText}>Signature (prep):</Text>
        </View>
        <View style={styles.materialBorderRight}></View>
        <View
          style={{
            flex: 1.2,
            flexDirection: "column",
            height: 50,
            padding: 5,
          }}
        >
          <View style={{ flexDirection: "row", flex: 1 }}>
            <Text style={styles.signatureFirstText}>Any Incidents</Text>
          </View>
          <View style={{ flexDirection: "row", flex: 1 }}>
            {!isIncident ? (
              <Image src="/media/pdf/checkbox.png" style={styles.checkbox} />
            ) : (
              <Image src="/media/pdf/unchecked.png" style={styles.checkbox} />
            )}
            <Text style={styles.signatureFirstText}>No Incidents</Text>
          </View>
          <View style={{ flexDirection: "row", flex: 1 }}>
            {isIncident ? (
              <Image src="/media/pdf/checkbox.png" style={styles.checkbox} />
            ) : (
              <Image src="/media/pdf/unchecked.png" style={styles.checkbox} />
            )}
            <Text style={styles.signatureFirstText}>Incident as Report</Text>
          </View>
        </View>
        <View style={styles.materialBorderRight}></View>
        <View
          style={{ flex: 2, flexDirection: "column", height: 50, padding: 5 }}
        >
          <View style={{ flexDirection: "row", flex: 1 }}>
            <View style={{ flex: 1 }}>
              <Text style={styles.signatureFirstText}>Reviewed by</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text style={styles.text}>____________________</Text>
            </View>
          </View>
          <Text style={styles.signatureSecondText}>Signature (client):</Text>
        </View>
      </View>
    </>
  );
};

export default Signature;
