import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./Routes";
import { I18nProvider } from "./shared/_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "./shared/layout";
import { MainContext } from "./context/context";
import UserSnackbars from "./components/pages/Snackbar";

export default function App({ basename }) {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    variant: "",
    timeout: 5000,
  });
  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  }
  var data = {snackbar, setSnackbar};
  return (
    <MainContext.Provider value={data}>
      <UserSnackbars
        open={snackbar.open}
        variant={snackbar.variant}
        message={snackbar.message}
        timeout={snackbar.timeout}
        handleClose={handleClose}
      />
      <React.Suspense fallback={<LayoutSplashScreen />}>
        {/* Override `basename` (e.g: `homepage` in `package.json`) */}
        <BrowserRouter basename={basename}>
          {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
          <MaterialThemeProvider>
            {/* Provide `react-intl` context synchronized with Redux state.  */}
            <I18nProvider>
              <Routes />
            </I18nProvider>
          </MaterialThemeProvider>
        </BrowserRouter>
      </React.Suspense>
    </MainContext.Provider>
  );
}
