import React, { Fragment, memo } from "react";
import { View, Text, Image } from "@react-pdf/renderer";
import { colors, styles } from "../style/style";

const Header = (props) => {
  const { tasks, header, date, address, projectEntry } = props;
  const leakStop = tasks?.filter((x) => x == "leak stop").length > 0;
  const stabilize = tasks?.filter((x) => x == "stabilize").length > 0;
  const voidFill = tasks?.filter((x) => x == "void fill").length > 0;
  const other = !leakStop && !stabilize && !voidFill;
  return (
    <View style={{ maxHeight: "6cm", minHeight: "6cm" }}>
      <View style={styles.pageTopContainer} fixed>
        <View
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Image
            src="/media/pdf/geoform_logo.jpg"
            style={{
              paddingVertical: "0.1cm",
              paddingHorizontal: "0.1cm",
              width: "4cm",
              height: "3cm",
              marginLeft: "-20px",
            }}
          />
        </View>
        <View
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Text
            style={[
              styles.headerGeoAddressText,
              { fontWeight: 700, fontSize: 10 },
            ]}
          >
            Geoform Canada Ltd.
          </Text>
          <Text style={styles.headerGeoAddressText}>
            2 - 2165 Buckingham Road
          </Text>
          <Text style={styles.headerGeoAddressText}>
            {" "}
            Oakville, Ontario L6H 0W7
          </Text>
          <Text style={styles.headerGeoAddressText}> Canada</Text>
        </View>
      </View>
      <View style={styles.pdfTitle} fixed>
        <View style={[styles.rowCenter, { height: "0.4cm" }]}>
          <Text
            style={{
              fontSize: 12,
              fontFamily: "Open Sans",
              fontWeight: 700,
            }}
          >
            DAILY REPORT - MATERIALS & TIMESHEET
          </Text>
        </View>
      </View>
      <View style={[styles.headerRow, { height: "13px" }]}>
        <View style={[styles.headerTableTd, { border: 0 }]}>
          <View
            style={{
              flex: 2,
            }}
          >
            <Text
              style={{
                fontSize: 9,
                fontFamily: "Open Sans",
                fontWeight: "700",
              }}
            >
              {header.bpName}
            </Text>
          </View>
          <View style={styles.materialBorderRight}></View>
          <View
            style={{
              flex: 1,
              borderTop: 1,
              flexDirection: "row",
            }}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: colors.grey,
                borderRight: 1,
                borderRightColor: "#000",
              }}
            >
              <Text
                style={{
                  fontSize: 8,
                  fontFamily: "Open Sans",
                  fontWeight: 700,
                  marginLeft: "3px",
                }}
              >
                Date
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 8,
                  fontFamily: "Open Sans",
                  marginLeft: "3px",
                }}
              >
                {date}
              </Text>
            </View>
          </View>
          <View style={styles.materialBorderRight}></View>
        </View>
      </View>
      <View style={[styles.headerRow, { height: "13px" }]}>
        <View style={[styles.headerTableTd, { border: 0 }]}>
          <View style={{ flex: 2 }}>
            <Text style={{ fontSize: 9 }}>{address.first}</Text>
          </View>
          <View style={styles.materialBorderRight}></View>
          <View
            style={{
              flex: 1,
              borderTop: 1,
              flexDirection: "row",
            }}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: colors.grey,
                borderRight: 1,
                borderRightColor: "#000",
              }}
            >
              <Text
                style={{
                  fontSize: 8,
                  fontFamily: "Open Sans",
                  fontWeight: 700,
                  marginLeft: "3px",
                }}
              >
                Document No:
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 8,
                  fontFamily: "Open Sans",
                  marginLeft: "3px",
                }}
              >
                {header.documentNo}
              </Text>
            </View>
          </View>
          <View style={styles.materialBorderRight}></View>
        </View>
      </View>
      <View style={[styles.headerRow, { height: "13px" }]}>
        <View style={[styles.headerTableTd, { border: 0 }]}>
          <View style={{ flex: 2 }}>
            <Text style={{ fontSize: 9 }}>{address.second}</Text>
          </View>
          <View style={styles.materialBorderRight}></View>
          <View
            style={{
              flex: 1,
              borderTop: 1,
              flexDirection: "row",
            }}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: colors.grey,
                borderRight: 1,
                borderRightColor: "#000",
              }}
            >
              <Text
                style={{
                  fontSize: 8,
                  fontFamily: "Open Sans",
                  fontWeight: 700,
                  marginLeft: "3px",
                }}
              >
                Page No:
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 8,
                  fontFamily: "Open Sans",
                  marginLeft: "3px",
                }}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber}/${totalPages}`
                }
              />
            </View>
          </View>
          <View style={styles.materialBorderRight}></View>
        </View>
      </View>
      <View style={[styles.headerRow, { height: "26px" }]}>
        <View style={styles.headerTableTd}>
          <View
            style={{
              flex: 2,
              flexDirection: "row",
            }}
          >
            <View style={{ flex: 1, borderRight: 1, borderRightColor: "#000" }}>
              <Fragment>
                <View
                  style={{
                    borderBottom: 1,
                    borderBottomColor: "#000",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "flex-start",
                    paddingLeft: "3px",
                    backgroundColor: colors.grey,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      fontFamily: "Open Sans",
                      fontWeight: 700,
                      fontStyle: "italic",
                    }}
                  >
                    Worksite:
                  </Text>
                </View>
                <View
                  style={{
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "flex-start",
                    paddingLeft: "3px",
                    paddingTop: "1px",
                    backgroundColor: colors.grey,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      fontFamily: "Open Sans",
                      fontWeight: 700,
                      fontStyle: "italic",
                    }}
                  >
                    Main Job:
                  </Text>
                </View>
              </Fragment>
            </View>
            <View style={{ flex: 6, borderRight: 1, borderRightColor: "#000" }}>
              <Fragment>
                <View
                  style={{
                    borderBottom: 1,
                    borderBottomColor: "#000",
                    justifyContent: "center",
                    alignContent: "flex-end",
                    alignItems: "flex-end",
                    paddingRight: "4px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 8,
                      fontFamily: "Open Sans",
                      fontWeight: 700,
                      fontStyle: "italic",
                    }}
                  >
                    {header.workSite}
                  </Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "flex-start",
                    paddingLeft: "3px",
                    flexDirection: "row",
                    paddingTop: "1px",
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      paddingTop: "1px",
                    }}
                  >
                    <Image
                      style={styles.checkbox}
                      src={
                        leakStop
                          ? "/media/pdf/checkbox.png"
                          : "/media/pdf/unchecked.png"
                      }
                    />
                    <Text style={styles.mainJobRow}>Leak Stop</Text>
                  </View>
                  <View style={styles.customHeaderBorderRight}></View>
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      paddingTop: "1px",
                    }}
                  >
                    <Image
                      style={styles.checkbox}
                      src={
                        stabilize
                          ? "/media/pdf/checkbox.png"
                          : "/media/pdf/unchecked.png"
                      }
                    />
                    <Text style={styles.mainJobRow}>Stabilize</Text>
                  </View>
                  <View style={styles.customHeaderBorderRight}></View>
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      paddingTop: "1px",
                    }}
                  >
                    <Image
                      style={styles.checkbox}
                      src={
                        voidFill
                          ? "/media/pdf/checkbox.png"
                          : "/media/pdf/unchecked.png"
                      }
                    />
                    <Text style={styles.mainJobRow}>Void Fill</Text>
                  </View>
                  <View style={styles.customHeaderBorderRight}></View>
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      paddingTop: "1px",
                    }}
                  >
                    <Image
                      style={styles.checkbox}
                      src={
                        other
                          ? "/media/pdf/checkbox.png"
                          : "/media/pdf/unchecked.png"
                      }
                    />
                    <Text style={styles.mainJobRow}>Other</Text>
                  </View>
                </View>
              </Fragment>
            </View>
          </View>
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <View
              style={{
                justifyContent: "center",
                alignContent: "center",
                flex: 1,
                backgroundColor: colors.grey,
                borderRight: 1,
                borderRightColor: "#000",
              }}
            >
              <Text
                style={{
                  fontSize: 8,
                  fontFamily: "Open Sans",
                  fontWeight: 700,
                  marginLeft: "3px",
                }}
              >
                PO No:
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 8,
                  fontFamily: "Open Sans",
                  marginLeft: "3px",
                }}
              >
                {header.poNum}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};
export default memo(Header);
