import React from "react";
import { useState } from "react";
import { handleAlertMessage, tableFilter } from "../../../helpers/Helpers";
import { Table, Tag } from "antd";
import { useEffect } from "react";
import ApiClientService from "../../../services/auth/api-client-service";
import { RoleTypes } from "../../../enums/roleTypeEnum";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../shared/_metronic/_helpers";
import SVG from "react-inlinesvg";
import { FaTrashAlt } from "react-icons/fa";
import { useContext } from "react";
import { MainContext } from "../../../context/context";
import UserDialog from "../../pages/Dialog";

var _ColorList = ["green", "geekblue", "volcano"];

function SurveyFlowChart() {
  const defaultFilters = [
    {
      DisplayOrder: [],
      Title: [],
      ShowRoleType: [],
      DisplayStep: [],
      Projects: [],
      Stgs: [],
      Actions: [],
    },
  ];
  const { snackbar, setSnackbar } = useContext(MainContext);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [deletedSurveyId, setDeletedSurveyId] = useState(0);
  const [filters, setFilters] = useState(defaultFilters);
  const [dialogOption, setDialogOption] = useState({
    open: false,
    title: "Survey Flow Deleting",
    body: "Are you sure you want to delete this record?",
    case1: "No",
    case2: "Yes",
  });

  function getData() {
    setLoading(true);
    ApiClientService.get(
      process.env.REACT_APP_API_GET_ALL_SURVEY_FLOW_LIST,
      null
    ).then((value) => {
      if (!value) return;
      let surveys = value.data.data;
      let data = [];
      if (surveys !== null) {
        surveys.forEach(function(survey) {
          data.push({
            DisplayOrder: survey.displayOrder,
            Title: survey.name,
            ShowRoleType: Object.keys(RoleTypes)[parseInt(survey.roleType) - 1],
            DisplayStep: survey.surveyDisplayStep.name,
            Projects: survey.projectSelects,
            Stgs: survey.stgSelects,
            Id: survey.id,
            Actions: createActionButton(survey),
          });
        });
        setRows(data);
        createHeaders();
        if (data.length > 0) createFilterData(data);
        setLoading(false);
      }
    }).finally(() => {
      setLoading(false);
    });
  }
  const sorterTable = (a, b) => {
    if (typeof a == "number") return a - b;
    else return a?.toString().localeCompare(b?.toString());
  };
  const createHeaders = () => {
    var headers = [];
    var object = defaultFilters[0];
    Object.keys(object || {}).forEach((key) => {
      if (key !== "Stgs" && key !== "Projects" && key !== "Actions") {
        headers.push({
          title: key,
          dataIndex: key,
          filters: object[key],
          filterMode: "tree",
          filterSearch: false,
          onFilter: (value, record) => tableFilter(record[key], value),
          sorter: (a, b) => sorterTable(a[key], b[key]),
        });
      } else if (key === "Stgs") {
        headers.push({
          title: key,
          key: key,
          dataIndex: key,
          render: (_, { Stgs }) => (
            <>
              {Stgs != null ? (
                Stgs?.map((stg) => {
                  var color =
                    _ColorList[Math.floor(Math.random() * _ColorList.length)];
                  return (
                    <Tag color={color} key={stg}>
                      {stg.label.toUpperCase()}
                    </Tag>
                  );
                })
              ) : (
                <label>All</label>
              )}
            </>
          ),
        });
      } else if (key === "Projects") {
        headers.push({
          title: key,
          key: key,
          dataIndex: key,
          render: (_, { Projects }) => (
            <>
              {Projects != null ? (
                Projects?.map((project) => {
                  return <Tag key={project}>{project.label.toUpperCase()}</Tag>;
                })
              ) : (
                <label></label>
              )}
            </>
          ),
        });
      } else if (key === "Actions") {
        headers.push({
          title: key,
          dataIndex: key,
        });
      }
    });
    setColumns(headers);
  };

  const createFilterData = (rows) => {
    if (rows.length <= 0) return;
    var array = defaultFilters[0];
    var object = defaultFilters[0];

    Object.keys(object || {}).forEach((key) => {
      rows.forEach((element) => {
        if (
          element[key] &&
          !object[key].some((x) => x.value === element[key])
        ) {
          array[key].push({
            text: element[key],
            label: element[key],
            value: element[key],
          });
        }
      });
    });
    setFilters(array);
  };


  function createActionButton(flowChart) {
    return (
      <div
        key={flowChart.id}
        className="d-flex justify-content-end align-items-end"
      >
        <Link
          to={{
            pathname: `/flow-chart-add-edit`,
            state: {
              params: {
                action: "update",
                flowChartId: flowChart.id,
              },
            },
          }}
          className="btn btn-icon btn-light btn-sm mr-2"
        >
          <span className="svg-icon svg-icon-md svg-icon-success">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")}
            ></SVG>
          </span>
        </Link>
        <button
          className="btn btn-danger btn-sm ml-2"
          onClick={(e) => handleDeleted(e, flowChart.id)}
        >
          <FaTrashAlt />
        </button>
      </div>
    );
  }

  const rejectAction = (e) => {
    e.preventDefault();
    setDialogOption({ ...dialogOption, open: false });
    setDeletedSurveyId(0);
  };
  const handleDeleted = (event, id) => {
    event.preventDefault();
    setDialogOption({ ...dialogOption, open: true });
    setDeletedSurveyId(id);
  };

  const deleteSurvey = () => {
    var data = {
      id: deletedSurveyId,
    };
    setDialogOption({ ...dialogOption, open: false });
    setLoading(true);
    ApiClientService.get(
      process.env.REACT_APP_API_SURVEYFLOW_SOFT_DELETE_BY_ID,
      data
    ).then((value) => {
      if (!value)
        handleAlertMessage(snackbar, setSnackbar, "An error occurred", "error");
      else if (
        value.status === 200 ||
        value.status === 201 ||
        value.status === 204
      ) {
        handleAlertMessage(
          snackbar,
          setSnackbar,
          "Delete successful.",
          "success"
        );
        setRows((prevState) => {
          prevState = prevState.filter((x) => x.Id !== deletedSurveyId);
          return prevState;
        });
        setFilters(defaultFilters);
        getData();
        
      } else {
        handleAlertMessage(snackbar, setSnackbar, "An error occurred", "error");
      }

      setLoading(false);
    });
  };
  useEffect(() => {
    createHeaders();
    getData();
  }, []);

  return (
    <>
      <UserDialog
        open={dialogOption.open}
        title={dialogOption.title}
        body={dialogOption.body}
        approveAction={deleteSurvey}
        rejectAction={rejectAction}
        case1={dialogOption.case1}
        case2={dialogOption.case2}
      ></UserDialog>
      <div className="assign-activities row mt-5">
        <div className="col-12 mt-4 d-flex justify-content-end align-items-center">
          <Link
            to={{
              pathname: `/flow-chart-add-edit`,
              state: {
                params: {
                  action: "add",
                },
              },
            }}
            className="btn btn-primary"
          >
            Create Survey Flow
          </Link>
        </div>
        <div className="col-12">
          <Table
            columns={columns}
            dataSource={rows}
            loading={loading}
            scroll={{ x: 1200 }}
          />
        </div>
      </div>
    </>
  );
}

export default SurveyFlowChart;
