import React, { useEffect, useState } from "react";
import { Card, message } from "antd";
import ApiClientService from "../../../services/auth/api-client-service";
import { distinctArray, dynamicSort } from "../../../helpers/Helpers";
import StageRuleList from "./components/StageRuleList";

function StageRulePage() {
  const [stageRules, setStageRules] = useState([]);
  const [loading, setLoading] = useState(true);

  const getStageData = () => {
    setLoading(true);
    ApiClientService.get(process.env.REACT_APP_API_STAGE_GET_ALL, null).then(
      (value) => {
        var data = value.data?.data;
        if (data && data.length > 0) {
          var stageData = data?.map((x) => ({
            label: x.name,
            visible: true,
          }));
          stageData = distinctArray(stageData, "label");
          stageData.sort(dynamicSort("label"));
          getStageRuleData(stageData);
        }
      }
    );
  };
  const getStageRuleData = (stages) => {
    ApiClientService.get(process.env.REACT_APP_API_STAGE_RULE_GET_ALL, null)
      .then((value) => {
        var data = value.data?.data;
        if (data) {
          var listArray = [];
          var stageData = data?.map((x) => ({
            label: x.stageName,
            visible: x.isVisible,
          }));
          stages.forEach((element) => {
            var tempStageRule = stageData?.find(
              (x) => x.label == element.label
            );
            if (!tempStageRule) listArray.push(element);
            else {
              listArray.push(tempStageRule);
            }
          });
          setStageRules(listArray);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const postAction = () => {
    setLoading(true);
    var stageRuleState = [...stageRules];
    var body = stageRuleState?.map((x) => ({
      StageName: x.label,
      IsVisible: x.visible,
    }));
    ApiClientService.post(
      process.env.REACT_APP_API_STAGE_RULE_SAVE_OR_UPDATE,
      body
    )
      .then((value) => {
        message.success("Stage Rule Settings Saved");
      })
      .catch((error) => {
        message.error("Stage Rule Settings is not Saved");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getStageData();
  }, []);

  return (
    <div className="assign-activities row mt-5">
      <div className="col-12 mt-4 d-flex justify-content-end align-items-center">
        <Card
          title="Stage Rule Visible Settings"
          bordered={true}
          extra={
            <button className="btn btn-primary" onClick={() => postAction()}>
              Save List
            </button>
          }
          style={{
            width: "100%",
          }}
        >
          <div className="row">
            <div className="col-sm-12">
              <StageRuleList
                data={stageRules}
                setStageRules={setStageRules}
                loading={loading}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default StageRulePage;
