import { StyleSheet } from "@react-pdf/renderer";

export const colors = {
  grey: "#f2f2f2",
  white: "#fff",
  black: "#000",
};
const headerTableRowHeight = "1.7cm";
export const styles = StyleSheet.create({
  section: {
    width: 200,
    flexFlow: 2,
  },
  text: {
    fontSize: 9,
    fontFamily: "Open Sans",
    fontWeight: 600,
  },
  activityText: {
    fontSize: 7,
    fontFamily: "Open Sans",
    fontWeight: 600,
  },
  tableText: {
    fontSize: 8,
  },
  tableText2: {
    fontSize: 7,
  },

  materialTableRow: {
    height: "0.7cm",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    alignContent: "center",
  },
  materialBorderRight: {
    borderRight: 1,
    borderRightColor: "#000",
  },
  tableLine: {
    width: 99,
    borderBottomColor: "#000",
    borderBottomWidth: 1,
  },
  tableLine2: {
    width: 210,
    borderBottomColor: "#000",
    borderBottomWidth: 1,
  },
  tableLine3: {
    width: 200,
    borderBottomColor: "#000",
    borderBottomWidth: 1,
  },
  topTableRightContainer: {
    alignItems: "flex-end",
    justifyContent: "flex-end",
    borderColor: "#000",
    borderWidth: 1,
    maxWidth: 100,
    backgroundColor: colors.white,
    height: "2.2cm",
    marginLeft: "-1",
  },
  topTableRight: {
    height: headerTableRowHeight,
    alignSelf: "center",
    paddingTop: 1,
  },
  topTableLeftContainer: {
    alignItems: "flex-end",
    justifyContent: "flex-end",
    borderColor: "#000",
    borderWidth: 1,
    maxWidth: 100,
    backgroundColor: colors.grey,
    height: "2.2cm",
  },
  topTableLeft: {
    height: headerTableRowHeight,
    alignSelf: "flex-start",
    paddingTop: 1,
    paddingLeft: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  topSecondTableLeftContainer: {
    alignItems: "flex-end",
    justifyContent: "flex-end",
    borderColor: "#000",
    borderWidth: 1,
    maxWidth: 60,
    backgroundColor: colors.grey,
    height: "1.6cm",
  },
  topSecondTableLeftLine: {
    width: 58,
    borderBottomColor: "#000",
    borderBottomWidth: 1,
  },
  topSecondTableRightContainer: {
    alignItems: "flex-end",
    justifyContent: "flex-end",
    borderColor: "#000",
    borderWidth: 1,
    width: 220,
    maxWidth: 218,
    backgroundColor: colors.white,
    height: "1.6cm",
    marginLeft: "-1",
  },
  topSecondTableRightLine: {
    width: 216,
    borderBottomColor: "#000",
    borderBottomWidth: 1,
  },
  topSecondTableRow: {
    borderColor: "#000",
    borderRightWidth: 1,
    backgroundColor: colors.white,
    height: "1cm",
    marginLeft: "-1px",
  },
  topSecondTableMainJobData: {
    display: "flex",
    flexDirection: "row",
    width: 54,
    borderRightWidth: 1,
    height: "0.8cm",
    alignItems: "center",
    justifyContent: "center",
  },
  materialTableContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    borderColor: "#000",
    borderWidth: 1,
    backgroundColor: colors.grey,
  },
  adress: { fontSize: 10, fontWeight: 600, fontFamily: "Open Sans" },
  addressContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  pdfTitle: {
    display: "flex",
    height: "1cm",
  },
  pageTopContainer: {
    display: "flex",
    height: "2cm",
    flexDirection: "row",
    marginHorizontal: "10%",
    marginTop: 15,
    borderBottomColor: "#e7e6e6",
    borderBottomWidth: 1,
  },
  activityTable: {
    alignSelf: "center",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  checkbox: {
    maxWidth: "9px",
    height: "9px",
    marginRight: 1,
  },
  tableMultiRow: {
    alignItems: "flex-start",
    alignContent: "flex-start",
    paddingLeft: 1,
    paddingRight: 1,
  },
  topTableMultiRow: {
    flexDirection: "row",
  },
  borderRight: {
    borderRightWidth: 1,
    borderRightColor: "black",
  },
  rowCenter: {
    flex: 1,
    flexDirection: "row",
    alignSelf: "center",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  containerRow: {
    flexDirection: "row",
    marginHorizontal: "10%",
    marginTop: "-1px",
  },
  tableTh: {
    width: "100%",
    height: 15,
  },
  tableThText: {
    fontSize: 10,
    fontWeight: 600,
    fontFamily: "Open Sans",
  },
  tableThSubText: {
    fontSize: 7,
  },
  activityTableRow1: {
    flex: 2,
    borderColor: "#000",
    borderWidth: 1,
    backgroundColor: colors.white,
    height: 20,
    marginLeft: "-1px",
  },
  equipmentTableRow: {
    borderColor: "#000",
    borderWidth: 1,
    backgroundColor: colors.white,
    marginLeft: "-1px",
  },
  footerText: {
    fontSize: 9,
    color: "#fff",
    fontFamily: "Open Sans",
    fontWeight: "600",
  },
  mainJobRow: {
    maxWidth: 50,
    flexWrap: "wrap",
    fontSize: 7,
    fontFamily: "Open Sans",
    fontWeight: 600,
  },
  groupBody: {
    minHeight: "15cm",
  },
  headerRow: {
    flexDirection: "row",
    marginHorizontal: "10%",
    marginTop: "-1px",
  },
  headerTableTd: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    borderColor: "#000",
    borderWidth: 1,
    backgroundColor: "#fff",
  },

  headerGeoAddressText: {
    fontSize: 8,
    fontFamily: "Open Sans",
  },
  customHeaderBorderRight: {
    flex: 0.2,
    borderRight: 1,
    marginTop: "-1px",
    borderRightColor: "#000",
    height: "12px",
    marginHorizontal: "2px",
  },
  footerContainer: {
    marginHorizontal: "10%",
    minHeight: "20px",
    maxHeight: "20px",
  },
  footerTableContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    backgroundColor: "#fff",
    fontSize: 8,
    fontFamily: "Open Sans",
    border: 0,
    color: "#000",
  },
  footerTableBorderContainer: {
    borderColor: "#000",
    borderWidth: 1,
    display: "flex",
    width: "100%",
    flexDirection: "row",
    backgroundColor: "#fff",
    fontSize: 8,
    fontFamily: "Open Sans",
    color: "#000",
  },
  hadi: {
    flex: 1,
    justifyContent: "flex-end",
    marginBottom: "3px",
    position: "relative",
  },
  body: {
    paddingBottom: "85px",
  },
  signatureFirstText: {
    fontFamily: "Open Sans",
    fontSize: 9,
    fontWeight: 700,
  },
  signatureSecondText: {
    fontFamily: "Open Sans",
    fontSize: 8,
    fontWeight: 700,
  },
});
export const indexStyles = StyleSheet.create({
  page: { paddingTop: 35, paddingBottom: 65 },
  title: { marginTop: "1%" },
  emphasis: { fontFamily: "Helvetica-Bold", color: "#F22300" },
  breakable: {
    width: "100%",
    marginBottom: "3px",
    flex: 1,
  },
  material: { width: "100%" },
  activity: { width: "100%" },
  equipment: { width: "100%" },
  timeSheet: { width: "100%" },
  footer: { width: "100%" },
});
