import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Row, Col } from "reactstrap";
import {
  GetProjectSelectList,
  GetUserSelectList,
} from "../../../helpers/SelectHelpers";
import { DatePicker } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

const FilterForm = (props) => {
  const { filter, setFilter } = props;
  const [users, setUsers] = useState();
  const [projects, setProjects] = useState();

  const handleChange = (e) => {
    var filterData = { ...filter };
    filterData.dateRange = e;
    filterData.startDate = e ? moment(e[0]).format("MM-DD-YYYY 00:00") : null;
    filterData.endDate = e ? moment(e[1]).format("MM-DD-YYYY 23:59") : null;

    setFilter(filterData);
  };
  const handleChangeSelect = (key, e) => {
    var filterData = { ...filter };
    filterData[key] = e;

    if (key === "project") filterData.projectEntry = e.entry;
    else if (key === "user") filterData.userId = e.value;

    setFilter(filterData);
  };
  useEffect(() => {
    GetUserSelectList(setUsers);
    GetProjectSelectList(setProjects);
  }, []);
  return (
    <Row className="mt-3">
      <Col xs={12} sm={6} xl={4}>
        <label>Project</label>
        <Select
          value={filter.Project}
          options={projects}
          isSearchable={true}
          onChange={(e) => handleChangeSelect("project", e)}
        />
      </Col>
      <Col xs={12} sm={6} xl={4}>
        <label>User</label>
        <Select
          value={filter.user}
          options={users}
          isSearchable={true}
          onChange={(e) => handleChangeSelect("user", e)}
        />
      </Col>
      <Col
        xs={12}
        sm={6}
        xl={4}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <label>Date Range</label>
        <RangePicker
          value={filter.dateRange}
          style={{ height: "39px" }}
          onChange={(e) => handleChange(e)}
        />
      </Col>
    </Row>
  );
};

export default FilterForm;
