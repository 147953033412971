import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Row, Col, Input, Button } from "reactstrap";
import { FaTimes } from "react-icons/fa";
import { questionStyles } from "../style/QuestionSetStyle";
import { SurveyContext, useContext } from "../../../../context/context";
import Question from "./Question";


function SubQuestionSet() {
  const { subQuestionSet, setSubQuestionSet } = useContext(SurveyContext);
  const classes = questionStyles();

  const handleChangeState = (e, param, index) => {
    e.preventDefault();
    const newState = subQuestionSet.map((obj, i) => {
      if (index === i) {
        return { ...obj, [param]: e.target.value };
      }
      return obj;
    });
    setSubQuestionSet(newState);
  };

  const addQuestion = (index) => {
    var newState = [...subQuestionSet];
    var defaultSurveyQuestion = {
      questionText: "",
      isRequired: false,
      freeText: "",
      keyboardType: {
        value: 1,
        label: "Alfanumeric",
      },
      questionType: {},
      surveyAnswers: [],
    };
    newState[index].surveyQuestions.push(defaultSurveyQuestion);
    setSubQuestionSet(newState);
  };

  const removeSubQuestionSet = (index) => {
    var newState = [];
    var tempArray = [...subQuestionSet];
    tempArray.forEach((element, i) => {
      if (index !== i) newState.push(element);
    });
    setSubQuestionSet(newState);
  };

  const RenderExpansion = (rows) => {
    return rows?.map((set, index) => (
      <Row className={classes.rowContainer}>
        <Col sm={11} key={["recipient", index].join("_")} className="mt-4">
          <div className={classes.root}>
            <ExpansionPanel
              defaultExpanded
              style={{ background: "transparent" }}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id={"panel1c-header" + index}
              >
                <div className="col-6 d-flex align-items-center">
                  <Typography className={classes.heading}>
                    {set.name}
                  </Typography>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Row className={classes.row}>
                  <Col sm={12}>
                    <label>Sub Question Set Name</label>
                    <Input
                      onChange={(e) => handleChangeState(e, "name", index)}
                      type="text"
                      name="name"
                      value={set.name}
                    />
                  </Col>
                  <Col sm={4} lg={4}>
                    <label>Display Order</label>
                    <Input
                      onChange={(e) =>
                        handleChangeState(e, "displayOrder", index)
                      }
                      type="number"
                      name="Display Order"
                      value={set.displayOrder}
                    />
                  </Col>
                  <Col
                    sm={8}
                    className="d-flex justify-content-end align-items-end"
                  >
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={(e) => addQuestion(index)}
                    >
                      Question Add
                    </button>
                  </Col>
                </Row>
                <Question
                  surveyQuestions={set.surveyQuestions}
                  setIndex={index}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </Col>
        <Col
          sm={1}
          className="mt-4 d-flex justify-content-end align-items-start"
        >
          <Button color="danger" onClick={() => removeSubQuestionSet(index)}>
            <FaTimes />
          </Button>
        </Col>
      </Row>
    ));
  };
  return <div>{RenderExpansion(subQuestionSet)}</div>;
}

export default SubQuestionSet;
