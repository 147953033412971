import React, { useContext, useState } from "react";
import { memo, useEffect } from "react";
import { StgCencusContext } from "../../../../context/context";
import { message, Table, Tooltip } from "antd";
import { FaTrashAlt } from "react-icons/fa";
import StgCencusService from "../../../../services/stg-cencus/stgCencusService";
import UserDialog from "../../../pages/Dialog";

var _DefaultHeader = {
  ProjectName: "",
  MainWork: "",
  RouteStage: "",
  Actions: null,
};
var NotHeader = ["StgId", "Id"];

export function StgCencusTable() {
  const [columns, setColumns] = useState();
  const {
    stgCencus,
    setStgCencus,
    rows,
    setRows,
    loading,
    setLoading,
  } = useContext(StgCencusContext);
  const [dialogConfig, setDialogConfig] = useState({
    open: false,
    deletedData: null,
    title: "Are you sure?",
    body: "The recording you selected will be deleted. Are you sure?",
    case1: "No",
    case2: "Yes",
  });

  const deleteStgCencus = async (e) => {
    setLoading(true);
    var newConfig = { ...dialogConfig };
    newConfig.open = false;
    newConfig.deletedData = null;
    setDialogConfig(newConfig);
    e.preventDefault();
    var deletedData = dialogConfig.deletedData;
    if (deletedData.Id != 0) {
      var body = {
        Id: deletedData.Id,
      };

      StgCencusService.stgCencusDelete(body)
        .then((response) => {
          if (response.status === 200) {
            message.success("Cencus Deleted");
            setStgCencus(stgCencus.filter((x) => x.Id !== deletedData.Id));
          } else {
            message.success("Cencus not Deleted");
          }
        })
        .catch((error) => {
          console.log(error);
          message.error("System Error!");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setStgCencus(stgCencus.filter((x) => x.StgId !== deletedData.StgId));
    }
  };

  const handleDeleted = async (e, cencus) => {
    e.preventDefault();
    var newConfig = { ...dialogConfig };
    newConfig.open = true;
    newConfig.deletedData = cencus;
    setDialogConfig(newConfig);
  };
  const rejectAction = (e) => {
    e.preventDefault();
    var newConfig = { ...dialogConfig };
    newConfig.open = false;
    newConfig.deletedData = null;
    setDialogConfig(newConfig);
  };

  const createAction = (cencus) => {
    return (
      <div
        key={cencus.Id}
        className="d-flex justify-content-end align-items-end"
      >
        <Tooltip placement="top" title="Delete">
          <button
            className="btn btn-danger btn-sm mr-2"
            onClick={(e) => handleDeleted(e, cencus)}
          >
            <FaTrashAlt />
          </button>
        </Tooltip>
      </div>
    );
  };

  const createModel = () => {
    setRows(
      stgCencus.map((x) => ({
        ProjectName: x.ProjectName ?? "No registered projects",
        ProjectId: x.ProjectId,
        MainWork: x.StageName ?? "No registered stages",
        StageId: x.StageId,
        RouteStage: x.StgName,
        StgId: x.StgId,
        Actions: createAction(x),
      }))
    );
    createHeader();
  };

  const createHeader = () => {
    var headers = [];
    Object.keys(_DefaultHeader).forEach((key) => {
      if (!NotHeader.includes(key)) {
        headers.push({
          title: key,
          key: key,
          dataIndex: key,
        });
      }
    });
    setColumns(headers);
  };
  useEffect(() => {
    createModel();
  }, [stgCencus]);
  return (
    <>
      <UserDialog
        open={dialogConfig.open}
        title={dialogConfig.title}
        body={dialogConfig.body}
        approveAction={deleteStgCencus}
        rejectAction={rejectAction}
        case1={dialogConfig.case1}
        case2={dialogConfig.case2}
      ></UserDialog>
      <Table columns={columns} dataSource={rows} loading={loading} />;
    </>
  );
}
export default memo(StgCencusTable);
