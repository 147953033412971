import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Row, Col, Button, Input } from "reactstrap";
import { useHistory } from "react-router-dom";
import { FaPlus, FaTimes } from "react-icons/fa";
import ImageUpload from "../../pages/multiImage";
import ApiClientService from "../../../services/auth/api-client-service";
import moment from "moment";
import UserDialog from "../../pages/Dialog";
import UserSnackbars from "../../pages/Snackbar";

function DamageAddEdit(props) {
  //#region Default definations
  const history = useHistory();
  const defaultMaterialRow = [
    { id: Math.floor(Math.random() * 10), value: "" },
  ];
  const defaultMaterialOptions = [
    { label: "", value: "", quantity: 0, uom: "" },
  ];

  var defaultdamageObject = {
    Id: 0,
    workedDate: "",
    description: "",
    materialData: null,
    materialQuantity: 0,
  };
  const [navigateData, setNavigateData] = useState(
    props.history.location.state
  );
  const [materialRows, setMaterialRows] = useState(defaultMaterialRow);
  const [damageMaterialData, setdamageMaterialData] = useState(
    defaultMaterialOptions
  );

  const [damageData, setdamageData] = useState(defaultdamageObject);

  const [materialOptions, setMaterialOptions] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState("Damage Add");
  const [popupBody, setPopupBody] = useState(
    "You are adding an damage for the operation you selected, are you sure you want to add it?"
  );
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [variantSnackbar, setVariantSnackbar] = useState("");
  const [messageSnackbar, setMessageSnackbar] = useState("");
  const timeout = 5000;
  const [file, setFile] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  //#endregion

  const getProjectData = (damageParam = null) => {
    ApiClientService.get(
      process.env.REACT_APP_API_GET_ASSIGN_ACTIVITIES,
      null
    ).then((value) => {
      var repsonse = value.data.data;
      if (repsonse != null) {
        var props = navigateData.params;
        try {
          var project = repsonse.find(
            (x) => x.absEntry == props.pEntry && x.name == props.projectName
          );
          var stage = project.stages.find((x) => x.name == props.stageName);
          var doc = stage.docs.find(
            (x) => x.num == props.docNum && x.entry == props.docEntry
          );
          var docStg = doc.docStgs.find((x) => x.id == props.docStgId);

          var date = new Date();
          var maxDate = moment(date).format("YYYY-MM-DD");
          var minDate = moment(date.setDate(date.getDate() - 2)).format(
            "YYYY-MM-DD"
          );

          if (props.action == "update") {
            var startDate = moment(
              damageParam.stageEmployee.workStartTime
            ).format("YYYY-MM-DD");
            var endDate = moment(damageParam.stageEmployee.workEndTime).format(
              "YYYY-MM-DD"
            );
            if (
              startDate <= damageParam.workedDate &&
              damageParam.workedDate <= endDate
            ) {
              setIsDisabled(false);
              minDate = startDate;
            } else {
              setMessageSnackbar(
                "Since the working dates are old dates, you cannot enter activities on these dates."
              );
              setVariantSnackbar("warning");
              setOpenSnackbar(true);
              setIsDisabled(true);
            }
          } else {
            var startDate = moment(props.workStartDate).format("YYYY-MM-DD");
            var endDate = moment(props.workedEndDate).format("YYYY-MM-DD");
            if (minDate > endDate) {
              setMessageSnackbar(
                "Since the working dates are old dates, you cannot enter activities on these dates."
              );
              setVariantSnackbar("warning");
              setOpenSnackbar(true);
              setIsDisabled(true);
            }
            if (minDate <= startDate) minDate = startDate;
            if (maxDate >= endDate) maxDate = endDate;
          }

          var arrayRows = [
            {
              projectName: project.name,
              docNum: doc.num,
              stgName: docStg.stg.desc,
              maxDate: maxDate,
              minDate: minDate,
            },
          ];
          setProjectData({ ...projectData, ...arrayRows[0] });
        } catch (e) {
          console.log("Bir hata oluştu..");
        }
      }
    });
  };

  const distinctData = (array) => {
    return array.filter(
      (element, index) =>
        array.findIndex((obj) => obj.value === element.value) === index
    );
  };

  //#region Get Options Data
  const getOptionsData = (params) => {
    var request = {
      docStgId: params.docStgId,
    };
    ApiClientService.get(
      process.env.REACT_APP_API_GET_ALL_ITEMS,
      request
    ).then((value) => {
      if (!value) return;

      var data = value.data.data;
      var arrayMaterial = [];

      data.forEach(element => {
        arrayMaterial.push({
          value: element.code,
          label: element.name == null ? element.code : element.name,
          quantity: 0,
          uom: element.inventoryUoM,
        });
      });
      setMaterialOptions(arrayMaterial);

      getProjectData();
    });
  };
  //#endregion

  //#region Add-Delete Consumed Material Component Rows
  const addRowsConsumedMaterialComp = (e) => {
    var index = Object.keys(damageMaterialData).length;
    setdamageMaterialData({
      ...damageMaterialData,
      [index]: defaultMaterialOptions[0],
    });
    setMaterialRows((materialRows) => [
      ...materialRows,
      {
        id: Math.floor(Math.random() * 100),
        value: "",
      },
    ]);
    e.preventDefault();
  };
  const removeRowsConsumedMaterialComp = (index, e, id) => {
    var data = [];
    var length = Object.keys(damageMaterialData).length;
    for (var i = 0; i < length; i++) {
      if (index != i) data.push(damageMaterialData[i]);
    }
    setdamageMaterialData(data);
    var array = materialRows.filter((i) => i.id !== id);
    setMaterialRows(array);
    e.preventDefault();
  };
  const materialRowChangeValue = (objIndex, e, isSelect) => {
    var data = e;
    if (!isSelect) {
      data = {
        quantity: e,
        label: damageMaterialData[objIndex].label,
        value: damageMaterialData[objIndex].value,
        uom: damageMaterialData[objIndex].uom,
      };
    } else {
      data = {
        quantity: damageMaterialData[objIndex].quantity,
        label: e.label,
        value: e.value,
        uom: e.uom,
      };
    }
    setdamageMaterialData({ ...damageMaterialData, [objIndex]: data });
  };

  //#endregion

  //#region MultiComponent
  const consumedMaterialComponent = (materialRows) => {
    return materialRows.map((cusRow, index) => (
      <Row key={index} className={index > 0 ? "mt-3" : ""}>
        <Col xs={12} sm={6} md={4}>
          <label>Consumed Materials</label>
          <Select
            value={damageMaterialData[index]}
            onChange={(e) => materialRowChangeValue(index, e, true)}
            options={materialOptions}
          />
        </Col>
        <Col xs={6} sm={4} md={4}>
          <label>Inventory Uom</label>
          <div className="input-group">
            <input
              className="form-control"
              id="inventoryUom"
              placeholder="103"
              value={damageMaterialData[index].quantity}
              onChange={(e) =>
                materialRowChangeValue(index, e.target.value, false)
              }
            />
            <div className="input-group-prepend">
              <span
                className="input-group-text"
                id="validationTooltipUsernamePrepend"
              >
                {!damageMaterialData[index].uom ||
                damageMaterialData[index].uom == null ||
                damageMaterialData[index].uom.trim() == ""
                  ? "number"
                  : damageMaterialData[index].uom}
              </span>
            </div>
          </div>
        </Col>
        <Col
          xl={4}
          className="d-flex justify-content-end align-items-end col-6 col-sm-2 col-md-4"
        >
          {index == 0 ? (
            <Button
              color="primary"
              onClick={(e) => addRowsConsumedMaterialComp(e)}
            >
              <FaPlus />
            </Button>
          ) : (
            <Button
              color="danger"
              onClick={(e) =>
                removeRowsConsumedMaterialComp(index, e, cusRow.id)
              }
            >
              <FaTimes />
            </Button>
          )}
        </Col>
      </Row>
    ));
  };
  //#endregion

  useEffect(() => {
    if (!navigateData) return;
    getOptionsData(navigateData.params);
    localStorage.setItem("DamageActivity", JSON.stringify(navigateData.params));
  }, []);

  //#region StyleSheet
  const textAreaStyle = {
    resize: "none",
    height: "150px",
  };
  //#endregion
  const openDialog = (e) => {
    e.preventDefault();
    setOpenPopup(true);
  };
  function rejectAction() {
    setOpenPopup(false);
  }
  const closeSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handleAlert = (message, type) => {
    setMessageSnackbar(message);
    setVariantSnackbar(type);
    setOpenSnackbar(true);
    setOpenPopup(false);
  };

  const handleSubmit = () => {
    setOpenPopup(false);
    var damageItemLength = Object.keys(damageMaterialData).length;
    var formdata = new FormData();

    try {
      if (damageData.workedDate == "")
        return handleAlert("Worked date not empty", "warning");
      for (var i = 0; i < damageItemLength; i++) {
        if (
          damageMaterialData[i].value.trim() == "" &&
          (damageMaterialData[i].quantity == null ||
            damageMaterialData[0].quantity <= 0)
        )
          return handleAlert("Material data not empty", "warning");
        if (damageMaterialData[i].value.trim() == "")
          return handleAlert("Material data not empty", "warning");
        if (
          damageMaterialData[i].quantity == null ||
          damageMaterialData[0].quantity <= 0
        )
          return handleAlert("Inventory Uom data not empty", "warning");

        formdata.append(
          "DamageItems[" + i + "][Quantity]",
          damageMaterialData[i].quantity
        );
        formdata.append(
          "DamageItems[" + i + "][Name]",
          damageMaterialData[i].label
        );
        formdata.append(
          "DamageItems[" + i + "][InventoryUom]",
          damageMaterialData[i].uom
        );
      }
      file.forEach(function(obj) {
        formdata.append("Files", obj.image, obj.url);
      });
      formdata.append("Description", damageData.description);
      formdata.append("WorkedDate", damageData.workedDate);
      formdata.append("DocStgId", navigateData.params.docStgId);
      formdata.append("ProjectName", navigateData.params.projectName);
      formdata.append("StageName", navigateData.params.stageName);
      formdata.append("StgName", navigateData.params.stgDesc);
      formdata.append("DocNum", navigateData.params.docNum);

      ApiClientService.post(
        process.env.REACT_APP_API_CREATE_DAMAGE,
        formdata
      ).then((value) => {
        if (value.status == 200 || value.status == 201 || value.status == 204) {
          handleAlert("Update process successful.", "success");
          setTimeout(() => history.push("/damage-list"), 1000);
        } else {
          handleAlert("An error occurred.", "error");
        }
      });
    } catch (e) {
      handleAlert("Please fill in the blanks", "warning");
    }
  };

  return (
    <div className="container">
      <UserDialog
        open={openPopup}
        title={popupTitle}
        body={popupBody}
        approveAction={handleSubmit}
        rejectAction={rejectAction}
      ></UserDialog>

      <UserSnackbars
        open={openSnackbar}
        variant={variantSnackbar}
        message={messageSnackbar}
        timeout={timeout}
        handleClose={closeSnackbar}
      ></UserSnackbars>
      <div className="row">
        <div className="col-12">
          <div className="card p-5">
            <form className="needs-validation">
              <div className="form-row">
                <div className="col-12 col-sm-4 mb-3">
                  <label>Worked Date</label>
                  <input
                    type="date"
                    className="form-control"
                    id="workedDate"
                    min={projectData.minDate}
                    max={projectData.maxDate}
                    disabled={isDisabled}
                    value={damageData.workedDate}
                    onChange={(e) =>
                      setdamageData({
                        ...damageData,
                        workedDate: e.target.value,
                      })
                    }
                    required
                  />
                </div>
                <div
                  className="col-md-6 mt-3 align-items-center p-5"
                  style={{ display: "grid" }}
                ></div>
              </div>
              <hr />
              <div className="form-row">
                <div className="col-12 mb-3">
                  {consumedMaterialComponent(materialRows)}
                </div>
              </div>
              <hr />
              <div className="form-row">
                <div className="col-12 col-xl-6">
                  <label>Description</label>
                  <Input
                    type="textarea"
                    name="text"
                    style={textAreaStyle}
                    value={damageData.description}
                    onChange={(e) =>
                      setdamageData({
                        ...damageData,
                        description: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col-12">
                  <label>Image</label>
                  <ImageUpload setFile={setFile} file={file} isShow={false} />
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary mt-3"
                onClick={openDialog}
                disabled={isDisabled}
              >
                Save
              </button>
              {isDisabled ? (
                <label class="text-danger p-5">
                  <strong>
                    Since the working dates are old dates, you cannot enter
                    activities on these dates.
                  </strong>
                </label>
              ) : (
                ""
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default DamageAddEdit;
