import React, { useContext } from "react";
import { FlowmeterContext } from "../../../../context/context";
import { Row, Col, Button, Input } from "reactstrap";
import { FaPlus, FaTimes } from "react-icons/fa";
import Select from "react-select";

function ItemComponent({ itemFlowSelect }) {
  const { flowItems, setFlowItems } = useContext(FlowmeterContext);

  const handleChange = (value, index, param) => {
    let newState = [...flowItems];
    newState[index][param] = value;
    setFlowItems(newState);
  };

  const addRow = () => {
    var defaultItem = {
      item: { label: "", value: "" },
      cId: "",
      flowMeterName: "",
      port: "",
    };
    var newState = [...flowItems];
    newState.push(defaultItem);
    setFlowItems(newState);
  };

  const removeRow = (index) => {
    var newState = [];
    var tempArray = [...flowItems];
    tempArray.forEach((element, i) => {
      if (index != i) newState.push(element);
    });
    setFlowItems(newState);
  };

  return (
    <div>
      {flowItems?.map((item, index) => (
        <Row key={1 + index}>
          <Col xs={12} sm={6} md={3} xl={3}>
            <label>Item</label>
            <Select
              value={item.item}
              options={itemFlowSelect}
              isSearchable={false}
              isClearable={false}
              onChange={(e) => handleChange(e, index, "item")}
            />
          </Col>
          <Col xs={12} sm={6} md={3} xl={3}>
            <label>CID</label>
            <Input
              type="text"
              name="cId"
              value={item.cId}
              onChange={(e) => handleChange(e.target.value, index, "cId")}
            />
          </Col>
          <Col xs={12} sm={6} md={3} xl={3}>
            <label>Flowmeter Name</label>
            <Input
              type="text"
              name="flowMeterName"
              value={item.flowMeterName}
              onChange={(e) =>
                handleChange(e.target.value, index, "flowMeterName")
              }
            />
          </Col>
          <Col xs={12} sm={4} md={2} xl={2}>
            <label>Port</label>
            <Input
              type="text"
              name="Port"
              value={item.port}
              onChange={(e) => handleChange(e.target.value, index, "port")}
            />
          </Col>
          <Col
            sm={1}
            md={1}
            className="d-flex justify-content-end align-items-end"
          >
            {index == 0 ? (
              <Button color="primary" onClick={(e) => addRow(e)}>
                <FaPlus />
              </Button>
            ) : (
              <Button color="danger" onClick={(e) => removeRow(index)}>
                <FaTimes />
              </Button>
            )}
          </Col>
        </Row>
      ))}
    </div>
  );
}

export default ItemComponent;
