import React, { useState, useEffect } from "react";
import ApiClientService from "../../../services/auth/api-client-service";
import { Table } from "antd";
import moment from "moment";
import { tableFilter } from "../../../helpers/Helpers";

function CustomerApprovedActivities(props) {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [filters, setFilters] = useState([
    {
      ProjectName: [],
      MainWork: [],
      DocNum: [],
      RouteStage: [],
      Employee: [],
      "Working Location": [],
      Task: [],
      IssueID: [],
      Date: [],
      StartHour: [],
      EndHour: [],
    },
  ]);

  function getData() {
    setRows([]);
    var arrayRows = [];
    var promises = [];
    ApiClientService.get(
      process.env.REACT_APP_API_CUSTOMER_APPROVE_ISSUE,
      null
    ).then((value) => {
      var issueList = value.data.data;
      if (issueList != null) {
        issueList.forEach(function(issue) {
          let employeeName = "";
          let params = {
            userId: issue.userId,
          };
          promises.push(
            ApiClientService.get(
              process.env.REACT_APP_API_GET_USER_BY_ID,
              params
            ).then((value) => {
              var employee = value.data.data;

              if (employee != null) {
                employeeName =
                  employee.firstName !== null
                    ? employee.lastName !== null
                      ? employee.firstName + " " + employee.lastName
                      : employee.userName !== null
                      ? employee.userName
                      : ""
                    : "";
              }
              issue.employeeName = employeeName;
              arrayRows.push(createModel(issue));
            })
          );
        });
        Promise.all(promises).then(() => {
          arrayRows.forEach((row) => {
            setRows((rows) =>
              [...rows, row].sort((a, b) =>
                a.employeStartTime < b.employeStartTime ? -1 : 1
              )
            );
          });
          createFilterData(arrayRows);
          setLoading(false);
        });
      }
    });
  }

  const createHeaders = () => {
    var headers = [];
    Object.keys(filters[0]).forEach((key) => {
      headers.push({
        title: key,
        key: key,
        dataIndex: key,
        filters: filters[0][key],
        filterMode: "tree",
        filterSearch: false,
        onFilter: (value, record) => tableFilter(record[key], value),
        sorter: (a, b) => a[key]?.toString().localeCompare(b[key]?.toString()),
      });
    });
    setColumns(headers);
  };

  const createFilterData = (rows) => {
    var array = filters[0];
    Object.keys(filters[0]).forEach((key) => {
      rows.forEach((element) => {
        if (
          element[key] !== null &&
          element[key] !== "" &&
          element[key] !== "Invalid date" &&
          !array[key].some((x) => x.value === element[key])
        )
          array[key].push({
            text: element[key],
            label: element[key],
            value: element[key],
          });
      });
    });
    setLoading(false);
    setFilters(array);
  };
  function createModel(issue) {
    let source;
    return (source = {
      ProjectName: issue.projectName,
      MainWork: issue.stageName,
      DocNum: issue.docNum,
      RouteStage: issue.stgDesc,
      Employee: issue.employeeName,
      "Working Location": issue.workingLocation,
      Task: issue.taskDesc,
      IssueID: issue.id,
      Date: moment(issue.startDate).format("DD/MM/YYYY"),
      StartHour: moment(issue.startDate).format("HH:mm:ss"),
      EndHour: moment(issue.endDate).format("HH:mm:ss"),
    });
  }
  useEffect(() => {
    createHeaders();
    getData();
  }, []);

  return (
    <div className="approved-activities">
      <div className="row">
        <div className="col-12">
          <Table
            key="customer-approved-table"
            columns={columns}
            dataSource={rows}
            scroll={{ x: 1700 }}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}

export default CustomerApprovedActivities;
