import React, { useState } from "react";

import "./styles.css";

const MailInput = (props) => {
  const { mailProp, setMailProp } = props;

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = mailProp.value.trim();

      if (value && isValid(value)) {
        setMailProp({
          items: [...mailProp.items, mailProp.value],
          value: "",
        });
      }
    }
  };

  const handleChange = (evt) => {
    setMailProp(
      { ...mailProp, value: evt.target.value },
      { ...mailProp, error: null }
    );
  };

  const handleDelete = (item) => {
    setMailProp({
      ...mailProp,
      items: mailProp.items.filter((i) => i !== item),
    });
  };

  const handlePaste = (evt) => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter((email) => !isInList(email));
      setMailProp({ ...mailProp, items: [...mailProp.items, ...toBeAdded] });
    }
  };

  const isValid = (email) => {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setMailProp({ ...mailProp, error: error });
      return false;
    }

    return true;
  };

  const isInList = (email) => {
    return mailProp.items.includes(email);
  };

  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };

  return (
    <>
      <input
        className={"input " + (mailProp.error && " has-error")}
        value={mailProp.value}
        placeholder="Type or paste email addresses and press `Enter`..."
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onPaste={handlePaste}
      />
      {mailProp.items.map((item) => (
        <div className="tag-item" key={item}>
          {item}
          <button
            type="button"
            className="button"
            onClick={() => handleDelete(item)}
          >
            &times;
          </button>
        </div>
      ))}
      {mailProp.error && <p className="error">{mailProp.error}</p>}
    </>
  );
};
export default MailInput;
