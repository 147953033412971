import React, { useState } from "react";
import { Col, InputNumber, Row, Slider } from "antd";

const RangeSlider = (props) => {
  const { inputValue, setInputValue } = props;
  const onChange = (newValue) => {
    setInputValue(newValue);
  };
  return (
    <>
      <Row>
        <Col span={12}>
          <Slider
            min={1}
            max={100}
            onChange={onChange}
            value={typeof inputValue === "number" ? inputValue : 0}
          />
        </Col>
        <Col span={4}>
          <InputNumber
            min={0}
            max={100}
            style={{
              margin: "0 16px",
            }}
            value={inputValue}
            onChange={onChange}
          />
        </Col>
      </Row>
    </>
  );
};
export default RangeSlider;
