import axios from "axios";

const config = {
  method: "",
  url: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  data: null,
  params: null,
  withCredentials: true,
};

class StgCencusService {
  async stgCencusGetAll() {
    var newConfig = { ...config };
    newConfig.url = newConfig.url + process.env.REACT_APP_API_STGCENCUS_GET_ALL;
    newConfig.method = "get";

    var result = await axios(newConfig);
    return result;
  }
  async stgCencusDelete(body) {
    var newConfig = { ...config };
    newConfig.url = newConfig.url + process.env.REACT_APP_API_STGCENCUS_DELETE;
    newConfig.method = "get";
    newConfig.params = body;

    var result = await axios(newConfig);
    return result;
  }
  async stgCencusCreate(body) {
    var newConfig = { ...config };
    newConfig.data = body;
    newConfig.url = newConfig.url + process.env.REACT_APP_API_STGCENCUS_CREATE;
    newConfig.method = "post";

    var result = await axios(newConfig);
    return result;
  }
  async stgCencusUpdated(body) {
    var newConfig = { ...config };
    newConfig.data = body;
    newConfig.url = newConfig.url + process.env.REACT_APP_API_STGCENCUS_UPDATE;
    newConfig.method = "post";

    var result = await axios(newConfig);
    return result;
  }
}
export default new StgCencusService();
