import { UploadOutlined, InboxOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import readXlsxFile from "read-excel-file";
import React from "react";
import { QuestionTypeListEnum } from "../../enums/questionTypeEnum";
import { KeyboardTypeEnum } from "../../enums/keyboardTypeEnum";
import { groupBy } from "../../helpers/Helpers";
import { StringToBoolean } from "../../helpers/ConverterHelpers";
import { IsNullOrEmpty } from "../../helpers/ValidateHelpers";

function ImportExcel(props) {
  const { Dragger } = Upload;
  const { setSubQuestionSet, subQuestionSet } = props;
  const uploadProps = {
    accept: ".xlsx",
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      const isExcel =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isExcel) {
        message.error(`${file.name} is not a xlsx file`);
        return Upload.LIST_IGNORE;
      }
      return false;
    },
    onChange: (info) => {
      try {
        readXlsxFile(info.file).then((rows) => {
          var data = [];
          for (let i = 0; i < rows.length; i++) {
            if (i == 0) continue;
            data.push({
              subQuestionSetName: rows[i][0],
              displayOrder: rows[i][1],
              question: {
                questionText: rows[i][2],
                questionType: rows[i][3],
                displayOrder: rows[i][4],
                freeText: rows[i][5],
                isRequired: rows[i][7],
                asnwer: rows[i][8],
                surveyAnswers: rows[i][8],
                keyboardType: rows[i][6],
              },
            });
          }
          var subQuestionSetArr = [];
          var grouppedQuestionSet = groupBy(data, "subQuestionSetName");

          Object.keys(grouppedQuestionSet).forEach((key) => {
            let questions = [];
            let questionAnswers = [];
            let defaultAnswer = "";
            var tmpSubQuestionSetArr = {
              name: key,
              surveyQuestions: questions,
              displayOrder: 0,
            };
            grouppedQuestionSet[key].forEach(function(element) {
              var freeText = "";
              var keyboardType = {};
              var keyboardTypeOptions = [
                KeyboardTypeEnum.Alfanumeric,
                KeyboardTypeEnum.Numeric,
                KeyboardTypeEnum.Date,
              ];
              var questionTypeOptions = [
                QuestionTypeListEnum.Multiple,
                QuestionTypeListEnum.Yes_No,
                QuestionTypeListEnum.Free_Text,
                QuestionTypeListEnum.Form,
              ];
              if (
                element.question.questionType ==
                QuestionTypeListEnum.Free_Text.value
              ) {
                freeText = element.freeText;
                keyboardType = keyboardTypeOptions.find(
                  (x) => x.value == element.question.keyboardType
                );
              } else if (
                element.question.questionType ==
                QuestionTypeListEnum.Multiple.value
              ) {
                var surveyAnswers = element.question.surveyAnswers.split(";");
                questionAnswers = surveyAnswers?.map((x) => ({
                  answerText: x,
                }));
              } else if (
                element.question.questionType ==
                QuestionTypeListEnum.Yes_No.value
              ) {
                defaultAnswer = element.question.surveyAnswers;
              }
              questions.push({
                questionText: element.question.questionText,
                questionType: questionTypeOptions.find(
                  (x) => x.value == element.question.questionType
                ),
                displayOrder: element.question.displayOrder,
                freeText: element.question.freeText,
                isRequired:
                  typeof element.question.isRequired == "boolean"
                    ? element.question.isRequired
                    : StringToBoolean(element.question.isRequired),
                defaultAnswer: defaultAnswer,
                surveyAnswers: questionAnswers,
                keyboardType: keyboardType,
              });
              tmpSubQuestionSetArr.displayOrder = element.displayOrder;
            });
            tmpSubQuestionSetArr.surveyQuestions = questions;
            subQuestionSetArr.push(tmpSubQuestionSetArr);
          });
          var newState = [...subQuestionSet];
          newState = newState.concat(subQuestionSetArr);
          setSubQuestionSet(newState);
        });
      } catch (error) {
        message.error(
          `Please upload the file in accordance with the specified formats.`
        );
      }
    },
  };

  return (
    <Dragger {...uploadProps}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
      <p className="ant-upload-hint">
        Support for a single upload. Installing outside the Excel file and
        outside the specified format causes problems.
      </p>
    </Dragger>
  );
}
export default ImportExcel;
