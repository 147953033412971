import axios from "axios";

const config = {
  method: "",
  url: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  data: null,
  params: null,
  withCredentials: true,
};
class ProjectExceptionService {
    async exceptionFilteredItems(body) {
        var newConfig = { ...config };
        newConfig.data = body;
        newConfig.url = newConfig.url + process.env.REACT_APP_API_PROJECT_EXCEPTION_FILTERED_ITEM;
        newConfig.method = "post";
    
        var result = await axios(newConfig);
        return result;
      }
}
export default new ProjectExceptionService();
