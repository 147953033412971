import React, { useEffect, useState } from "react";
import BaseSelect from "react-select";
import { Row, Col, Input } from "reactstrap";
import { GetProjectStgSelectList } from "../../../helpers/SelectHelpers";
import { distinctArray, handleAlertMessage } from "../../../helpers/Helpers";
import { useContext } from "react";
import { MainContext } from "../../../context/context";
import ApiClientService from "../../../services/auth/api-client-service";
import { useHistory } from "react-router-dom";
import { RoleTypeOptions } from "../../../mockupData/roleType";
import CustomCheckBox from "../../pages/Checkbox";
import FixRequiredSelect from "../../pages/FixRequiredSelect";

const MediaRuleAddEdit = (props) => {
  const history = useHistory();
  const [stgOptions, setStgOptions] = useState([]);
  const [projectstgList, setProjectStgList] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [navigateData, setNavigateData] = useState(
    props.history.location.state
  );
  const { snackbar, setSnackbar } = useContext(MainContext);
  const [projectOptions, setProjectOptions] = useState([]);
  const [mediaRuleState, setMediaRuleState] = useState({
    id: 0,
    selectedProject: "",
    selectedRole: "",
    stgList: [],
    projectId: null,
    stgId: null,
    stgIdList: "",
    roleType: [],
    imageQuantity: "",
    videoQuantity: "",
    warningText: "",
    isActive: true,
  });
  const handleChangeSelect = (key, param, e) => {
    var newState = { ...mediaRuleState };
    newState[key] = e;
    if (key != "stgList") newState[param] = e ? e.value : e;
    if (key == "selectedProject" && e != null) {
      newState.stgList = [];
      var options = projectstgList.filter(
        (x) => x.projectId.toString() == e.value
      );
      setStgOptions(options);
    }else if(key == "selectedProject") {
      setStgOptions(distinctArray(projectstgList, "label"));
    }
    setMediaRuleState(newState);
  };
  const Select = (props) => (
    <FixRequiredSelect
      {...props}
      SelectComponent={BaseSelect}
      options={props.options}
    />
  );
  useEffect(() => {
    GetProjectStgSelectList(
      setProjectOptions,
      setProjectStgList,
      setStgOptions
    );
    if (navigateData && navigateData.params.action == "update") {
      setIsUpdate(true);
      getByIdMediaRule(navigateData.params.mediaRuleId);
    }
  }, []);

  const postAction = (event) => {
    event.preventDefault();
    var data = { ...mediaRuleState };
    if (data.stgList.length > 0) {
      let stgIdList = data.stgList.map(function(element) {
        return element.value;
      });
      data.stgIdList = stgIdList.join(";");
    }
    data.roleType = data.selectedRole?.value;
    ApiClientService.post(process.env.REACT_APP_API_SAVE_MEDIA_RULE, data).then(
      (value) => {
        if (!value)
          handleAlertMessage(
            snackbar,
            setSnackbar,
            "An error occurred",
            "error"
          );
        else if (
          value.status == 200 ||
          value.status == 201 ||
          value.status == 204
        ) {
          var message = "Update process successful.";
          handleAlertMessage(snackbar, setSnackbar, message, "success");
          setTimeout(() => history.push("/media-rule-list"), 2000);
        } else {
          var message = "An error occurred";
          handleAlertMessage(snackbar, setSnackbar, message, "error");
        }
      }
    );
  };

  function getByIdMediaRule(id) {
    var headers = {
      id: id,
    };
    ApiClientService.get(
      process.env.REACT_APP_API_GET_BY_ID_MEDIA_RULE,
      headers
    ).then((value) => {
      if (!value) return;

      if (!value)
        handleAlertMessage(snackbar, setSnackbar, "An error occurred", "error");
      else if (
        value.status === 200 ||
        value.status === 201 ||
        value.status === 204
      ) {
        var data = value.data.data;
        var mapData = {
          id: data.id,
          selectedProject: data.project !== null ? { label: data.project?.name, value: data.project.id } : null,
          stgList: data.stgSelectList,
          projectId: data.project?.id,
          stgId: 0,
          stgIdList: "",
          selectedRole: RoleTypeOptions.find((x) => x.value == data.roleType),
          imageQuantity: data.imageQuantity,
          videoQuantity: data.videoQuantity,
          warningText: data.warningText,
          isActive: data.isActive,
        };
        setMediaRuleState(mapData);
      } else {
        var message = "An error occurred";
        handleAlertMessage(snackbar, setSnackbar, message, "error");
      }
    });
  }
  return (
    <div className="card p-5 mt-4 mb-4">
      <form onSubmit={postAction.bind(this)} autoComplete="off">
        <Row className="mt-3">
          <Col xs={12} sm={6} xl={4}>
            <label>Project</label>
            <Select
              value={mediaRuleState.selectedProject}
              options={projectOptions}
              isClearable={true}
              isSearchable={false}
              onChange={(e) =>
                handleChangeSelect("selectedProject", "projectId", e)
              }
            />
          </Col>

          <Col xs={12} sm={6} xl={4}>
            <label>Video Quantity</label>
            <Input
              required
              type="number"
              name="videoQuantity"
              value={mediaRuleState.videoQuantity}
              onChange={(e) =>
                setMediaRuleState({
                  ...mediaRuleState,
                  videoQuantity: e.target.value,
                })
              }
            />
          </Col>
          <Col xs={12} sm={6} xl={4}>
            <label>Image Quantity</label>
            <Input
              required
              type="number"
              name="imageQuantity"
              value={mediaRuleState.imageQuantity}
              onChange={(e) =>
                setMediaRuleState({
                  ...mediaRuleState,
                  imageQuantity: e.target.value,
                })
              }
            />
          </Col>
          <Col xs={12} sm={6} xl={4}>
            <label>Stg List</label>
            <Select
              required
              value={mediaRuleState.stgList}
              isMulti
              options={stgOptions}
              isSearchable={false}
              onChange={(e) => handleChangeSelect("stgList", "stgId", e)}
            />
          </Col>
          <Col xs={12} sm={6} xl={4}>
            <label>Role Type</label>
            <Select
              required
              value={mediaRuleState.selectedRole}
              options={RoleTypeOptions}
              isSearchable={false}
              onChange={(e) =>
                setMediaRuleState({ ...mediaRuleState, selectedRole: e })
              }
            />
          </Col>
          <Col xs={12} sm={6} xl={4}>
            <label>Warning Text</label>
            <Input
              type="text"
              required
              name="warningText"
              value={mediaRuleState.warningText}
              onChange={(e) =>
                setMediaRuleState({
                  ...mediaRuleState,
                  warningText: e.target.value,
                })
              }
            />
          </Col>
          <Col>
            {isUpdate ? (
              <CustomCheckBox
                value={mediaRuleState.isActive}
                single={false}
                name="isActive"
                data={mediaRuleState}
                checked={mediaRuleState.isActive}
                setValue={setMediaRuleState}
                label="Is Active"
                color="secondary"
              />
            ) : (
              <></>
            )}
          </Col>

          <Col
            xs={12}
            className="mt-4 d-flex justify-content-end align-items-center"
          >
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default MediaRuleAddEdit;
