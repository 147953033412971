const QuestionSectionParameterEnum = {
  Stg: {
    value: 1,
    label: "Tasks",
  },
  All: {
    value: 2,
    label: "For all activities",
  },
};

export { QuestionSectionParameterEnum };
