/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from "react";
import objectPath from "object-path";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHtmlClassService } from "../_core/MetronicLayout";
import { AsideSearch } from "./AsideSearch";
import { AsideMenu } from "./aside-menu/AsideMenu";
import { KTUtil } from "../../_metronic/_assets/js/components/util";
import { uuidv4 } from "../../../helpers/Helpers";

export function Aside() {
    const uiService = useHtmlClassService();

    const layoutProps = useMemo(() => {
        return {
            asideClassesFromConfig: uiService.getClasses("aside", true),
            asideSecondaryDisplay: objectPath.get(
                uiService.config,
                "aside.secondary.display"
            ),
            asideSelfMinimizeToggle: objectPath.get(
                uiService.config,
                "aside.self.minimize.toggle"
            ),
            extrasSearchDisplay: objectPath.get(
                uiService.config,
                "extras.search.display"
            ),
            extrasNotificationsDisplay: objectPath.get(
                uiService.config,
                "extras.notifications.display"
            ),
            extrasQuickActionsDisplay: objectPath.get(
                uiService.config,
                "extras.quick-actions.display"
            ),
            extrasQuickPanelDisplay: objectPath.get(
                uiService.config,
                "extras.quick-panel.display"
            ),
            extrasLanguagesDisplay: objectPath.get(
                uiService.config,
                "extras.languages.display"
            ),
            extrasUserDisplay: objectPath.get(
                uiService.config,
                "extras.user.display"
            ),
        };
    }, [uiService]);

    const tabs = {
        tabId1: "kt_aside_tab_1",
        tabId2: "kt_aside_tab_2",
    };
    const [activeTab, setActiveTab] = useState(tabs.tabId1);
    const handleTabChange = (id) => {
        setActiveTab(id);
        const asideWorkspace = KTUtil.find(
            document.getElementById("kt_aside"),
            ".aside-secondary .aside-workspace"
        );
        if (asideWorkspace) {
            KTUtil.scrollUpdate(asideWorkspace);
        }
    };

    return (
        <>
            {/* begin::Aside */}
            <div
                id="kt_aside"
                className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig}`}
            >

                    <div className="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">
                       {/* begin::Aside Toggle */}
                       {layoutProps.asideSecondaryDisplay &&
                          layoutProps.asideSelfMinimizeToggle && (
                               <>
                                  <OverlayTrigger
                                       placement="right"
                                        overlay={<Tooltip id="toggle-aside">Toggle Aside</Tooltip>}
                                   >
                                       <span
                                           className="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
                                          id="kt_aside_toggle"  >
                                           <i className="ki ki-bold-arrow-back icon-sm" />
                                       </span>
                                   </OverlayTrigger>
                               </>
                        )}
                    </div>
         
                {layoutProps.asideSecondaryDisplay && (
                    <>
                        {/* begin::Secondary */}
                        <div className="aside-secondary d-flex flex-row-fluid" key={uuidv4()}>
                            {/* begin::Workspace */}
                            <div className="aside-workspace scroll scroll-push my-2">
                                <div className="tab-content pl-5">
                                    <AsideSearch isActive={activeTab === tabs.tabId1} key={uuidv4()} />
                                    <AsideMenu isActive={activeTab === tabs.tabId1} key={uuidv4()} />
                                </div>
                            </div>
                            {/* end::Workspace */}
                        </div>
                        {/* end::Secondary */}
                    </>
                )}
            </div>
            {/* end::Aside */}
        </>
    );
}
