import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Link } from "react-router-dom";
import ApiClientService from "../../../services/auth/api-client-service";
import { FaUndo } from "react-icons/fa";
import UserSnackbars from "../../../components/pages/Snackbar";
import { uuidv4 } from "../../../helpers/Helpers";

export function AsideSearch({ isActive }) {
  const [user, setUser] = useState();
  const [snackbar, setSnackbar] = useState(false);
  const timeout = 2500;
 

  var path = window.location.pathname;
  //var active = menu.find((x) => x.url == path);
  var profilePicUrl = toAbsoluteUrl("/media/users/user_image.png");

  //if (!active) active = { id: 0, url: "" };

  if (!user) {
    ApiClientService.get(process.env.REACT_APP_API_GET_USER, null).then(
      (value) => {
        if (value) {
          setUser(value.data.data);
        }
      }
    );
  }

  const UpdateData = (e) => {
    e.preventDefault();
    ApiClientService.post(process.env.REACT_APP_API_PROJECT_TRIGGER, null).then(
      (value) => {
        if (value.data.isSuccessful) {
          setSnackbar(true);
        }
      }
    );
  };

  return (
    <>
      <UserSnackbars
        open={snackbar}
        variant="success"
        message="Data Updated Successfully"
        timeout={timeout}
        handleClose={() => setSnackbar(false)}
      ></UserSnackbars>
      <div
        key={uuidv4()}
        className={`tab-pane p-3 px-lg-7 py-lg-5 fade ${isActive &&
          "show active"}`}
      >
        <div className="d-flex align-items-center mt-5">
          <div className="symbol symbol-100 mr-5">
            <div
              className="symbol-label"
              style={{
                backgroundImage: `url(${profilePicUrl})`,
              }}
            ></div>
            <i className="symbol-badge bg-success"></i>
          </div>
          <div className="d-flex flex-column">
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {user != null ? user.firstName + " " + user.lastName : "" }
            </a>
            <div className="text-muted mt-1">{user?.description}</div>
            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Mail.svg"
                        )}
                      />
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {user?.email}
                  </span>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="separator separator-dashed mt-8 mb-5"></div>
        <div className="row">
          <div className="col-6 my-1 my-lg-3 mb-10">
            <h3 className="p-2">Menu</h3>
          </div>
          <div className="col-6 pt-2 my-1 my-lg-3 mb-10 d-flex justify-content-end">
            <button
              style={{ background: "transparent", border: "none" }}
              className="navi-item"
              onClick={(e) => UpdateData(e)}
            >
              <span className="navi-text text-muted text-hover-primary refresh-text">
                <FaUndo /> Refresh
              </span>
            </button>
          </div>
        </div>
        {/* begin::Item */}
        {/*  */}
      </div>
    </>
  );
}
