import ApiClientService from "../services/auth/api-client-service";

export default function workSiteList(idList, header, setHeader) {
  ApiClientService.post(process.env.REACT_APP_API_WORK_SITE_LIST, idList).then(
    (response) => {
      if (response && response.data?.data?.length > 0) {
        var first = [...response.data?.data].shift();
        var workSiteList = response.data?.data?.map((x) => x.workSite) ?? [];
        var tempHeader = { ...header };
        tempHeader.workSite = workSiteList.join(",");
        var dcYear = new Date()
          .getFullYear()
          .toString()
          .replace("0", "");
        tempHeader.bpCode = first.bpCode;
        tempHeader.documentNo =
          "GFC" + dcYear + "-" + tempHeader.bpCode + "-" + first.absEntry;
        tempHeader.poNum = first.poNum;
        tempHeader.adress = first.adress;
        tempHeader.bpName = first.bpName;
        setHeader(tempHeader);
      }
    }
  );
}
