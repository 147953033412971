import React, { useState } from "react";
import { useFormik } from "formik";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import Card from "@material-ui/core/Card";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../shared/_metronic/_helpers";
import ApiClientService from "../../services/auth/api-client-service";
import { message } from "antd";

const initialValues = {
  email: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const [isSended, setIsSended] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };
  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setSubmitting }) => {
      var params = {
        email: values.email,
      };
      setMessage("");
      enableLoading();
      ApiClientService.get(
        process.env.REACT_APP_API_FORGOT_PASSWORD_MAIL,
        params
      )
        .then((value) => {
          if (value && value.data == "OK") {
            setLoading(false);
            setIsSended(true);
          } else {
            setIsSended(false);
            setMessage(value.data.errors[0]);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsSended(false);
          setMessage("");
        })
        .finally(() => {
          disableLoading();
          setSubmitting(false);
        });
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <>
          {!isSended ? (
            <div
              className="login-form login-forgot"
              style={{ display: "block" }}
            >
              <div className="text-center mb-10 mb-lg-20">
                <h3 className="font-size-h1">Forgotten Password ?</h3>
                <div className="text-muted font-weight-bold">
                  Enter your email to reset your password
                </div>
              </div>
              <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
              >
                {message != "" ? (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">{message}</div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="form-group fv-plugins-icon-container">
                  <input
                    type="email"
                    className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                      "email"
                    )}`}
                    name="email"
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.email}</div>
                    </div>
                  ) : null}
                </div>
                <div className="form-group d-flex flex-wrap flex-center">
                  <button
                    id="kt_login_forgot_submit"
                    type="submit"
                    className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    disabled={formik.isSubmitting}
                  >
                    Submit
                  </button>
                  <Link to="/auth">
                    <button
                      type="button"
                      id="kt_login_forgot_cancel"
                      className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                    >
                      Cancel
                    </button>
                  </Link>
                </div>
              </form>
            </div>
          ) : (
            <div style={{ display: "block", maxWidth: "80rem" }}>
              <Card
                style={{
                  padding: 50,
                  display: "flex",
                  justifyContent: "center",
                  maxWidth: "80rem",
                }}
              >
                <div>
                  <div
                    className="text-center mb-10"
                    style={{
                      borderBottom: "1px solid #b3b3b3",
                    }}
                  >
                    <h3 className="font-size-h1">PASSWORD RESET</h3>
                  </div>
                  <div
                    className="form-group fv-plugins-icon-container"
                    style={{
                      fontSize: 15,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <SVG
                      width={50}
                      height={50}
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Mail-opened.svg"
                      )}
                    ></SVG>
                    <p className="font-weight-bold">
                      Information about the password has been sent to your
                      address: {initialValues.email}
                    </p>
                    <p className="font-weight-bold">
                      Check your email and click on the link to proceed!
                    </p>
                  </div>
                  <div className="form-group d-flex flex-wrap justify-content-between align-items-center"></div>
                </div>
              </Card>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default injectIntl(ForgotPassword);
