import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ApiClientService from "../../../services/auth/api-client-service";
import { Table } from "antd";
import "antd/dist/antd.css";
import { FaRocket, FaBuffer, FaFilePdf } from "react-icons/fa";
import UserDialog from "../../pages/Dialog";
import SimpleTooltips from "../../pages/Tooltip";
import UserSnackbars from "../../pages/Snackbar";
import { Checkbox } from "@material-ui/core";
import moment from "moment";
import { groupBy } from "../../../utils/helper";
import "../../pages/table.css";
import GeoModal from "../../pages/CustomerTransferWaitingActivities/GeoModal";
import { tableFilter } from "../../../helpers/Helpers";

function CustomerTransferWaitingActivities(props) {
  const defaultFilters = [
    {
      Info: [],
      ProjectName: [],
      WorkingLocation: [],
      Task: [],
      IssueID: [],
      StartDate: [],
      EndDate: [],
      GroupedDate: [],
      Approve: [],
      Actions: [],
      File: [],
      Mail: [],
    },
  ];
  const timeout = 5000;
  const [openPopup, setOpenPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState(
    "Do you want to send for customer approval"
  );
  const [popupBody, setPopupBody] = useState(
    "Are you sure you want to submit the selected issues for customer approval?"
  );
  const [openUnGroupPopup, setOpenUnGroupPopup] = useState(false);
  const [popupUnGroupTitle, setPopupUnGroupTitle] = useState(
    "Do you approve of breaking the package?"
  );
  const [popupUnGroupBody, setPopupUnGroupBody] = useState(
    "Are you sure you want to unpack the grouped issues?"
  );
  const [approveIssueGuidList, setApproveIssueGuidList] = useState([]);
  const [guid, setGuid] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [variantSnackbar, setVariantSnackbar] = useState("");
  const [messageSnackbar, setMessageSnackbar] = useState("");
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState(defaultFilters);
  const [isGroupedIssueExist, setGroupedIssueLlist] = useState(false);

  const handleCheckbox = (e) => {
    var array = [];
    try {
      if (e.target.checked) {
        setApproveIssueGuidList((prevList) => [...prevList, e.target.value]);
      } else {
        setApproveIssueGuidList((prevList) => [
          ...prevList.filter((item) => item !== e.target.value),
        ]);
      }
    } catch (error) {}
  };

  const closeSnackbar = () => {
    setOpenSnackbar(false);
  };
  const openDialog = (id) => {
    setOpenPopup(true);
  };
  const openUnGroupDialog = (guid) => {
    setGuid(guid);
    setOpenUnGroupPopup(true);
  };
  const handleAlert = (message, type) => {
    setMessageSnackbar(message);
    setVariantSnackbar(type);
    setOpenSnackbar(true);
    setOpenPopup(false);
  };

  function approveAction(button) {
    if (
      approveIssueGuidList === null ||
      approveIssueGuidList === undefined ||
      approveIssueGuidList.length <= 0
    )
      return handleAlert("Please select at least one.", "error");

    setLoading(true);
    ApiClientService.postSingle(
      process.env.REACT_APP_API_SEND_TO_CUSTOMER_APPROVE_ISSUE,
      approveIssueGuidList
    )
      .then((value) => {
        if (value.status === 200 || value.status === 201 || value.status === 204) {
          handleAlert("Send to customer approval successful.", "success");

          setFilters(defaultFilters);
          getData();
        } else handleAlert("An error occurred.", "error");
      })
      .finally(() => {
        setLoading(false);
      });
    setOpenPopup(false);
  }
  function approveUnGroupAction() {
    if (guid === null || guid === undefined || guid === "") {
      handleAlert("No packages found to unpacking.", "error");
      setOpenUnGroupPopup(false);
      return;
    }

    ApiClientService.postSingle(
      process.env.REACT_APP_API_UNGROUP_WAITING_CUSTOMER_APPROVE_ISSUE,
      guid
    ).then((value) => {
      if (value.status === 200 || value.status === 201 || value.status === 204) {
        handleAlert("Unpackage operation successful.", "success");
        getData();
        setGuid("");
      } else handleAlert("An error occurred.", "error");
    });
    setOpenUnGroupPopup(false);
  }
  function rejectAction() {
    setOpenPopup(false);
  }
  function rejectUnGroupAction() {
    setGuid("");
    setOpenUnGroupPopup(false);
  }
  function getData() {
    setRows([]);
    var arrayRows = [];
    setLoading(true);
    ApiClientService.get(
      process.env.REACT_APP_API_GET_CUSTOMER_APPROVE_ISSUES,
      null
    )
      .then((value) => {
        var issueList = value.data.data;
        if (issueList != null && issueList.length > 0) {
          var groupedIssueList = groupBy(
            issueList,
            (issue) => issue.approveGroupGuid
          );

          groupedIssueList.forEach(function(issue) {
            arrayRows.push(createModel(issue, "issue"));
          });

          if (groupedIssueList.size > 0) {
            setGroupedIssueLlist(true);
          } else {
            setGroupedIssueLlist(false);
          }

          arrayRows.sort((a, b) =>
            a.employeStartTime < b.employeStartTime ? -1 : 1
          );
          
          arrayRows.forEach((element) => {
            element.WorkingLocation = element.WorkingLocation.filter(onlyUnique)?.join('-');
            element.Task = element.Task.filter(onlyUnique)?.join('-')
          });

          setRows(arrayRows);

          if (arrayRows.length > 0) createFilterData(arrayRows);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function setTableFooter() {
    if (isGroupedIssueExist) {
      return createMainActionButtons();
    }
  }

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const createFilterData = (rows) => {
    if (rows.length <= 0) return;
    let array = filters[0];
    const object = filters[0];

    Object.keys(object || {}).forEach((key) => {
      rows.forEach((element) => {
        if (
          element[key] &&
          !object[key].some((x) => x.value === element[key])
        ) {
          array[key].push({
            text: element[key],
            label: element[key],
            value: element[key],
          });
        }
      });
    });
    setFilters(array);
  };

  const createHeaders = () => {
    var headers = [];
    var object = filters[0];
    const filterKeys = [
      "ProjectName",
      "WorkingLocation",
      "Task",
      "IssueID",
      "StartDate",
      "EndDate",
      "GroupedDate",
    ];

    Object.keys(object).forEach((key) => {
      if (filterKeys.includes(key)) {
        headers.push({
          title: key === "Segment" ? "Working Location" : key,
          dataIndex: key,
          filters: object[key],
          filterMode: "tree",
          filterSearch: false,
          onFilter: (value, record) => tableFilter(record[key], value),
          sorter: (a, b) =>
            a[key]?.toString().localeCompare(b[key]?.toString()),
        });
      } else {
        headers.push({
          title: key,
          dataIndex: key,
        });
      }
    });
    setColumns(headers);
  };

  function createMainActionButtons() {
    return (
      <div className="main-action">
        <div className="d-flex justify-content-end">
          <button onClick={() => openDialog()} className="btn btn-info">
            <FaRocket /> Send
          </button>
        </div>
      </div>
    );
  }
  function createUnGroupActionButtons(guid) {
    return (
      <div className="main-action">
        <div className="d-flex justify-content-center">
          <button
            onClick={() => openUnGroupDialog(guid)}
            className="btn btn-info btn-sm"
            style={{ whiteSpace: "nowrap" }}
          >
            <FaBuffer /> Unpack
          </button>
        </div>
      </div>
    );
  }
  function createSendToMailButtons(issue) {
    return (
      <div className="main-action">
        <div className="d-flex justify-content-center">
          <GeoModal issue={issue} />
        </div>
      </div>
    );
  }

  function createCheckbox(guid) {
    return (
      <div className="checkboxes">
        <div className="d-flex justify-content-center align-items-center">
          <Checkbox
            defaultChecked={false}
            onChange={(e) => handleCheckbox(e)}
            value={guid}
            inputProps={{
              "aria-label": "primary checkbox",
            }}
          />
        </div>
      </div>
    );
  }
  function pdfButton(issue) {
    return (
      <div className="main-action">
        <div className="d-flex justify-content-center">
          <Link
            to={{
              pathname: `/preview`,
              state: {
                params: {
                  issue: issue,
                },
              },
            }}
            className="btn btn-warning"
            style={{ whiteSpace: "nowrap" }}
          >
            <FaFilePdf /> Pdf Show
          </Link>
        </div>
      </div>
    );
  }
  function logInfo(issue) {
    return (
      <div className="main-action">
        <div className="d-flex justify-content-center">
          <SimpleTooltips
            key={issue.id}
            color="info"
            title="Attention! There is an accident record inside."
          />
        </div>
      </div>
    );
  }
  function timezoneFormat(time) {
    var date = new Date(time);
    var timezone = -date.getTimezoneOffset() / 60;
    return timezone;
  }

  function createModel(issue, type) {
    let source;
    switch (type) {
      case "issue":
        source = {
          Info: issue[0].isIncident === true ? logInfo(issue[0]) : "",
          ProjectName: issue[0].projectName,
          WorkingLocation: issue.map(({ workingLocation }) => workingLocation),
          Task: issue.map(({ taskDesc }) => taskDesc),
          IssueID: issue.map(({ id }) => id).join("-"),
          StartDate: moment(
            issue.sort((a, b) => (a.startDate < b.startDate ? -1 : 1))[0]
              .startDate
          ).format("DD/MM/YYYY"),
          EndDate: moment(
            issue.sort((a, b) => (a.endDate > b.endDate ? -1 : 1))[0].endDate
          ).format("DD/MM/YYYY"),
          GroupedDate: moment(issue[0].approveGroupDate)
            .add(timezoneFormat(issue[0].approveGroupDate), "h")
            .format("DD/MM/YYYY HH:mm"),
          Approve: createCheckbox(issue[0].approveGroupGuid),
          Actions: createUnGroupActionButtons(issue[0].approveGroupGuid),
          File: pdfButton(issue),
          Mail: createSendToMailButtons(issue),
        };
        break;
      case "mainAction":
        source = createMainActionButtons();

        break;

      default:
        break;
    }
    return source;
  }

  useEffect(() => {
    createHeaders();
    getData();
  }, []);

  return (
    <div className="approved-activities row">
      <UserDialog
        open={openPopup}
        title={popupTitle}
        body={popupBody}
        approveAction={approveAction}
        rejectAction={rejectAction}
      ></UserDialog>
      <UserDialog
        open={openUnGroupPopup}
        title={popupUnGroupTitle}
        body={popupUnGroupBody}
        approveAction={approveUnGroupAction}
        rejectAction={rejectUnGroupAction}
      ></UserDialog>
      <UserSnackbars
        open={openSnackbar}
        variant={variantSnackbar}
        message={messageSnackbar}
        timeout={timeout}
        handleClose={closeSnackbar}
      ></UserSnackbars>
      <Table
        columns={columns}
        dataSource={rows}
        scroll={{ x: 1700 }}
        loading={loading}
        footer={setTableFooter}
      />
    </div>
  );
}

export default CustomerTransferWaitingActivities;
