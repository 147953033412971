import axios from "axios";

const config = {
  method: "",
  url: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
  data: null,
  params: null,
  withCredentials: true,
};

class SegmentService {
  async workingLocationGetAll(stageId) {
    var newConfig = { ...config };
    newConfig.url =
      newConfig.url + process.env.REACT_APP_API_SEGMENT_WORKING_LOCATION;
    newConfig.method = "get";
    newConfig.params = { stageId: stageId };
    var result = await axios(newConfig);
    return result;
  }
  async subWorkingLocationGetAll(workingLocationId) {
    var newConfig = { ...config };
    newConfig.url =
      newConfig.url + process.env.REACT_APP_API_SEGMENT_SUBWORKING_LOCATION;
    newConfig.method = "get";
    newConfig.params = { workingLocationId: workingLocationId };
    var result = await axios(newConfig);
    return result;
  }
  async problemLocationGetAll(subWorkingLocationId) {
    var newConfig = { ...config };
    newConfig.url =
      newConfig.url + process.env.REACT_APP_API_SEGMENT_PROBLEM_LOCATION;
    newConfig.method = "get";
    newConfig.params = { subWorkingLocationId: subWorkingLocationId };
    var result = await axios(newConfig);
    return result;
  }
}
export default new SegmentService();
