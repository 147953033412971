import React from "react";
import { List, Switch } from "antd";
import { uuidv4 } from "../../../../helpers/Helpers";

export default function StageRuleList(props) {
  const { data, setStageRules, loading } = props;

  const handleChange = (e, key) => {
    var newState = [...data];
    newState.forEach((element) => {
      if (element.label == key) element.visible = e;
    });
    setStageRules(newState);
  };

  const switchButton = (item) => {
    return (
      <Switch
        size="default"
        checkedChildren="Visible"
        unCheckedChildren="Hidden"
        defaultChecked
        checked={item.visible}
        onChange={(e) => handleChange(e, item.label)}
      />
    );
  };

  return (
    <>
      <List
        size="small"
        loading={loading}
        rowKey={uuidv4()}
        bordered
        dataSource={data}
        renderItem={(item) => (
          <List.Item actions={[switchButton(item)]}>{item.label}</List.Item>
        )}
      />
    </>
  );
}
