import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ApiClientService from "../../../services/auth/api-client-service";
import DataTable from "../../pages/DataTable";
import { FaCheck, FaTimesCircle } from "react-icons/fa";
import UserDialog from "../../pages/Dialog";
import UserSnackbars from "../../pages/Snackbar";
import { Checkbox } from "@material-ui/core";
import CustomCheckBox from "../../pages/Checkbox";
import moment from "moment";
import { DateDiff, groupBy, isDistinctArray } from "../../../helpers/Helpers";
import "../../pages/style.css";
import { Modal } from "antd";
import { Row, Col, Input } from "reactstrap";
import GeoTable from "../../pages/CustomerTransferWaitingActivities/Table";
import {
  maxDateArray,
  minDateArray,
  TurkishOnlyDateFormat,
} from "../../../helpers/DateHelpers";

function ApprovedActivities(props) {
  const history = useHistory();
  const timeout = 5000;
  const [openPopup, setOpenPopup] = useState(false);
  const [incidentPopup, setIncidentPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState(
    "Do You Confirm The Transaction?"
  );
  const [popupBody, setPopupBody] = useState(
    "Are you sure you want to group the selected issues to ready for send to customer approval?"
  );
  const [approveIssueIdList, setApproveIssueIdList] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [variantSnackbar, setVariantSnackbar] = useState("");
  const [messageSnackbar, setMessageSnackbar] = useState("");
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setrowsPerPage] = useState(5);
  const [dataTableFooter, setDataTableFooter] = useState();
  const [group, setGroup] = useState(true);
  const [currentIssueId, setCurrentIssueId] = useState();
  const [isIncident, setIsIncident] = useState(false);
  const [contactInfo, setContactInfo] = useState({
    name: "",
  });
  const [visible, setVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableProp, setTableProp] = useState({ header: [], rows: [] });
  const [issue, setIssue] = useState([]);
  const [isManuel, setIsManuel] = useState(false);
  const [stageEmployees, setStageEmployes] = useState([]);
  const handleCheckbox = (e) => {
    try {
      if (e.target.checked) {
        setApproveIssueIdList((prevList) => [...prevList, e.target.value]);
      } else {
        setApproveIssueIdList((prevList) => [
          ...prevList.filter((item) => item !== e.target.value),
        ]);
      }
    } catch (error) {}
  };

  const closeSnackbar = () => {
    setOpenSnackbar(false);
  };
  const setTotalCount = (count) => {
    setCount(count);
  };

  const changePage = (page) => {
    setPage(page);
  };
  const setrowsPerPageNumber = (page) => {
    setrowsPerPage(page);
  };
  const openDialog = (id) => {
    setOpenPopup(true);
  };
  const handleAlert = (message, type) => {
    setMessageSnackbar(message);
    setVariantSnackbar(type);
    setOpenSnackbar(true);
    setOpenPopup(false);
  };

  function approveAction(button) {
    if (!group) {
      disApproved();
      return;
    }
    if (
      approveIssueIdList === null ||
      approveIssueIdList === undefined ||
      approveIssueIdList.length <= 0
    ) {
      return handleAlert("Please select at least one.", "error");
    }
    setOpenPopup(false);
    var temp = [];
    rows.forEach(function(item) {
      if (approveIssueIdList.includes(item.IssueID.toString())) {
        temp.push(item);
      }
    });
    var grouppedUser = groupBy(temp, "UserId");
    var nightShiftArray = [];
    Object.keys(grouppedUser).forEach((key) => {
      var dates = grouppedUser[key]?.map((x) => TurkishOnlyDateFormat(x.Date));
      var minDate = minDateArray(dates, "MM/DD/YYYY");
      var maxDate = maxDateArray(dates, "MM/DD/YYYY");
      var dateDiff = DateDiff(new Date(minDate), new Date(maxDate));
      if (dateDiff == 1) {
        grouppedUser[key].forEach(function(element) {
          var test = stageEmployees.find(
            (x) => x.userId == key && x.stage.name == element.StageName
          );

          var sEmployeStartTime = moment(test.workStartTime).format("LTS");
          var sEmployeEndTime = moment(test.workEndTime).format("LTS");
          nightShiftArray.push(
            sEmployeStartTime > sEmployeEndTime ? true : false
          );
        });
      } else if (dateDiff > 1) {
        return handleAlert("Different dates cannot be grouped.", "error");
      }
    });

    if (
      nightShiftArray.length > 0 &&
      nightShiftArray.filter((x) => x == false)?.length > 0
    )
      return handleAlert("Different dates cannot be grouped.", "error");
    else {
      if (isDistinctArray(temp, "ProjectName"))
        return handleAlert("Different projects cannot be grouped.", "error");
    }

    if (isIncident) setIncidentPopup(true);
    else openModal();
  }
  function postAction() {
    var name = "";
    if (selectedRows.length > 0 && contactInfo.name.trim() !== "") {
      return handleAlert("Please only one contact", "info");
    } else if (selectedRows.length > 1) {
      return handleAlert("Please only one contact", "info");
    } else if (selectedRows.length > 0) {
      name = selectedRows[0].name;
    } else if (contactInfo.name.trim() !== "") {
      name = contactInfo.name;
    }
    if (name.trim() === "") {
      return handleAlert("Please add user", "info");
    }
    var params = {
      issueIdList: approveIssueIdList,
      isIncident: isIncident,
      contactName: name,
    };
    ApiClientService.post(
      process.env.REACT_APP_API_GROUP_WAITING_CUSTOMER_APPROVE_ISSUE,
      params
    ).then((value) => {
      if (value.status === 200 || value.status === 201 || value.status === 204) {
        handleAlert("Issue packing successful.", "success");
        setIsIncident(false);
        setIncidentPopup(false);
        getData();
        setVisible(false);
      } else {
        handleAlert("An error occurred.", "error");
      }
    });
  }
  function disApproved() {
    ApiClientService.postSingle(
      process.env.REACT_APP_API_DISAPPROVE_ISSUE,
      currentIssueId
    ).then((value) => {
      if (
        value.status === 200 ||
        value.status === 201 ||
        value.status === 204
      ) {
        handleAlert("Issue disapproved successful.", "success");
        getData();
      } else {
        handleAlert("An error occurred.", "error");
      }
    });
  }
  function rejectAction() {
    setOpenPopup(false);
    setIncidentPopup(false);
  }
  function getData() {
    setRows([]);
    setCount(0);
    setPage(0);
    var arrayRows = [];
    var tempIssue = [];
    var tempStageEmployes = [];
    var promises = [];
    ApiClientService.get(
      process.env.REACT_APP_API_GET_APPROVED_ACTIVITIES,
      null
    ).then((value) => {
      var issueList = value.data.data;
      if (issueList != null) {
        issueList.forEach(function(issue) {
          let employeeName = "";
          let stageEmployeParam = {
            userId: issue.userId,
            stageName: issue.stageName,
          };
          let params = {
            userId: issue.userId,
          };
          promises.push(
            ApiClientService.get(
              process.env.REACT_APP_API_GET_USER_BY_ID,
              params
            ).then((value) => {
              var employee = value.data.data;

              if (employee != null) {
                employeeName =
                  employee.firstName !== null
                    ? employee.lastName !== null
                      ? employee.firstName + " " + employee.lastName
                      : employee.userName !== null
                      ? employee.userName
                      : ""
                    : "";
              }
              issue.employeeName = employeeName;
              tempIssue.push(issue);
              arrayRows.push(createModel(issue, "issue"));
            })
          );
          promises.push(
            ApiClientService.get(
              process.env.REACT_APP_API_GET_EMPLOYEE,
              stageEmployeParam
            ).then((value) => {
              var stageEmployeeRespone = value.data.data;
              if (stageEmployeeRespone != null) {
                tempStageEmployes.push(stageEmployeeRespone);
              }
            })
          );
        });

        Promise.all(promises).then(() => {
          if (issueList.length > 0) {
            setDataTableFooter(createModel(null, "mainAction"));
          } else {
            setDataTableFooter();
          }
          setCount(arrayRows.length);
          setIssue(tempIssue);
          setStageEmployes(tempStageEmployes);
          if (arrayRows.length > 0) setIsManuel(false);
          else setIsManuel(true);

          arrayRows.forEach((row) => {
            setRows((rows) =>
              [...rows, row].sort((a, b) =>
                a.employeStartTime < b.employeStartTime ? -1 : 1
              )
            );
          });
        });
      }
    });
  }
  function groupBtn(isFlag, id) {
    setGroup(isFlag);
    if (!isFlag) {
      setPopupBody(
        "Are you sure you want to send the approved activity back to the approval process?"
      );
      setCurrentIssueId(id);
    } else {
      setPopupBody(
        "Are you sure you want to group the selected issues to ready for send to customer approval?"
      );
    }
    openDialog();
  }
  function createMainActionButtons() {
    return (
      <div className="main-action">
        <div className="d-flex justify-content-end">
          <CustomCheckBox
            value={isIncident}
            single={true}
            name="isIncident"
            setValue={setIsIncident}
            label="Is Incident?"
            color="secondary"
          />
          <button onClick={() => groupBtn(true, null)} className="btn btn-info">
            <FaCheck /> Group
          </button>
        </div>
      </div>
    );
  }

  function createCheckbox(issueId) {
    return (
      <div className="checkboxes">
        <div className="d-flex justify-content-center align-items-center">
          <Checkbox
            defaultChecked={false}
            onChange={(e) => handleCheckbox(e)}
            value={issueId}
            inputProps={{
              "aria-label": "primary checkbox",
            }}
          />
        </div>
      </div>
    );
  }

  function createDisapprovedButton(issueId) {
    return (
      <div className="checkboxes">
        <div className="d-flex justify-content-center align-items-center">
          <button
            className="btn btn-danger"
            onClick={() => groupBtn(false, issueId)}
          >
            <FaTimesCircle />
          </button>
        </div>
      </div>
    );
  }

  function createModel(issue, type) {
    let source;
    switch (type) {
      case "issue":
        source = {
          ProjectName: issue.projectName,
          MainWork: issue.stageName,
          DocNum: issue.docNum,
          RouteStage: issue.stgDesc,
          Employee: issue.employeeName,
          "Working Location": issue.workingLocation,
          Task: issue.taskDesc,
          IssueID: issue.id,
          Date: moment(issue.startDate).format("DD/MM/YYYY"),
          StartHour: moment(issue.startDate).format("HH:mm"),
          EndHour: moment(issue.endDate).format("HH:mm"),
          Actions: createCheckbox(issue.id),
          DisApproved: createDisapprovedButton(issue.id),
          IsNightShift: issue.isNightShift,
          UserId: issue.userId,
        };
        break;
      case "mainAction":
        source = createMainActionButtons();

        break;

      default:
        break;
    }
    return source;
  }

  async function getProjectContactData() {
    var projectEntry = issue.find(
      (x) => approveIssueIdList[0] == x.id.toString()
    )?.projectEntry;

    var param = { projectEntry: projectEntry };
    await ApiClientService.get(
      process.env.REACT_APP_API_GET_BY_PROJECT_CONTACT_PROJECT_ENTRY,
      param
    ).then((response) => {
      if (!response.data) return handleAlert("Data empty", "warning");
      var data = response.data?.data
        ?.filter((x) => x.name && x.name.trim() != "")
        .map((x, index) => ({
          name: x.name,
          key: index,
        }));

      var createHeader = [];
      var defaultHeader = {
        name: "",
      };
      Object.keys(defaultHeader).forEach((key) => {
        createHeader.push({
          title: key,
          dataIndex: key,
        });
      });
      var tempData = { ...data };
      tempData.header = createHeader;
      tempData.rows = data;
      setTableProp(tempData);
    });
  }
  function openModal() {
    getProjectContactData();
    setVisible(true);
    setSelectedRows([]);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="approved-activities row">
      <UserDialog
        open={openPopup}
        title={popupTitle}
        body={popupBody}
        approveAction={approveAction}
        rejectAction={rejectAction}
      ></UserDialog>

      <UserDialog
        open={incidentPopup}
        title={"Incident"}
        body={"Do you confirm that there are incidents in these logs?"}
        approveAction={postAction}
        rejectAction={rejectAction}
      ></UserDialog>

      <UserSnackbars
        open={openSnackbar}
        variant={variantSnackbar}
        message={messageSnackbar}
        timeout={timeout}
        handleClose={closeSnackbar}
      ></UserSnackbars>
      <DataTable
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={changePage}
        setRowsPerPage={setrowsPerPageNumber}
        rows={rows}
        footerAction={dataTableFooter}
      ></DataTable>
      <Modal
        title="Project Contact Add"
        centered
        visible={visible}
        onOk={() => postAction()}
        onCancel={() => setVisible(false)}
        okText="Group"
        width={1000}
      >
        <Row>
          <Col sm={12} md={6}>
            <label>Name Surname</label>
            <Input
              type="text"
              required
              name="nameSurname"
              disabled={selectedRows.length > 0 ? "disabled" : ""}
              value={contactInfo.name}
              onChange={(e) =>
                setContactInfo({
                  ...contactInfo,
                  name: e.target.value,
                })
              }
            />
          </Col>
          <Col
            sm={12}
            md={6}
            className="mt-4 d-flex justify-content-end align-items-end"
          ></Col>
        </Row>
        <GeoTable
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          tableProp={tableProp}
          type="radio"
        />
      </Modal>
    </div>
  );
}

export default ApprovedActivities;
