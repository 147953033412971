import React, { Fragment } from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles } from "../style/style";

const Equipment = (props) => {
  const { equipments } = props;
  var items = [];
  var size = equipments.length > 18 ? equipments.length : 18;
  var column = parseInt(size / 6) + (size % 6 > 0 ? 1 : 0);
  if (equipments) {
    for (let i = 0; i < column; i++) {
      var tempData = [];
      for (let j = i * 6; j < 6 * (i + 1); j++) {
        tempData.push(equipments[j]);
      }
      items.push(
        <View
          style={[
            styles.containerRow,
            {
              marginHorizontal: "10%",
              minHeight: "15px",
              maxHeight: "15px",
              border: 1,
              borderColor: "#000",
            },
          ]}
        >
          {tempData?.map((item, index) => (
            <>
              <View
                style={[
                  styles.materialTableContainer,
                  {
                    backgroundColor: "#fff",
                    flexDirection: "column",
                    border: 0,
                  },
                ]}
              >
                <View style={[styles.materialTableRow, { flex: 1 }]}>
                  <Text style={styles.tableText}>{item}</Text>
                </View>
              </View>
              {index < 5 ? (
                <View
                  style={{ borderRight: 1, borderRightColor: "#000" }}
                ></View>
              ) : (
                <></>
              )}
            </>
          ))}
        </View>
      );
    }
  }
  return (
    <Fragment>
      <Fragment>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            marginHorizontal: "10%",
            marginTop: "2px",
            marginBottom: "-2px",
            minHeight: "20px",
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <Text
              style={{
                fontSize: 9,
                fontFamily: "Open Sans",
                fontWeight: 700,
                marginRight: 2,
                fontStyle: "italic",
              }}
            >
              EQUIPMENT USED THAT DAY{" "}
            </Text>
            <Text
              style={{
                fontSize: 9,
                fontFamily: "Open Sans",
                fontWeight: 600,
                fontStyle: "italic",
              }}
            >
              (List all Equipment available at the Site that day with their
              Geoform #)
            </Text>
          </View>
        </View>
      </Fragment>
      <Fragment>{items}</Fragment>
    </Fragment>
  );
};

export default Equipment;
