import Cookies from 'js-cookie';

class TokenStorage {
    saveSignInStatus(status) {
        Cookies.set(process.env.REACT_APP_UI_COOKIE, status, { expires: 60 });
    }
    signOut() {
        Cookies.remove(process.env.REACT_APP_UI_COOKIE);
    }

    getSignInStatus() {
        let status = false;
        let cookie = Cookies.get(process.env.REACT_APP_UI_COOKIE);
        if (cookie !== null && cookie !== undefined)
            status = cookie;

        return status;
        
    }

}



export default new TokenStorage();