import React, { useContext, useEffect, useState } from "react";
import { Table } from "antd";
import ApiClientService from "../../../services/auth/api-client-service";
import { handleAlertMessage, uuidv4 } from "../../../helpers/Helpers";
import { MainContext } from "../../../context/context";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../shared/_metronic/_helpers";
import SVG from "react-inlinesvg";
import { RoleTypes } from "../../../enums/roleTypeEnum";
import { tableFilter } from "../../../helpers/Helpers";
import { Tag } from "antd";

var _Pathname = "/media-rule-add-edit";
function MediaRuleList() {
  const defaultHeaders = [
    {
      project: [],
      stgCodes: [],
      imageQuantity: [],
      videoQuantity: [],
      role: [],
      warningText: [],
      status: [],
      actions: [],
    },
  ];
  const colorList = ["green", "geekblue", "volcano"];
  const { snackbar, setSnackbar } = useContext(MainContext);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(defaultHeaders);

  function createDetailButton(id, pathname) {
    return (
      <Link
        to={{
          pathname: _Pathname,
          key: uuidv4(),
          state: {
            params: {
              action: "update",
              mediaRuleId: id,
            },
          },
        }}
        className="btn btn-icon btn-light btn-sm"
      >
        <span className="svg-icon svg-icon-md svg-icon-success">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")}
          ></SVG>
        </span>
      </Link>
    );
  }

  function createHeaders() {
    let headers = [];
    const object = defaultHeaders[0];
    const mediaRuleListFilterKeys = [
      "project",
      "imageQuantity",
      "videoQuantity",
      "role",
      "warningText",
      "status",
    ];
    Object.keys(object || {}).forEach((key) => {
      if (mediaRuleListFilterKeys.includes(key)) {
        headers.push({
          title: key,
          dataIndex: key,
          filters: object[key],
          filterMode: "tree",
          filterSearch: false,
          onFilter: (value, record) => tableFilter(record[key], value),
          sorter: (a, b) =>
            a[key]?.toString().localeCompare(b[key]?.toString()),
        });
      } else if (key === "stgCodes") {
        headers.push({
          title: key,
          dataIndex: key,
          render: (_, { stgCodes }) => (
            <>
              {stgCodes.map((stg) => {
                var color =
                  colorList[Math.floor(Math.random() * colorList.length)];
                return (
                  <Tag color={color} key={stg}>
                    {stg.toUpperCase()}
                  </Tag>
                );
              })}
            </>
          ),
        });
      } else {
        headers.push({
          title: key,
          dataIndex: key,
        });
      }
    });
    setColumns(headers);
  }

  const createFilterData = (rows) => {
    if (rows.length <= 0) return;
    let array = defaultHeaders[0];
    const object = defaultHeaders[0];

    Object.keys(object || {}).forEach((key) => {
      rows.forEach((element) => {
        if (
          element[key] &&
          !object[key].some((x) => x.value === element[key])
        ) {
          array[key].push({
            text: element[key],
            label: element[key],
            value: element[key],
          });
        }
      });
    });
    setFilters(array);
  };

  const getMediaRuleList = () => {
    setLoading(true);
    let data = [];
    setRows([]);
    ApiClientService.get(
      process.env.REACT_APP_API_GET_ALL_MEDIA_RULE_LIST,
      null
    )
      .then((value) => {
        if (!value) return;
        let mediaRuleList = value.data?.data;
        if (mediaRuleList && mediaRuleList.length > 0) {
          mediaRuleList.forEach(function(mediaRule) {
            data.push({
              project: mediaRule.project?.name ?? "",
              stgCodes: mediaRule.stgSelectList.map(function(x) {
                return x.label;
              }),
              imageQuantity: mediaRule.imageQuantity,
              videoQuantity: mediaRule.videoQuantity,
              role: Object.keys(RoleTypes)[parseInt(mediaRule.roleType) - 1],
              warningText: mediaRule.warningText,
              status: mediaRule.isActive ? "Active" : "Passive",
              actions: createDetailButton(mediaRule.id),
            });
          });
          setRows(data);
          if (data.length > 0) createFilterData(data);
        } else {
          return handleAlertMessage(
            snackbar,
            setSnackbar,
            "There is no data.",
            "warning"
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    createHeaders();
    getMediaRuleList();
  }, []);

  return (
    <>
      <div className="assign-activities row mt-5">
        <div className="col-12 mt-4 d-flex justify-content-end align-items-center">
          <Link
            to={{
              pathname: _Pathname,
              state: {
                params: {
                  action: "add",
                },
              },
            }}
            className="btn btn-primary"
          >
            Media Rule Add
          </Link>
        </div>
        <div className="col-12">
          <Table columns={columns} dataSource={rows} loading={loading} />
        </div>
      </div>
    </>
  );
}

export default MediaRuleList;
