import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Row, Col } from "reactstrap";
import { DatePicker } from "antd";
import moment from "moment";
import {
  GetProjectSelectList,
  GetUserSelectList,
  GetStgSelectList,
  GetSurveySelectList,
} from "../../../../../helpers/SelectHelpers";

const { RangePicker } = DatePicker;

const SurveyFilterForm = (props) => {
  const { filter, setFilter } = props;
  const [users, setUsers] = useState();
  const [projects, setProjects] = useState();
  const [surveys, setSurveys] = useState();
  const [stgs, setStgs] = useState();

  const handleChange = (e) => {
    var filterData = { ...filter };
    filterData.dateRange = e;
    filterData.startDate = e ? moment(e[0]).format("MM-DD-YYYY 00:00") : null;
    filterData.endDate = e ? moment(e[1]).format("MM-DD-YYYY 23:59:59") : null;

    setFilter(filterData);
  };
  const handleChangeSelect = (key, param, e) => {
    var filterData = { ...filter };
    filterData[key] = e;
    if (key == "project") filterData[param] = e != null ? e.entry : 0;
    else filterData[param] = e != null ? e.value : 0;
    if(key == "user" && e == null) filterData[param] = "";
    setFilter(filterData);
  };
  useEffect(() => {
    GetUserSelectList(setUsers);
    GetProjectSelectList(setProjects);
    GetSurveySelectList(setSurveys);
    GetStgSelectList(setStgs);
  }, []);
  return (
    <Row className="mt-3">
      <Col xs={12} sm={6} xl={4}>
        <label>Project</label>
        <Select
          value={filter.project}
          options={projects}
          isSearchable={true}
          isClearable={true}
          onChange={(e) => handleChangeSelect("project", "projectEntry", e)}
        />
      </Col>
      <Col xs={12} sm={6} xl={4}>
        <label>Stg</label>
        <Select
          value={filter.stg}
          options={stgs}
          isSearchable={true}
          isClearable={true}
          onChange={(e) => handleChangeSelect("stg", "stgId", e)}
        />
      </Col>
      <Col xs={12} sm={6} xl={4}>
        <label>Survey</label>
        <Select
          value={filter.survey}
          options={surveys}
          isSearchable={true}
          isClearable={true}
          onChange={(e) => handleChangeSelect("survey", "surveyId", e)}
        />
      </Col>
      <Col xs={12} sm={6} xl={4}>
        <label>User</label>
        <Select
          value={filter.user}
          options={users}
          isSearchable={true}
          isClearable={true}
          onChange={(e) => handleChangeSelect("user", "userId", e)}
        />
      </Col>
      <Col
        xs={12}
        sm={6}
        xl={4}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <label>Date Range</label>
        <RangePicker
          value={filter.dateRange}
          style={{ height: "39px" }}
          onChange={(e) => handleChange(e)}
        />
      </Col>
    </Row>
  );
};

export default SurveyFilterForm;
