import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "./shared/layout";
import { MyPage } from "./components/mypage/MyPage";
import { DashboardPage } from "./components/home/DashboardPage";
import UserManagementPage from "./components/usermanagement/UserManagementPage";
import AssignActivities from "./components/mypage/Activities/AssignActivities";
import ActivityAddEdit from "./components/mypage/Activities/ActivityAddEdit";
import Preview from "./components/mypage/PdfRender/index";
import ApproveWaitingActivities from "./components/mypage/Activities/ApproveWaitingActivites";
import ApprovedActivities from "./components/mypage/Activities/ApprovedActivities";
import CustomerApprovedActivities from "./components/mypage/Customer/ApprovedActivities";
import CustomerTransferWaitingActivities from "./components/mypage/Activities/CustomerTransferWaitingActivities";
import DamagedMaterialReport from "./components/mypage/Damage/DamagedMaterialReport";
import DamageList from "./components/mypage/Damage/DamageList";
import DamageAddEdit from "./components/mypage/Damage/DamageAddEdit";
import ActivityAttachmentReport from "./components/mypage/Report/ActivitiesAttachmentReport";
import UnapprovedActivities from "./components/mypage/Activities/UnapprovedActivities";
import Survey from "./components/mypage/Survey/Survey";
import SurveyAddEdit from "./components/mypage/Survey/SurveyAddEdit";
import SurveyAnswerReport from "./components/mypage/Report/Survey/SurveyAnswerReport";
import FlowMeterAddEdit from "./components/mypage/FlowMeter/FlowMeterAddEdit";
import MediaRuleAddEdit from "./components/mypage/MediaRule/MediaRuleAddEdit";
import MediaRuleList from "./components/mypage/MediaRule/MediaRuleList";
import SurveyFlowChart from "./components/mypage/Survey/SurveyFlowChart";
import SurveyFlowChartAddEdit from "./components/mypage/Survey/SurveyFlowAddEdit";
import FlowMeterList from "./components/mypage/FlowMeter/FlowMeterList";
import StgCencusPage from "./components/mypage/StgCencus";
import StageRulePage from "./components/mypage/StageRule";
const UserProfilepage = lazy(() =>
    import("./components/mypage/UserProfilePage")
);

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard" />
                }
                <ContentRoute path="/dashboard" component={DashboardPage} />
                <ContentRoute path="/my-page" component={MyPage} />
                <Route path="/user-profile" component={UserProfilepage} />
                <Route path="/user-management" component={UserManagementPage} />
                <ContentRoute path="/assigned-activities" component={AssignActivities} />
                <ContentRoute path="/approve-waiting-activities" component={ApproveWaitingActivities} />
                <ContentRoute path="/approved-activities" component={ApprovedActivities} />
                <ContentRoute path="/my-pending-activities" component={UnapprovedActivities} />
                <ContentRoute path="/attachment-report" component={ActivityAttachmentReport} />
                <ContentRoute path="/customer-transfer-waiting-activities" component={CustomerTransferWaitingActivities} />
                <ContentRoute path="/customer-approved-activities" component={CustomerApprovedActivities} />
                <ContentRoute path="/damaged-material-report" component={DamagedMaterialReport} />
                <ContentRoute path="/damage-list" component={DamageList} />
                <ContentRoute path="/survey" component={Survey} />
                <ContentRoute path="/survey-answer-report" component={SurveyAnswerReport} />
                <ContentRoute path="/flow-meter-list" component={FlowMeterList} />
                <ContentRoute path="/flow-meter-add-edit" component={FlowMeterAddEdit} />
                <ContentRoute path="/media-rule-list" component={MediaRuleList} />
                <ContentRoute path="/survey-flow-chart" component={SurveyFlowChart} />
                <ContentRoute path="/media-rule-add-edit" component={MediaRuleAddEdit} />
                <ContentRoute path="/stg-cencus" component={StgCencusPage} />
                <ContentRoute path="/stage-rule" component={StageRulePage} />
                <Route path="/survey-add-edit" component={SurveyAddEdit} />
                <Route path="/activity-add-edit" component={ActivityAddEdit} />
                <Route path="/damage-add-edit" component={DamageAddEdit} />
                <Route path="/flow-chart-add-edit" component={SurveyFlowChartAddEdit} />
                <Route path="/preview" component={Preview} />
                <Redirect to="error/error-v1" />
            </Switch>
        </Suspense>
    );
}
