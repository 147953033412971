import React, { useContext, useEffect, useState } from "react";
import { Table } from "antd";
import ExportExcel from "../../../pages/ExportExcel";
import ApiClientService from "../../../../services/auth/api-client-service";
import {
  createTableHeaders,
  handleAlertMessage,
} from "../../../../helpers/Helpers";
import { Col, Row } from "reactstrap";
import SurveyFilterForm from "./components/SurveyFilterForm";
import { MainContext } from "../../../../context/context";
import "../style.css";
var _DefaultHeader = {
  Project: "",
  Stage: "",
  Stg: "",
  WorkingLocation: "",
  SubWorkingLocation: "",
  QuestionSet: "",
  SubQuestionSet: "",
  Question: "",
  Answer: "",
  User:"",
  Date:""
};

function SurveyAnswerReport() {
  const { snackbar, setSnackbar } = useContext(MainContext);
  const [headers, setHeaders] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    project: {},
    projectEntry: 0,
    startDate: null,
    endDate: null,
    user: {},
    userId: "",
    survey: "",
    surveyId: 0,
    stg: {},
    stgId: 0,
    dateRange: "",
  });
  const validateFilter = () => {
    var isValid = false;
    var message = "";
    if (
      (filter.startDate == null || filter.endDate == null) &&
      filter.projectEntry == 0 &&
      filter.userId == "" &&
      filter.surveyId == 0 &&
      filter.stgId == 0
    ) {
      isValid = true;
      message = "Please select a field you want to filter";
    }
    return [isValid, message];
  };

  const getFilterData = () => {
    const [isValid, message] = validateFilter();
    if (isValid)
      return handleAlertMessage(snackbar, setSnackbar, message, "warning");
    setLoading(true);
    ApiClientService.post(
      process.env.REACT_APP_API_REPORT_SURVEY_ANSWER,
      filter
    ).then((value) => {
      if (!value) return;
      var data = value.data;
      setLoading(false);
      if (data.length > 0) {
        createTableHeaders(setHeaders, Object.keys(data[0]));
        setRows(data);
      } else {
        createTableHeaders(setHeaders, Object.keys(_DefaultHeader));
        setRows([]);
        return handleAlertMessage(
          snackbar,
          setSnackbar,
          "There is no data according to the searched filter.",
          "warning"
        );
      }
    });
  };


  return (
    <>
      <div className="card p-5 mt-4 mb-4">
        <Row css={{ zIndex: 99 }}>
          <Col sm={12}>
            <SurveyFilterForm filter={filter} setFilter={setFilter} />
          </Col>
          <Col sm={6} className="mt-4">
            <ExportExcel data={rows} fileName="surveyUserAnswerReport" />
          </Col>
          <Col sm={6} className="mt-4 d-flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={getFilterData}
            >
              Filter
            </button>
          </Col>
        </Row>
      </div>
      <div className="mt-3">
        <Table
          columns={headers}
          dataSource={rows}
          scroll={{ x: 3000 }}
          loading={loading}
        />
      </div>
    </>
  );
}

export default SurveyAnswerReport;
