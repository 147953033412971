import React, { useEffect, useState, memo, Fragment } from "react";
import Header from "./components/Header";
import MaterialTable from "./components/MaterialTable";
import Activities from "./components/Activities";
import Equipment from "./components/Equipment";
import TimeSheet from "./components/TimeSheet";
import Signature from "./components/Signature";
import Footer from "./components/Footer";
import { styles, indexStyles } from "./style/style";
import ApiClientService from "../../../services/auth/api-client-service";
import { PDFViewer, Document, Page, Font, View } from "@react-pdf/renderer";
import moment from "moment";
import {
  checkIfDateBetween,
  distinctArray,
  dynamicSort,
  groupBy,
} from "../../../helpers/Helpers";
import { ConvertDecimalHour, minDateArray } from "../../../helpers/DateHelpers";
import workSiteList from "../../../helpers/PdfHelpers";

function Preview(props) {
  const [issue, setIssue] = useState([
    {
      materials: [],
      locations: [],
      timeSheet: [],
    },
  ]);
  const [address, setAddress] = useState({
    first: "",
    second: "",
  });
  const [header, setHeader] = useState({
    poNum: "",
    adress: "",
    documentNo: "",
    bpCode: "",
    bpName: "",
  });
  const [timeSheet, setTimeSheet] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [contactName, setContactName] = useState("");
  const [voiceRule, setVoiceRule] = useState(null);

  const issueList = window.location.href.includes("preview")
    ? props.location.state
      ? props.location.state.params.issue
      : []
    : props.issue
    ? props.issue
    : [];

  Font.register({
    family: "Open Sans",
    fonts: [
      {
        src:
          "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
      },
      {
        src:
          "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
        fontWeight: 600,
      },
      {
        src:
          "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf",
        fontWeight: 700,
      },
      {
        src:
          "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-300italic.ttf",
        fontWeight: 300,
        fontStyle: "italic",
      },
      {
        src:
          "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600italic.ttf",
        fontWeight: 600,
        fontStyle: "italic",
      },
      {
        src:
          "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf",
        fontWeight: 800,
      },
      {
        src:
          "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700italic.ttf",
        fontWeight: 700,
        fontStyle: "italic",
      },
    ],
  });
  const timeSheetModel = (data) => {
    var timeSheetList = [];
    var issueList = data.map((x) => ({
      startDate: x.startDate,
      endDate: x.endDate,
      userId: x.userId,
      userName: "",
      stageName: x.stageName,
    }));

    // issueList.sort(dynamicSort("startDate"));
    // var issueUserGroup = groupBy(issueList, "userId");
    // var userIdList = Object.keys(issueUserGroup);
    // var tempTimeSheetList = {};
    // var tempStageEmployes = [];
    // for (var i = 0; i < userIdList.length; i++) {
    //   var arr = issueUserGroup[userIdList[i]];
    //   for (var j = 0; j < arr.length; j++) {
    //     if (!tempTimeSheetList[userIdList[i]]) {
    //       tempTimeSheetList[userIdList[i]] = [];
    //       tempTimeSheetList[userIdList[i]].push(arr[j]);
    //     } else {
    //       tempTimeSheetList[userIdList[i]].forEach((element) => {
    //         var isOk = false;
    //         tempTimeSheetList[userIdList[i]].forEach((object) => {
    //           var timeType = timeSheetRule(arr[j].startDate, object.endDate);
    //           if (isTimeBetween(object, arr[j])) {
    //             object.startDate =
    //               object.startDate <= arr[j].startDate
    //                 ? object.startDate
    //                 : arr[j].startDate;
    //             object.endDate =
    //               object.endDate >= arr[j].endDate
    //                 ? object.endDate
    //                 : arr[j].endDate;
    //             isOk = true;
    //           }
    //           if (timeType === "ardisik" || timeType === "lunch") {
    //             object.startDate =
    //               object.startDate <= arr[j].startDate
    //                 ? object.startDate
    //                 : arr[j].startDate;
    //             object.endDate =
    //               object.endDate >= arr[j].endDate
    //                 ? object.endDate
    //                 : arr[j].endDate;
    //             isOk = true;
    //           }
    //         });
    //         if (!isOk) {
    //           var tempData = tempTimeSheetList[userIdList[i]].filter(
    //             (x) =>
    //               x.startDate === arr[j].startDate &&
    //               x.endDate === arr[j].endDate
    //           );
    //           if (tempData.length < 1)
    //             tempTimeSheetList[userIdList[i]].push(arr[j]);
    //         }
    //       });
    //     }
    //   }
    // }
    // var timeSheetUserKey = Object.keys(tempTimeSheetList);
    // for (let i = 0; i < timeSheetUserKey.length; i++) {
    //   tempTimeSheetList[timeSheetUserKey[i]].forEach((element) => {
    //     timeSheetList.push(element);
    //   });
    // }
    var promises = [];
    // var timeSheetDisctinctUserId = distinctArray(timeSheetList, "userId");
    var timeSheetDisctinctUserId = distinctArray(issueList, "userId");

    var users = [];
    timeSheetDisctinctUserId.forEach((element) => {
      var userObject = {
        userId: element.userId,
      };
      promises.push(
        ApiClientService.get(
          process.env.REACT_APP_API_GET_USER_BY_ID,
          userObject
        ).then((response) => {
          if (response) {
            users.push(response.data.data);
          }
        })
      );
    });
    // timeSheetList.forEach((element) => {
    //   var stageEmployeParam = {
    //     userId: element.userId,
    //     stageName: element.stageName,
    //   };
    //   promises.push(
    //     ApiClientService.get(
    //       process.env.REACT_APP_API_GET_EMPLOYEE,
    //       stageEmployeParam
    //     ).then((value) => {
    //       var stageEmployeeRespone = value.data.data;
    //       if (stageEmployeeRespone != null) {
    //         tempStageEmployes.push(stageEmployeeRespone);
    //       }
    //     })
    //   );
    // });

    Promise.all(promises).then(() => {
      issueList.forEach((element) => {
        var user = users?.find((x) => x.id === element.userId);
        element.userName = [user.firstName, user.lastName].join(" ");
      });
      // timeSheetList.forEach((element) => {
      //   var user = users.find((x) => x.id === element.userId);
      //   element.userName = [user.firstName, user.lastName].join(" ");
      //   var stageEmployeObject = tempStageEmployes.find(
      //     (x) => x.userId == element.userId && x.stage.name == element.stageName
      //   );
      //   var strPlannedStart = stageEmployeObject.workStartTime.split("T");
      //   var strPlannedEnd = stageEmployeObject.workEndTime.split("T");
      //   var strRealStart = element.startDate.split("T");
      //   var strRealEnd = element.endDate.split("T");
      //   var startDiff = timeDiff(strPlannedStart[1], strRealStart[1]);
      //   var endDiff = timeDiff(strPlannedEnd[1], strRealEnd[1]);
      //   var timeIn = ConvertDecimalHour(0.5);
      //   var timeOut = ConvertDecimalHour(0.25);

      //   if (startDiff.hour == timeIn.hour) {
      //     if (startDiff.minute <= timeIn.minute) {
      //       element.startDate = strRealStart[0] + "T" + strPlannedStart[1];
      //     }
      //   } else if (startDiff.hour < timeIn.hour) {
      //     element.startDate = strRealStart[0] + "T" + strPlannedStart[1];
      //   }

      //   if (endDiff.hour == timeOut.hour) {
      //     if (endDiff.minute <= timeOut.minute) {
      //       element.endDate = strRealEnd[0] + "T" + strPlannedEnd[1];
      //     }
      //   } else if (endDiff.hour < timeOut.hour) {
      //     element.endDate = strRealEnd[0] + "T" + strPlannedEnd[1];
      //   }
      // });
      timeSheetList.sort(function(a, b) {
        return (
          a.startDate.localeCompare(b.startDate) && b.userName - a.userName
        );
      });
      //timeSheetList.sort(dynamicSort("startDate"));
      setTimeSheet([...timeSheet, ...issueList]);
    });
  };
  function timeDiff(start, end) {
    var date1 = new Date();
    var date2 = new Date();
    var startSplit = start.split(":");
    var endSplit = end.split(":");
    date1.setHours(startSplit[0], startSplit[1]);
    date2.setHours(endSplit[0], endSplit[1]);
    var diff = date2.getTime() - date1.getTime();

    var msec = diff < 0 ? diff * -1 : diff;
    var hour = Math.floor(msec / 1000 / 60 / 60);
    msec -= hour * 1000 * 60 * 60;
    var minute = Math.floor(msec / 1000 / 60);
    return {
      hour: hour,
      minute: minute,
    };
  }
  function isTimeBetween(obj1, obj2) {
    return (
      checkIfDateBetween(obj1.startDate, obj2.startDate, obj2.endDate) ||
      checkIfDateBetween(obj2.startDate, obj1.startDate, obj1.endDate) ||
      checkIfDateBetween(obj1.endDate, obj2.startDate, obj2.endDate) ||
      checkIfDateBetween(obj2.endDate, obj1.startDate, obj1.endDate)
    );
  }
  function timeSheetRule(start, end) {
    var combineDur = ConvertDecimalHour(voiceRule.combineDur);
    var hour = parseInt(moment.duration(moment(end).diff(start)).asHours());
    var minute =
      parseInt(moment.duration(moment(end).diff(start)).asMinutes()) % 60;
    if (Math.abs(hour) == 0 && Math.abs(minute) <= 1) return "ardisik";
    if (Math.abs(hour) < combineDur.hour) return "lunch";
    if (
      Math.abs(hour) == combineDur.hour &&
      Math.abs(minute) <= combineDur.minute
    )
      return "lunch";
    return "noTimer";
  }
  const getProjectByEntry = (entry) => {
    var param = { absEntry: entry };
    ApiClientService.get(
      process.env.REACT_APP_API_GET_PROJECT_ABS_ENTRY,
      param
    ).then((response) => {
      if (!response) return;
      if (response.data.data) {
        var data = response.data?.data;
        if (data) {
          var newState = { ...address };
          var firstArray = [];
          var secondArray = [];
          firstArray.push(data.address2);
          firstArray.push(data.address3);
          if (data.city) secondArray.push(data.city);
          if (data.state) secondArray.push(data.state);
          if (data.zipCode) secondArray.push(data.zipCode);
          if (data.country) secondArray.push(data.country);
          newState.first = firstArray.join(",");
          newState.second = secondArray.join(",");
          setAddress(newState);
          // var voiceRuleParam = {
          //   projectId: data.id,
          // };
          // ApiClientService.get(
          //   process.env.REACT_APP_API_VOICE_RULE_GET_BY_PROJECT_ID,
          //   voiceRuleParam
          // ).then((response) => {
          //   if (response) {
          //     setVoiceRule(response.data.data);
          //   }
          // });
        }
      }
    });
  };
  const createModel = (issueList, stageRules) => {
    var materials = [];
    var activities = {
      locations: [],
      jobs: [],
      tasks: [],
    };

    var issueIdList = issueList?.map((x) => x.id);
    setContactName(issueList[0].contactName);
    getProjectByEntry(issueList[0].projectEntry);
    workSiteList(issueIdList, header, setHeader);

    issueList.forEach(function(issue, index) {
      issue.issueItems.forEach(function(item) {
        var strLength = item.itemName.length;
        var itemHeightSize = "15px";
        if (strLength > 61) itemHeightSize = "22px";
        materials.push({
          itemCode: item.geoArtCode,
          itemName: item.itemName,
          qty: 1,
          itemInventoryUoM: item.itemInventoryUoM,
          quantity: item.quantity,
          height: itemHeightSize,
        });
      });
      if (issue.description.trim() !== "") {
        var strLength = issue.description.length;
        var itemHeightSize = "15px";
        if (strLength > 61) itemHeightSize = "22px";
        materials.push({
          itemCode: "",
          itemName: issue.description,
          qty: "",
          itemInventoryUoM: "",
          quantity: "",
          height: itemHeightSize,
        });
      }
      activities.locations.push({
        itemName: issue.workingLocation,
      });
      var isHidden = !stageRules.includes(issue.stageName);
      activities.jobs.push({
        itemName: isHidden ? issue.stgDesc : "",
      });
      activities.tasks.push({
        itemName: isHidden ? issue.taskDesc : "",
      });
      activities.jobs = distinctData(activities.jobs);
      activities.tasks = distinctData(activities.tasks);
      activities.locations = distinctData(activities.locations);
    });

    var materialGroup = groupBy(materials, "itemCode");
    var tempMaterial = [];
    for (var key in materialGroup) {
      if (!materialGroup.hasOwnProperty(key)) continue;
      var obj = materialGroup[key];
      var sum = 0;
      for (var prop in obj) {
        if (!obj.hasOwnProperty(prop)) continue;
        sum += obj[prop].quantity;
      }
      tempMaterial.push({
        itemCode: obj[0].itemCode,
        itemName: obj[0].itemName,
        qty: obj[0].itemCode.trim() !== "" ? 1 : "",
        itemInventoryUoM: obj[0].itemInventoryUoM,
        quantity: obj[0].itemCode.trim() !== "" ? sum : "",
        height: obj[0].height,
      });
    }

    setIssue({
      materials: tempMaterial,
      activities: activities,
      timeSheet: [],
    });
  };
  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };
  useEffect(() => {
    if (issueList.length > 0) {
      ApiClientService.get(
        process.env.REACT_APP_API_STAGE_RULE_GET_ALL_HIDDEN,
        null
      ).then((value) => {
        var data = value.data?.data;
        var stageRules = [];
        if (data && data.length > 0) {
          stageRules = data?.map((x) => x.stageName);
        }
        createModel(issueList, stageRules);
      });
    }

    getTasks(issueList?.map((x) => x.taskCode)?.filter(onlyUnique));
    var isResource =
      issueList?.filter((x) => x.issueResources.length > 0)?.length > 0;
    if (isResource) {
      var projectEntry = issueList.find((e) => typeof e !== "undefined")
        ?.projectEntry;
      if (projectEntry !== null && Object.keys(projectEntry).length !== 0)
        getEquipments(projectEntry);
    }
  }, []);
  // useEffect(() => {
  //   if (voiceRule) timeSheetModel(issueList);
  // }, [voiceRule]);
  useEffect(() => {
    var timeData = issueList.filter(
      (x) => x.stgDesc?.toLowerCase() === "end of shift - timesheet"
    );
    if (timeData != null && timeData.length > 0) timeSheetModel(timeData);
  }, []);
  const distinctData = (array) => {
    return array.filter(
      (element, index) =>
        array.findIndex((obj) => obj.itemName === element.itemName) === index
    );
  };

  const getEquipments = (projectEntry) => {
    var arrayEquipment = [];
    var param = { projectEntry: projectEntry };
    ApiClientService.get(
      process.env.REACT_APP_API_GET_WHS_RESOURCES_PROJECT_ENTRY,
      param
    ).then((response) => {
      if (!response) return;
      if (response.data.data) {
        var array = response.data?.data;
        array.forEach((element) => {
          arrayEquipment.push(element.dailyRecord);
        });
        arrayEquipment.sort();
        setEquipments(arrayEquipment);
      }
    });
  };

  const getTasks = (avc) => {
    var taskCodeList = [];
    avc.forEach((element) => {
      taskCodeList.push(element);
    });
    ApiClientService.post(
      process.env.REACT_APP_API_GET_TASK_CODE,
      taskCodeList
    ).then((response) => {
      if (!response) return;
      if (response.data?.length > 0) {
        setTasks(response.data?.map((x) => x.mainJob)?.filter(onlyUnique));
      }
    });
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.body}>
        {issueList.length > 0 ? (
          <Fragment>
            <View style={indexStyles.breakable} fixed>
              <Header
                header={header}
                date={minDateArray(
                  issueList?.map((x) => x.startDate),
                  "DD.MM.YYYY"
                )}
                projectEntry={issueList[0]?.projectEntry}
                tasks={tasks}
                address={address}
              />
            </View>
            <View
              style={{
                flex: 3.5,
                flexDirection: "column",
              }}
            >
              <MaterialTable item={issue.materials} contactName={contactName} />
              <Activities item={issue.activities} />
              <Equipment equipments={equipments} />
              <TimeSheet data={timeSheet} />
            </View>
            <View
              wrap={false}
              style={{
                flex: 0.3,
                justifyContent: "flex-end",
              }}
            >
              <Signature item={issueList} />
            </View>
            <Footer documentNo={header.documentNo} />
          </Fragment>
        ) : (
          <></>
        )}
      </Page>
    </Document>
  );

  return (
    <div style={{ flexGrow: 2 }}>
      <PDFViewer
        className="pdf-url"
        showToolbar={true}
        style={{
          width: window.location.href.includes("preview") ? "100%" : "15rem",
          height: window.location.href.includes("preview") ? "100rem" : "20rem",
        }}
      >
        <MyDocument />
      </PDFViewer>
    </div>
  );
}
export default memo(Preview);
