import { Divider } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { GetSurveySelectList } from "../../../helpers/SelectHelpers";
import { Col, Row, Input, Button } from "reactstrap";
import BaseSelect from "react-select";
import FixRequiredSelect from "../../pages/FixRequiredSelect";
import FlowChartRule from "./FlowChartRule";
import { questionStyles } from "./style/QuestionSetStyle";
import { MainContext, SurveyFlowContext } from "../../../context/context";
import { dynamicSort, handleAlertMessage } from "../../../helpers/Helpers";
import ApiClientService from "../../../services/auth/api-client-service";
import { RoleTypeOptions } from "../../../mockupData/roleType";
import { QuestionSectionParameterEnum } from "../../../enums/surveyEnum";

const _DefaultFlowChart = {
  id: 0,
  flowName: "",
  selectedProject: null,
  selectedStg: null,
  selectedDisplayStep: null,
  selectedRole: null,
  selectedQuestionSectionParameter: null,
  displayOrder: "",
  isStgDisable: true,
  message: "",
  surveys: [],
};

function SurveyFlowChartAddEdit(props) {
  const [navigateData, setNavigateData] = useState(
    props.history.location.state
  );
  const [surveyOptions, setSurveyOptions] = useState([]);
  const { snackbar, setSnackbar } = useContext(MainContext);
  const [flowSection, setFlowSection] = useState(_DefaultFlowChart);
  const [survey, setSurvey] = useState([]);

  const classes = questionStyles();

  const Select = (props) => (
    <FixRequiredSelect
      {...props}
      SelectComponent={BaseSelect}
      options={props.options}
    />
  );

  useEffect(() => {
    GetSurveySelectList(setSurveyOptions);
    if (navigateData && navigateData.params.action == "update") {
      getByIdFlowChart(navigateData.params.flowChartId);
    } else {
      setFlowSection({ ...flowSection, surveys: [] });
    }
  }, []);

  const addedSurvey = (event, index) => {
    event.preventDefault();
    var tempSection = { ...flowSection };

    if (!survey || survey?.length <= 0)
      return handleAlertMessage(
        snackbar,
        setSnackbar,
        "Survey is Empty",
        "warning"
      );

    var isThere = tempSection.surveys?.some((x) => x.name == survey.label);
    if (isThere) {
      handleAlertMessage(snackbar, setSnackbar, "Duplicate", "warning");
    } else {
      tempSection.surveys.push({
        name: survey.label,
        surveyId: survey.value,
        displayOrder: 0,
      });
      setFlowSection(tempSection);
    }
    setSurvey("");
  };

  const getByIdFlowChart = (id) => {
    var headers = {
      id: id,
    };
    ApiClientService.get(
      process.env.REACT_APP_API_GET_BY_ID_SURVEY_FLOW,
      headers
    ).then((value) => {
      if (!value) return;
      if (!value)
        handleAlertMessage(snackbar, setSnackbar, "An error occurred", "error");
      if (value.status == 200 || value.status == 201 || value.status == 204) {
        var flowChart = value.data.data;
        var newState = {
          id: flowChart.id,
          flowName: flowChart.name,
          selectedStg: flowChart.stgSelects,
          selectedProject: flowChart.projectSelects,
          selectedDisplayStep: {
            label: flowChart?.surveyDisplayStep?.name,
            value: flowChart?.surveyDisplayStep?.id,
          },
          selectedQuestionSectionParameter: selectQuestionParameter(
            flowChart.stgIdList
          ),
          selectedRole: RoleTypeOptions.find(
            (x) => x.value == flowChart.roleType
          ),
          displayOrder: flowChart.displayOrder,
          message: flowChart?.message,
          isStgDisable: flowChart.stgSelects?.length > 0 ? false : true,
          surveys: flowChart.surveySurveyFlowCharts
            .map((surveyFlow) => ({
              name: surveyFlow.survey.title,
              surveyId: surveyFlow.surveyId,
              displayOrder: surveyFlow.displayOrder,
            }))
            ?.sort(dynamicSort("displayOrder")),
        };
        setFlowSection(newState);
      } else {
        var message = "An error occurred";
        handleAlertMessage(snackbar, setSnackbar, message, "error");
      }
    });
  };
  const selectQuestionParameter = (strValue) => {
    if (!strValue) {
      return QuestionSectionParameterEnum.All;
    } else {
      return QuestionSectionParameterEnum.Stg;
    }
  };
  const handleChange = (value, surveyIndex, event) => {
    event.preventDefault();
    const sort = value.replace(/\+|-/gi, "");
    var newState = { ...flowSection };
    newState.surveys[surveyIndex].displayOrder = sort;
    setFlowSection(newState);
  };
  const removeSurvey = (event, index) => {
    event.preventDefault();
    var newState = [];
    var object = { ...flowSection };
    object.surveys.forEach((element, i) => {
      if (index != i) newState.push(element);
    });
    object.surveys = newState;
    setFlowSection(object);
  };

  const flowContextData = {
    flowSection,
    setFlowSection,
  };

  return (
    <SurveyFlowContext.Provider value={flowContextData}>
      <div>
        <FlowChartRule />
        <Divider></Divider>
        <div className="card p-5 mt-4 mb-4">
          <Row className={classes.row}>
            <Col sm={8}>
              <label>Survey</label>
              <Select
                value={survey}
                options={surveyOptions}
                isClearable={false}
                isSearchable={true}
                onChange={(e) => setSurvey(e)}
              />
            </Col>
            <Col sm={4} className="d-flex justify-content-end align-items-end">
              <button
                onClick={(e) => addedSurvey(e)}
                className="btn btn-primary"
              >
                Add
              </button>
            </Col>
            <Divider />
            <Col sm={12}>
              {flowSection.surveys?.map((survey, sIndex) => (
                <Row>
                  <Col sm={12} md={6} lg={6}>
                    <label>Survey Name</label>
                    <Input
                      type="text"
                      name="SurveyName"
                      value={survey.name}
                      isDisabled={true}
                    />
                  </Col>
                  <Col sm={6} md={4} lg={4}>
                    <label>Order</label>
                    <Input
                      value={survey.displayOrder}
                      type="number"
                      name="displayOrder"
                      onChange={(e) => handleChange(e.target.value, sIndex, e)}
                    />
                  </Col>
                  <Col
                    sm={6}
                    md={2}
                    lg={2}
                    className="d-flex justify-content-end align-items-end"
                  >
                    <Button
                      color="danger"
                      onClick={(e) => removeSurvey(e, sIndex)}
                    >
                      -
                    </Button>
                  </Col>
                  <Divider />
                </Row>
              ))}
            </Col>
          </Row>
        </div>
      </div>
    </SurveyFlowContext.Provider>
  );
}

export default SurveyFlowChartAddEdit;
