import { Button, Modal } from "antd";
import React, { useState, memo } from "react";
import { FiMail } from "react-icons/fi";
import MailInput from "./MailInput";
import GeoTable from "./Table";
import ApiClientService from "../../../services/auth/api-client-service";
import Preview from "../../mypage/PdfRender";
import { Col, Row } from "react-bootstrap";
import UserSnackbars from "../Snackbar";
import { minDateArray } from "../../../helpers/DateHelpers";

const GeoModal = (props) => {
  const { issue } = props;
  const [alertMessage, setAlertMessage] = useState({
    open: false,
    variant: "",
    message: "",
    timeout: 5000,
  });
  const [visible, setVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableProp, setTableProp] = useState({ header: [], rows: [] });
  const [mailProp, setMailProp] = useState({
    items: [],
    value: "",
    error: null,
  });

  const handleAlert = (message, variant) => {
    var alert = { ...alertMessage };
    alert.message = message;
    alert.variant = variant;
    alert.open = true;
    setAlertMessage(alert);
  };
  const closeSnackbar = () => {
    var alert = { ...alertMessage };
    alert.open = false;
    setAlertMessage(alert);
  };
  async function getData() {
    var obj = {...issue[0] ?? {}};
    if (obj != null && obj.length != 0) {
      var param = { projectEntry: obj.projectEntry };
      await ApiClientService.get(
        process.env.REACT_APP_API_GET_BY_PROJECT_CONTACT_PROJECT_ENTRY,
        param
      ).then((response) => {
        if (!response.data) return handleAlert("Data empty", "warning");

        var data = response.data?.data
          ?.filter((x) => x.email && x.email.trim() != "")
          .map((x, index) => ({
            name: x.name,
            mail: x.email,
            key: index,
          }));

        var createHeader = [];
        var defaultHeader = {
          name: "",
          mail: "",
        };
        Object.keys(defaultHeader).forEach((key) => {
          createHeader.push({
            title: key,
            dataIndex: key,
          });
        });
        var tempData = { ...data };
        tempData.header = createHeader;
        tempData.rows = data;
        setTableProp(tempData);
      });
    }
  }
  function openModal() {
    getData();
    setVisible(true);
    setMailProp({
      items: [],
      value: "",
      error: null,
    });
    setSelectedRows([]);
  }

  async function sendToMail() {
    if (selectedRows.length <= 0 && mailProp.items?.length <= 0)
      return handleAlert(
        "Please select the e-mail address to be sent.",
        "warning"
      );
    var issueFirstElement = {...issue[0] ?? {}};

    var formdata = new FormData();
    var uri = document.getElementsByClassName("pdf-url")[0].src;

    let response = await fetch(uri);
    let data = await response.blob();
    let metadata = {
      type: "file/pdf",
    };
    let file = new File([data], "daily-record.pdf", metadata);

    selectedRows.forEach((element) => {
      formdata.append("Mails", element);
    });
    mailProp.items.forEach((element) => {
      formdata.append("Mails", element);
    });

    formdata.append("Attachments", file, uri);
    var body = "";
    if (issueFirstElement != null) {
      var projectDate = minDateArray(
        issue?.map((x) => x.startDate),
        "MMMM Do"
      );
      body =
        "Hello " +
        issueFirstElement.contactName +
        " <br /> " +
        "Attached you can find " +
        projectDate +
        " Daily Report for your signature. <br />" +
        "Have a great day!";
    }
    formdata.append("Body", body);
    formdata.append(
      "ProjectName",
      issueFirstElement != null ? issueFirstElement.projectName : ""
    );
    formdata.append(
      "IssuePackageId",
      issueFirstElement != null ? issueFirstElement.approveGroupGuid : ""
    );

    await ApiClientService.post(
      process.env.REACT_APP_API_PROJECT_CONTACT_SEND_MAIL,
      formdata
    )
      .then((response) => {
        return handleAlert("Successfully sent.", "success");
      })
      .catch((error) =>
        handleAlert("An error occurred while sending mail.", "warning")
      );
  }

  return (
    <>
      <button
        onClick={() => openModal()}
        className="btn btn-success btn-sm"
        style={{ whiteSpace: "nowrap" }}
      >
        <FiMail /> Send Mail
      </button>
      <Modal
        title="Send to project mail"
        centered
        visible={visible}
        onOk={() => sendToMail()}
        onCancel={() => setVisible(false)}
        okText="Send"
        width={1000}
      >
        <Row>
          <Col sm={8}>
            <MailInput mailProp={mailProp} setMailProp={setMailProp} />
          </Col>
          <Col sm={4}>
            <Preview issue={issue} />
          </Col>
        </Row>
        <GeoTable
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          tableProp={tableProp}
        />
        <UserSnackbars
          open={alertMessage.open}
          variant={alertMessage.variant}
          message={alertMessage.message}
          timeout={alertMessage.timeout}
          handleClose={closeSnackbar}
        />
      </Modal>
    </>
  );
};

export default memo(GeoModal);
