/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useState } from "react";
import objectPath from "object-path";
import { useLocation, useHistory } from "react-router-dom";
import { BreadCrumbs } from "./components/BreadCrumbs";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../_core/MetronicSubheader";
import { useHtmlClassService } from "../_core/MetronicLayout";

export function SubHeader() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const history = useHistory();

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderFixed: objectPath.get(uiService.config, "subheader.fixed"),
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  const logoutClick = () => {
    history.push("/logout");
    window.location.reload();
  };
  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    );
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);
  //TODO: YAZICI
  const menu = [
    { url: "/assigned-activities", title: "Assigned Activities" },
    {
      url: "/approve-waiting-activities",
      title: "Approve Waiting Activities",
    },
    { url: "/approved-activities", title: "Approved Activities" },
    { url: "/my-pending-activities", title: "My Pending Activities" },
    {
      url: "/customer-transfer-waiting-activities",
      title: "Customer Transfer Waiting Activities",
    },
    {
      url: "/customer-approved-activities",
      title: "Customer Approved Activities",
    },
    { url: "/damaged-material-report", title: "Damaged Material Report" },
    { url: "/damage-list", title: "Damage List" },
    { url: "/user-management", title: "User Management" },
    { url: "/survey", title: "Survey" },
    { url: "/survey-flow-chart", title: "Survey Flow Chart" },
    { url: "/survey-answer-report", title: "Survey Answer Report" },
    { url: "/activity-add-edit", title: "Activity Add Edit" },
    { url: "/damage-add-edit", title: "Damage Activity Add Edit" },
    { url: "/flow-meter-add-edit", title: "Flow Meter Add Edit" },
    { url: "/flow-meter-list", title: "Flow Meter List" },
    { url: "/media-rule-list", title: "Media Rule List" },
    { url: "/media-rule-add-edit", title: "Media Rule Add Edit" },
    { url: "/flow-chart-add-edit", title: "Survey Flow Chart Add Edit" },
    { url: "/survey-flow-chart", title: "Survey Flow Chart" },
    { url: "/attachment-report", title: "Activity Attachment Report" },
    { url: "/stage-rule", title: "Stage Rule" }
  ];
  var path = window.location.pathname;
  var object = menu.find((x) => x.url == path);
  var headerTitle = object ? object.title : "";

  const activity = JSON.parse(localStorage.getItem("Activity"));
  const damageActivity = JSON.parse(localStorage.getItem("DamageActivity"));
  if (path != "/activity-add-edit") localStorage.removeItem("Activity");
  else if (path != "/damage-add-edit")
    localStorage.removeItem("damageActivity");
  return (
    <div
      id="kt_subheader"
      className={`subheader py-3 py-lg-8 ${layoutProps.subheaderCssClasses}`}
      style={{ backgroundColor: "white" }}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap mr-1">
          {/* begin::Mobile Toggle */}
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}
          {/* end::Mobile Toggle */}

          {/* begin::Heading */}
          <div className="d-flex align-items-baseline flex-column mr-5">
            {/* begin::Title */}
            <h2 className="subheader-title text-dark font-weight-bold my-2 mr-3">
              {subheader.title.trim() == "" ? headerTitle : subheader.title}
            </h2>
            {/* end::Title */}
            {activity != null && path == "/activity-add-edit" ? (
              <>
                <ul className="breadcrumb breadcrumb-transparent font-weight-bold my-2 p-0">
                  <li className="breadcrumb-item" key={activity.projectName}>
                    <span className="text-muted ">{activity.projectName}</span>
                  </li>
                  <li className="breadcrumb-item" key={activity.stageName}>
                    <span className="text-muted ">{activity.stageName}</span>
                  </li>
                  <li className="breadcrumb-item" key={activity.docNum}>
                    <span className="text-muted ">{activity.docNum}</span>
                  </li>
                  <li className="breadcrumb-item" key={activity.stgDesc}>
                    <span className="text-muted ">{activity.stgDesc}</span>
                  </li>
                </ul>
              </>
            ) : (
              ""
            )}
            {damageActivity != null && path == "/damage-add-edit" ? (
              <>
                <ul className="breadcrumb breadcrumb-transparent font-weight-bold my-2 p-0">
                  <li
                    className="breadcrumb-item"
                    key={damageActivity.projectName}
                  >
                    <span className="text-muted ">
                      {damageActivity.projectName}
                    </span>
                  </li>
                  <li
                    className="breadcrumb-item"
                    key={damageActivity.stageName}
                  >
                    <span className="text-muted ">
                      {damageActivity.stageName}
                    </span>
                  </li>
                  <li className="breadcrumb-item" key={damageActivity.docNum}>
                    <span className="text-muted ">{damageActivity.docNum}</span>
                  </li>
                  <li className="breadcrumb-item" key={damageActivity.stgDesc}>
                    <span className="text-muted ">
                      {damageActivity.stgDesc}
                    </span>
                  </li>
                </ul>
              </>
            ) : (
              ""
            )}
            <BreadCrumbs items={subheader.breadcrumbs} />
          </div>
          <div style={{ position: "absolute", right: "1%" }}>
            <button
              onClick={logoutClick}
              className="btn btn-light-primary btn-bold"
            >
              Sign out
            </button>
          </div>
          {/* end::Heading */}
        </div>
        {/* Info */}

        {/* Toolbar */}
        {/*<div className="d-flex align-items-center flex-wrap">*/}
        {/*<button*/}
        {/*  type="button"*/}
        {/*  className={`btn btn-primary btn-fixed-height font-weight-bold px-2 px-lg-5 mr-2`}*/}
        {/*>*/}
        {/*  <span className="svg-icon svg-icon-lg">*/}
        {/*    <SVG*/}
        {/*      src={toAbsoluteUrl(*/}
        {/*        "/media/svg/icons/Communication/Add-user.svg"*/}
        {/*      )}*/}
        {/*    />*/}
        {/*  </span>*/}
        {/*  {` `}New Member*/}
        {/*</button>*/}

        {/*<QuickActions />*/}
        {/* Button */}
        {/*<button*/}
        {/*  className="btn btn-primary btn-icon font-weight-bolds   "*/}
        {/*  data-toggle="modal"*/}
        {/*  data-target="#kt_chat_modal"*/}
        {/*>*/}
        {/*  <span className="svg-icon svg-icon-lg">*/}
        {/*    <SVG*/}
        {/*      src={toAbsoluteUrl(*/}
        {/*        "/media/svg/icons/Communication/Group-chat.svg"*/}
        {/*      )}*/}
        {/*    />*/}
        {/*  </span>*/}
        {/*</button>*/}
        {/* </div>*/}
      </div>
    </div>
  );
}
