import StageService from "../../services/stage/stageService";
import { dynamicSort } from "../Helpers";

export const getStageByProjectId = (project, setStageNameOption) => {
  StageService.stageGetByProjectId(project.id)
    .then((response) => {
      if (response) {
        var data = response.data?.data;
        if (data && data.length > 0) {
          let stageData = data.map((x) => ({
            label: x.name,
            value: x.id,
            projectEntry: project.value,
            stageEmployees: x.stageEmployees,
          }));
          stageData.sort(dynamicSort("label"));
          setStageNameOption(stageData);
        }
        return response;
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {});
};
