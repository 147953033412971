import React, {Component} from "react";
import {connect} from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../shared/layout";
import TokenStorage from "../../services/token-storage/token-storage-service";

class Logout extends Component {
    componentDidMount() {
    /*this.props.logout();*/
        TokenStorage.signOut();
  }

    render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
  }
}

export default Logout;
