/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import SVG from "react-inlinesvg";
import {
  toAbsoluteUrl,
  checkIsActive,
} from "../../../../shared/_metronic/_helpers";
import { updateStateArray, uuidv4 } from "../../../../helpers/Helpers";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import "./style/styles.css";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));
const menu = [
  {
    id: 1,
    url: "/dashboard",
    name: "Dashboard",
    isMenu: true,
    img: "/media/svg/icons/Layout/Layout-top-panel-4.svg",
    isToggle: false,
    subMenu: [],
    active: "left-menu-item",
  },
  {
    id: 2,
    url: "/activity",
    name: "Activity",
    isMenu: true,
    isToggle: false,
    img: "/media/svg/icons/Navigation/Angle-double-right.svg",
    active: "left-menu-item",
    subMenu: [
      {
        url: "/assigned-activities",
        name: "Activity List",
        isMenu: true,
        img: "/media/svg/icons/Navigation/Angle-double-right.svg",
        parentId: 2,
      },
      {
        url: "/approve-waiting-activities",
        name: "Approve Waiting",
        isMenu: true,
        img: "/media/svg/icons/Navigation/Angle-double-right.svg",
        parentId: 2,
      },
      {
        url: "/my-pending-activities",
        name: "My Pending Activities",
        isMenu: true,
        img: "/media/svg/icons/Navigation/Angle-double-right.svg",
        parentId: 2,
      },
      {
        url: "/approved-activities",
        name: "Approved Activities",
        isMenu: true,
        img: "/media/svg/icons/Navigation/Angle-double-right.svg",
        parentId: 2,
      },
    ],
  },
  {
    id: 3,
    url: "/customer",
    name: "Customer Activities",
    isMenu: true,
    img: "/media/svg/icons/Navigation/Angle-double-right.svg",
    isToggle: false,
    active: "left-menu-item",
    subMenu: [
      {
        url: "/customer-transfer-waiting-activities",
        name: "Transfer Waiting Activities",
        isMenu: true,
        img: "/media/svg/icons/Navigation/Angle-double-right.svg",
        parentId: 3,
      },
      {
        url: "/customer-approved-activities",
        name: "Approved Activities",
        isMenu: true,
        img: "/media/svg/icons/Navigation/Angle-double-right.svg",
        parentId: 3,
      },
    ],
  },
  {
    id: 4,
    url: "/damage",
    name: "Damage",
    isMenu: true,
    img: "/media/svg/icons/Navigation/Angle-double-right.svg",
    isToggle: false,
    active: "left-menu-item",
    subMenu: [
      {
        url: "/damaged-material-report",
        name: "Damaged Material Report",
        isMenu: true,
        img: "/media/svg/icons/Navigation/Angle-double-right.svg",
        parentId: 4,
      },
      {
        url: "/damage-list",
        name: "Damage List",
        isMenu: true,
        img: "/media/svg/icons/Navigation/Angle-double-right.svg",
        parentId: 4,
      },
    ],
  },
  {
    id: 5,
    url: "/flow-meter-list",
    name: "Flow Meter",
    isMenu: true,
    img: "/media/svg/icons/Navigation/Angle-double-right.svg",
    isToggle: false,
    subMenu: [],
    active: "left-menu-item",
  },
  {
    id: 6,
    url: "/survey",
    name: "Survey",
    isMenu: true,
    img: "/media/svg/icons/Navigation/Angle-double-right.svg",
    isToggle: false,
    subMenu: [
      {
        url: "/survey",
        name: "Survey List",
        isMenu: true,
        img: "/media/svg/icons/Navigation/Angle-double-right.svg",
        parentId: 6,
      },
      {
        url: "/survey-flow-chart",
        name: "Survey Flow Chart",
        isMenu: true,
        img: "/media/svg/icons/Navigation/Angle-double-right.svg",
        parentId: 6,
      },
    ],
    active: 'left-menu-item',
  },
  {
    id: 7,
    url: "/media-rule-list",
    name: "Media Rule",
    isMenu: true,
    img: "/media/svg/icons/Navigation/Angle-double-right.svg",
    isToggle: false,
    subMenu: [],
    active: "left-menu-item",
  },
  {
    id: 8,
    url: "/stage-rule",
    name: "Stage Rule",
    isMenu: true,
    img: "/media/svg/icons/Navigation/Angle-double-right.svg",
    isToggle: false,
    subMenu: [],
    active: "left-menu-item",
  },
    {
        id: 9,
        url: "/stg-cencus",
        name: "Stage Cencus",
        isMenu: true,
        img: "/media/svg/icons/Navigation/Angle-double-right.svg",
        isToggle: false,
        subMenu: [],
        active: "left-menu-item",
    },
  {
    id: 10,
    url: "/report",
    name: "Report",
    isMenu: true,
    img: "/media/svg/icons/Navigation/Angle-double-right.svg",
    isToggle: false,
    active: "left-menu-item",
    subMenu: [
      {
        url: "/attachment-report",
        name: "Activity Attachment Report",
        isMenu: true,
        img: "/media/svg/icons/Navigation/Angle-double-right.svg",
        parentId:8,
      },
      {
        url: "/survey-answer-report",
        name: "Survey Answer Report",
        isMenu: true,
        img: "/media/svg/icons/Navigation/Angle-double-right.svg",
        parentId:8,
      },
    ],
  },
  {
    id: 11,
    url: "/user-management",
    name: "User Management",
    isMenu: true,
    img: "/media/svg/icons/Navigation/Angle-double-right.svg",
    isToggle: false,
    subMenu: [],
    active: "left-menu-item",
  },
];

export function AsideMenuList({ layoutProps }) {
  const classes = useStyles();
  const location = useLocation();
  const [menuItems, setMenuItems] = useState(menu);

  function activeClass(uri) {
    var activeClass =
      location.pathname === uri ? "left-menu-active" : "left-menu-item";
    return activeClass;
  }
  const updateStateMenu = (index, value) => {
    updateStateArray(menuItems, setMenuItems, index, !value, "isToggle");
  };

  const toggleActive = () => {
    var locationPath = location.pathname;
    var data = [...menuItems];
    menuItems.forEach((x, index) => {
      if (x.url == locationPath) {
        return;
      }
      if (x.subMenu.some((t) => t.url === locationPath)) {
        data[index].isToggle = true;
        data[index].active = "left-menu-active";
      } else {
        data[index].isToggle = false;
        data[index].active = "left-menu-item";
      }
    });
    setMenuItems(data);
  };

  useEffect(() => {
    toggleActive();
  }, []);
  return (
    <>
      <List
        className={`menu-nav ${classes.root}`}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {menuItems.map((element, index) =>
          element.subMenu.length <= 0 ? (
            <>
              <NavLink to={element.url}>
                <ListItem className={`${activeClass(element.url)}`} button>
                  <ListItemIcon>
                    <SendIcon className="left-menu-icon" />
                  </ListItemIcon>
                  <span className="left-menu-text">{element.name}</span>
                </ListItem>
              </NavLink>
              <Divider />
            </>
          ) : (
            <>
              <ListItem
                className={element.active}
                button
                onClick={() => updateStateMenu(index, element.isToggle)}
              >
                <ListItemIcon>
                  <SendIcon className="left-menu-icon" />
                </ListItemIcon>
                <ListItemText primary={element.name} />
                {element.isToggle ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={element.isToggle} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {element.subMenu.map((subMenu, index) => (
                    <NavLink
                      className={`${activeClass(
                        subMenu.url,
                        true,
                        element.index
                      )}`}
                      to={subMenu.url}
                    >
                      <ListItem button className={classes.nested}>
                        <ListItemIcon>
                          <StarBorder />
                        </ListItemIcon>
                        <span className="left-menu-text">{subMenu.name}</span>
                      </ListItem>
                    </NavLink>
                  ))}
                </List>
              </Collapse>
              <Divider />
            </>
          )
        )}
      </List>
    </>
  );
}
