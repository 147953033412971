import React, { Fragment } from "react";
import { View, Text } from "@react-pdf/renderer";
import { styles, colors } from "../style/style";

const sharpFlex = 0.5;
const codeFlex = 1.8;
const descFlex = 5;
const qtyFlex = 1;
const untFlex = 1;
const nrpFlex = 1.2;

const tableHeader = (contactName) => {
  return (
    <Fragment>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          marginHorizontal: "10%",
          marginTop: "1px",
          minHeight: "20px",
        }}
      >
        <View style={{ flexDirection: "column" }}>
          <Text
            style={{
              fontSize: 7,
              color: "#c00000",
              fontFamily: "Open Sans",
              fontWeight: 700,
              fontStyle: "italic",
            }}
          >
            Name of the Supervisor of Crosslinx atthesite on that day:{" "}
            {contactName?.toUpperCase()}
          </Text>
          <View style={{ flexDirection: "row" }}>
            <Text
              style={{
                fontSize: 9,
                fontFamily: "Open Sans",
                fontWeight: 700,
                marginRight: 2,
                fontStyle: "italic",
              }}
            >
              MATERIALS USED
            </Text>
            <Text
              style={{
                fontSize: 9,
                fontFamily: "Open Sans",
                fontWeight: 600,
                fontStyle: "italic",
              }}
            >
              (LIST ALL MATERIALS, PACKERS, SPEARS ACCESSORIES, EQUIPMENT
              CLEANING MATERIALS ETC)
            </Text>
          </View>
        </View>
      </View>
    </Fragment>
  );
};

const MaterialTable = (props) => {
  const { contactName } = props;
  var data = props.item ? props.item : [];
  var items = [];
  var size = data.length > 10 ? data.length : 10;
  if (props.item) {
    for (let i = 0; i < size; i++) {
      items.push(
        <View
          style={[
            styles.containerRow,
            {
              marginHorizontal: "10%",
              minHeight: data[i] ? data[i].height : "15px",
              maxHeight: data[i] ? data[i].height : "15px",
            },
          ]}
        >
          <View
            style={[
              styles.materialTableContainer,
              i % 2 != 0
                ? { backgroundColor: colors.grey }
                : { backgroundColor: colors.white },
            ]}
          >
            <View style={[styles.materialTableRow, { flex: sharpFlex }]}>
              <Text style={styles.tableText}>{i + 1}</Text>
            </View>
            <View style={styles.materialBorderRight}></View>
            <View style={[styles.materialTableRow, { flex: codeFlex }]}>
              <Text style={styles.tableText}>
                {data[i] ? data[i].itemCode : ""}
              </Text>
            </View>
            <View style={styles.materialBorderRight}></View>

            <View style={[styles.materialTableRow, { flex: descFlex }]}>
              <Text style={styles.tableText}>
                {data[i] ? data[i].itemName : ""}
              </Text>
            </View>
            <View style={styles.materialBorderRight}></View>

            <View style={[styles.materialTableRow, { flex: qtyFlex }]}>
              <Text style={styles.tableText}>{data[i] ? data[i].qty : ""}</Text>
            </View>
            <View style={styles.materialBorderRight}></View>

            <View style={[styles.materialTableRow, { flex: untFlex }]}>
              <Text style={styles.tableText}>
                {data[i] ? data[i].itemInventoryUoM : ""}
              </Text>
            </View>
            <View style={styles.materialBorderRight}></View>

            <View style={[styles.materialTableRow, { flex: nrpFlex }]}>
              <Text style={styles.tableText}>
                {data[i] ? data[i].quantity : ""}
              </Text>
            </View>
          </View>
        </View>
      );
    }
  }
  return (
    <>
      {tableHeader(contactName)}
      <Fragment>
        <View
          style={[
            styles.containerRow,
            { marginHorizontal: "10%", minHeight: "25px", maxHeight: "25px" },
          ]}
        >
          <View
            style={[
              styles.materialTableContainer,
              {
                backgroundColor: "#f2f2f2",
                fontSize: 8,
                fontFamily: "Open Sans",
                fontWeight: 700,
              },
            ]}
          >
            <View style={[styles.materialTableRow, { flex: sharpFlex }]}>
              <Text style={styles.tableText}>#</Text>
            </View>
            <View style={styles.materialBorderRight}></View>
            <View style={[styles.materialTableRow, { flex: codeFlex }]}>
              <Text style={styles.tableText}>Code</Text>
            </View>
            <View style={styles.materialBorderRight}></View>
            <View style={[styles.materialTableRow, { flex: descFlex }]}>
              <Text style={styles.tableText}>Material Description</Text>
            </View>
            <View style={styles.materialBorderRight}></View>
            <View style={[styles.materialTableRow, { flex: qtyFlex }]}>
              <Fragment>
                <Text>Qty. in</Text>
                <Text>each Pkg</Text>
              </Fragment>
            </View>
            <View style={styles.materialBorderRight}></View>
            <View style={[styles.materialTableRow, { flex: untFlex }]}>
              <Text style={styles.tableText}>Unit</Text>
            </View>
            <View style={styles.materialBorderRight}></View>
            <View style={[styles.materialTableRow, { flex: nrpFlex }]}>
              <Text style={styles.tableText}>Nr of Packs</Text>
            </View>
          </View>
        </View>
        {items}
      </Fragment>
    </>
  );
};

export default MaterialTable;
