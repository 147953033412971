import React, { useContext, useEffect, useState } from "react";
import { ActivityContext } from "../../../../context/context";
import { Row, Col, Button } from "reactstrap";
import { FaPlus, FaTimes } from "react-icons/fa";
import Select from "react-select";
import {
  GetItemSelectList,
  GetWhsBatchNumberSelectList,
} from "../../../../helpers/SelectHelpers";
import { uuidv4 } from "../../../../helpers/Helpers";

function ItemComponent() {
  const {
    items,
    setItems,
    docStgId,
    action,
    issue,
    itemOptions,
    setItemOptions,
    batchNumbers,
    setBatchNumbers,
  } = useContext(ActivityContext);

  const [batchNumberOptions, setBatchNumberOptions] = useState([]);

  const handleChange = (value, index, param) => {
    let newState = [...items];
    newState[index][param] = value;
    if (param === "selectedItem") {
      newState[index]["uom"] = value.uom;
      let tempBatchNumbers = [...batchNumbers];
      let newBatchNumberOptions = [...batchNumberOptions];
      newBatchNumberOptions[index] = tempBatchNumbers.filter(
        (x) => x.itemId === newState[index].selectedItem.id
      );
      if (newBatchNumberOptions[index].length > 0)
        newState[index].isBatchNumber = true;
      else newState[index].isBatchNumber = false;
      newState[index].selectedBatchNumber = null;
      setBatchNumberOptions(newBatchNumberOptions);
    }
    setItems(newState);
  };

  const addRow = (event) => {
    var defaultItem = {
      selectedItem: {
        value: 0,
        label: "None",
      },
      selectedBatchNumber: null,
      uom: "",
      quantity: "",
      isBatchNumber: false,
    };
    var newState = [...items];
    newState.push(defaultItem);
    setItems(newState);
  };

  const removeRow = (index) => {
    var newState = [];
    var tempArray = [...items];
    tempArray.forEach((element, i) => {
      if (index !== i) newState.push(element);
    });
    setItems(newState);
    console.log(newState);
  };

  useEffect(() => {
    if (docStgId > 0) {
      var exceptionObject = {
        projectEntry: issue.pEntry,
        stgEntry: issue.stgEntry,
        workingLocation: issue.workingLocation,
        subWorkingLocation: issue.subWorkingLocation,
        problemLocation: issue.problemLocation,
      };
      GetItemSelectList(setItemOptions, docStgId, exceptionObject);
      GetWhsBatchNumberSelectList(setBatchNumbers, docStgId);
    }
  }, []);
  useEffect(() => {
    if (items.length > 0 && batchNumbers.length > 0 && action == "update") {
      var newState = [...items];
      var hede = [];
      newState.forEach((element, index) => {
        if (
          element.selectedItem != null &&
          element.selectedItem.label != "None"
        ) {
          var item = itemOptions.find(
            (x) => x.value == element.selectedItem.value
          );

          var alfa = batchNumbers.filter((x) => x.itemId == item?.id);

          if (alfa != null && alfa.length > 0) {
            hede[index] = alfa;
          }
        }
      });
      if (hede.length > 0) setBatchNumberOptions(hede);
    }
  }, [batchNumbers, items]);
  return (
    <div>
      {items?.map((item, index) => (
        <Row key={uuidv4()} className={index > 0 ? "mt-3" : ""}>
          <Col xs={12} sm={6} md={4}>
            <label>Consumed Materials</label>
            <Select
              value={item.selectedItem}
              onChange={(e) => handleChange(e, index, "selectedItem")}
              options={itemOptions.filter((x) => (x.itemType == "I"))}
              isClearable={true}
            />
          </Col>
          <Col xs={12} sm={6} md={3}>
            <label>Batch Numbers</label>
            <Select
              value={item.selectedBatchNumber}
              isClearable={true}
              onChange={(e) => handleChange(e, index, "selectedBatchNumber")}
              options={batchNumberOptions[index]}
            />
          </Col>
          <Col xs={6} sm={6} md={3}>
            <label>Uom</label>
            <div className="input-group">
              <input
                className="form-control"
                id="inventoryUom"
                placeholder="103"
                value={item.quantity}
                onChange={(e) =>
                  handleChange(e.target.value, index, "quantity")
                }
              />
              <div className="input-group-prepend">
                <span
                  className="input-group-text"
                  id="validationTooltipUsernamePrepend"
                >
                  {!item.uom || item.uom == null || item.uom.trim() === ""
                    ? "number"
                    : item.uom}
                </span>
              </div>
            </div>
          </Col>
          <Col
            sm={6}
            md={2}
            className="d-flex justify-content-end align-items-end"
          >
            {index === 0 ? (
              <Button color="primary" onClick={(e) => addRow(e)}>
                <FaPlus />
              </Button>
            ) : (
              <Button color="danger" onClick={(e) => removeRow(index)}>
                <FaTimes />
              </Button>
            )}
          </Col>
        </Row>
      ))}
    </div>
  );
}

export default ItemComponent;
